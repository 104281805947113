<template>
  <div class="container">
    <div v-if="$apollo.queries.article.loading">Ladataan...</div>
    <div v-else-if="!article"><h1>Juttua ei löytynyt :(</h1></div>
    <div v-else>
      <div
        class="article-hero"
        :style="{ backgroundImage: `url(${article.heroImage.url}?w=1024)` }"
      ></div>
      <article class="article-content bg-white p-5 mb-5">
        <h1 class="mb-5">{{ article.title }}</h1>
        <div class="article-body article-rich-text">
          <RichTextRenderer
            :document="article.body.json"
            :nodeRenderers="renderNodes()"
          />
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import RichTextRenderer from 'contentful-rich-text-vue-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { setMeta } from '../utils/utils';

export default {
  name: 'Article',
  components: {
    RichTextRenderer,
  },
  apollo: {
    article: {
      query: gql`
        query ArticleBySlug($slug: String!) {
          articleCollection(limit: 1, where: { slug: $slug }) {
            items {
              body {
                json
                links {
                  assets {
                    block {
                      contentType
                      sys {
                        id
                      }
                      url(transform: { width: 1024 })
                    }
                  }
                }
              }
              heroImage {
                url
              }
              slug
              title
            }
          }
        }
      `,
      variables() {
        return {
          slug: this.$route.params.slug,
        };
      },
      skip() {
        return !this.$route.params.slug;
      },
      update: (data) => data.articleCollection.items[0],
    },
  },
  data() {
    return {
      article: undefined,
    };
  },
  computed: {
    assetsById() {
      const assetsById = new Map();

      if (this.article && this.article.body) {
        this.article.body.links.assets.block.forEach((asset) => {
          assetsById.set(asset.sys.id, asset);
        });
      }

      return assetsById;
    },
  },
  methods: {
    renderNodes() {
      return {
        [BLOCKS.EMBEDDED_ASSET]: (node, key, h, next) => {
          const asset = this.assetsById.get(node.data.target.sys.id);
          if (!asset) return null;

          if (asset.contentType.startsWith('image')) {
            return h('img', { key, attrs: { src: asset.url } });
          } else {
            return null;
          }
        },
      };
    },
  },
  created() {
    console.log('article', this.article);
  },
  metaInfo() {
    const title = this.article ? this.article.title : undefined;
    const url = this.article ? `articles/${this.article.slug}` : undefined;
    const ogImage = this.article
      ? `${this.article.heroImage.url}?fit=fill&w=1200&h=627`
      : undefined;

    return {
      title,
      meta: [
        ...setMeta({
          title,
          url,
          ogImage,
          ogImageWidth: 1200,
          ogImageHeight: 627,
        }),
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.article-hero {
  height: 30vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.article-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.article-body {
  max-width: 800px;
}

.article-rich-text {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.5em;
  }

  p {
    margin-bottom: 1.5em;
  }

  ol,
  ul {
    margin-bottom: 1em;

    & li > p {
      margin-bottom: 0;
    }
  }

  ul {
    padding-left: 1rem;
    list-style-type: disc;
  }
}
</style>

import api from '../../api';

const actions = {
  createCheckin(store, { id }) {
    return api.checkins.create(id);
  },
  deleteCheckin(store, { id }) {
    return api.checkins.delete(id);
  },
};

export default {
  namespaced: true,
  actions,
};

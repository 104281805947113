/** Run this in mounted() */
export function loadExternalScripts(scripts) {
  scripts.forEach(script => {
    console.log('loading', script)
    let tag = document.head.querySelector(`[src="${ script }"`);
    if (!tag) {
      tag = document.createElement("script");
      tag.setAttribute("src", script);
      tag.setAttribute("type", 'text/javascript');
      document.head.appendChild(tag);
    }
  });
}

export function getRandomPlaceholder(category) {
  const placeholders = {
    activity: ['activity000.jpg', 'activity001.jpg', 'activity002.jpg'],
    event: ['event000.jpg', 'event001.jpg'],
    restaurant: [
      'restaurant000.jpg',
      'restaurant001.jpg',
      'restaurant002.jpg',
      'restaurant003.jpg',
    ],
    service: ['service000.jpg', 'service001.jpg', 'service002.jpg'],
  };

  let file =
    placeholders[category][
      Math.floor(Math.random() * placeholders[category].length)
    ];
  let url = `/static/placeholders/${file}`;

  return url;
}

export const categoryColors = {
  restaurant: '#9175BD',
  service: '#28B994',
  activity: '#F0AAD2',
  event: '#4B9EDE',
};

export const colors = {
  0: '#fad46b',
  1: '#82dce9',
  2: '#a88cd5',
};

export const categoryPatterns = {
  restaurant: 'pattern-7.png',
  service: 'pattern-5.png',
  activity: 'pattern-3.png',
  event: 'pattern-1.png',
};

export const patterns = {
  0: '',
  1: 'pattern-1.png',
  2: 'pattern-2.png',
  3: 'pattern-3.png',
  4: 'pattern-4.png',
  5: 'pattern-5.png',
  6: 'pattern-6.png',
  7: 'pattern-7.png',
  8: 'pattern-8.png',
};

export function getMarkerIcon({ type, category, size = 30 }) {
  const icons = {
    event: '/static/icon-event-circle.svg',
    location: `/static/icon-${category}-circle.svg`,
    geolocation: '/static/icon-user-location.svg',
  };

  return {
    url: icons[type],
    anchor: { x: size / 2, y: size / 2 },
    scaledSize: { width: size, height: size },
  };
}

export function slugify(string) {
  const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;';
  const to = 'aaaaaeeeeeiiiiooooouuuunc------';

  from.split('').forEach((c, i) => {
    string = string.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  });

  return string
    .toString() // Cast to string
    .toLowerCase() // Convert the string to lowercase letters
    .trim() // Remove whitespace from both sides of a string
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/&/g, '-y-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-'); // Replace multiple - with single -
}

export const setMeta = ({
  title,
  description,
  url,
  ogImage,
  ogImageWidth,
  ogImageHeight,
}) => {
  const fullTitle = `${title} | Sandis - tekemistä, hyötyä ja hupia lapsiperheille`;
  const titleMeta = [
    { vmid: 'twitter:title', name: 'twitter:title', content: fullTitle },
    { vmid: 'og:title', property: 'og:title', content: fullTitle },
  ];

  const descriptionMeta = [
    { vmid: 'description', name: 'description', content: description },
    {
      vmid: 'twitter:description',
      name: 'twitter:description',
      content: description,
    },
    {
      vmid: 'og:description',
      property: 'og:description',
      content: description,
    },
  ];

  const urlMeta = [
    {
      vmid: 'og:url',
      property: 'og:url',
      content: `https://www.sandis.co/${url}`,
    },
  ];

  const imageMeta = [
    {
      vmid: 'og:image',
      property: 'og:image',
      content: ogImage,
    },
    {
      vmid: 'og:image:width',
      property: 'og:image:width',
      content: ogImageWidth,
    },
    {
      vmid: 'og:image:height',
      property: 'og:image:height',
      content: ogImageHeight,
    },
  ];

  return [
    ...(title ? titleMeta : []),
    ...(description ? descriptionMeta : []),
    ...(url ? urlMeta : []),
    ...(ogImage ? imageMeta : []),
  ];
};

export const sortByProp = (prop) => (a, b) => {
  const x = a[prop].toLowerCase();
  const y = b[prop].toLowerCase();

  return x < y ? -1 : x > y ? 1 : 0;
};

// Returns id for locations, id + date_id for events
export const getThingId = (thing) =>
  thing.type === 'event' ? `${thing.id}_${thing.date_id}` : `${thing.id}`;

export const getThingUrl = (thing) => {
  // Append date id to url if it's available
  const params = thing.date_id ? `?date_id=${thing.date_id}` : '';

  return `/${thing.type}/${thing.id}/${thing.slug}${params}`;
};

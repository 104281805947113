<template>
  <div id="main-view">
    <div class="main-view__mobile-view-toggle md:hidden">
      <button
        class="button button--primary--blue"
        @click="onMobileViewToggleClick"
      >
        {{ mobileViewToggleText }}
      </button>
    </div>

    <div class="main-view__filters sticky">
      <div
        :class="['dropdown', { 'is-active': visibleDropdown === 'location' }]"
      >
        <div class="dropdown-trigger">
          <button
            :class="['button button--primary--blue']"
            aria-haspopup="true"
            aria-controls="dropdown-location"
            @click="onDropdownClick('location')"
          >
            <span>Sijainti</span>
            <span class="icon is-small">
              <i class="fa fa-angle-down" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        <div class="dropdown-menu" id="dropdown-location" role="menu">
          <div class="dropdown-content">
            <div class="dropdown-item">
              <Position @value="onPositionValue"></Position>
            </div>
          </div>
        </div>
      </div>
      <div :class="[{ 'is-hidden-mobile': filters.category === 'all' }]">
        <div
          :class="[
            'dropdown is-right',
            { 'is-active': visibleDropdown === 'tag' },
          ]"
        >
          <div class="dropdown-trigger">
            <button
              :class="[
                'button button--primary--blue',
                { badge: filters.tags.length > 0 },
              ]"
              :data-badge="filters.tags.length"
              aria-haspopup="true"
              aria-controls="dropdown-tag"
              @click="onDropdownClick('tag')"
            >
              <span>Rajaa kohteita</span>
              <span class="icon is-small">
                <i class="fa fa-angle-down" aria-hidden="true"></i>
              </span>
            </button>
          </div>
          <div class="dropdown-menu" id="dropdown-tag" role="menu">
            <div class="dropdown-content">
              <div class="dropdown-item">
                <strong>Ominaisuudet</strong>
                <div class="my-2" v-for="tag in combinedTags" :key="tag">
                  <label
                    :class="[
                      'tag is-medium is-unselectable',
                      { 'is-primary': ~filters.tags.indexOf(tag) },
                    ]"
                  >
                    <input
                      type="checkbox"
                      :value="tag"
                      v-model="tags"
                      class="hidden"
                    />
                    {{ $t('tags.' + tag) | capitalize }}
                  </label>
                </div>
              </div>
              <div class="dropdown-item">
                <strong>Ikä</strong>
                <div
                  class="my-2"
                  v-for="age in [
                    'baby',
                    'toddler',
                    'preschooler',
                    'gradeschooler',
                  ]"
                  :key="age"
                >
                  <label
                    :class="[
                      'tag is-medium is-unselectable',
                      { 'is-primary': ~filters.ages.indexOf(age) },
                    ]"
                  >
                    <input
                      type="checkbox"
                      :value="age"
                      v-model="ages"
                      class="hidden"
                    />
                    {{ $t('ages.' + age) | capitalize }}
                  </label>
                </div>
              </div>
              <div class="dropdown-item">
                <button
                  class="button button--primary--rose w-full"
                  @click="fetchData"
                >
                  Hae
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ml-4">
        <router-link to="/browse">
          <i class="fa fa-times-circle"></i>
        </router-link>
      </div>
    </div>

    <main class="main-view__row">
      <div
        :class="[
          'main-view__list',
          { 'hidden md:block': mobileVisibleView !== 'list' },
        ]"
      >
        <div class="px-2">
          <Pagination
            v-if="pagination"
            :current="pagination.page_number"
            :total="pagination.total_pages"
            :onPageChange="handlePageChange"
          />
        </div>
        <CardList :items="things"></CardList>
      </div>
      <div
        :class="[
          'main-view__map sticky',
          { 'hidden md:block': mobileVisibleView !== 'map' },
        ]"
      >
        <AppleMap
          ref="map"
          :center="mapCenter"
          :items="things"
          @center-change="onMapCenterChange"
          fitAllItems
        />
      </div>
    </main>
  </div>
</template>

<script>
import CardList from '@/components/CardList';
import Position from '@/components/Position';
import Pagination from '@/components/Pagination';
import AppleMap from '@/components/Map';

import stickybits from 'stickybits';
import zenscroll from 'zenscroll';
import throttle from 'lodash.throttle';

import { getMarkerIcon } from '../utils/utils';
import categories from '../utils/categories';
import tags from '../utils/tags';
import { capitalize } from '../utils/filters';
import { globalMapOptions } from '../utils/map';

// Returns id for locations, id + date_id for events
const getId = (thing) =>
  thing.type === 'event' ? `${thing.id}_${thing.date_id}` : `${thing.id}`;

export default {
  name: 'Browse',
  components: {
    AppleMap,
    CardList,
    Pagination,
    Position,
  },
  data() {
    return {
      categories: categories.location,
      createDiscussionVisible: false,
      infoBoxVisible: false,
      infoWindow: {
        description: '',
        open: false,
        options: { maxWidth: 310 },
        position: { lat: 0, lng: 0 },
        target: '',
        title: '',
      },
      locations: {},
      mapCenter: {
        lat: 60.1699,
        lng: 24.9384,
      },
      mapOptions: globalMapOptions,
      mobileVisibleView: 'list',
      availableTags: tags,
      visibleDropdown: '',
    };
  },
  head: {
    title() {
      return {
        inner: 'Tervetuloa!',
      };
    },
  },
  beforeRouteEnter: function (to, from, next) {
    next((vm) => {
      vm.mapCenter =
        vm.$store.state.things.filters.location.lat &&
        vm.$store.state.things.filters.location.lng
          ? vm.$store.state.things.filters.location
          : vm.mapCenter;
    });
  },
  beforeRouteUpdate: function (to, from, next) {
    const filters = this.getFiltersFromRoute(to);
    this.$store.dispatch('things/setFilters', filters);
    this.fetchData();
    next();
  },
  created() {
    const filters = this.getFiltersFromRoute(this.$route);
    this.$store.dispatch('things/setFilters', filters);
    this.fetchData();
  },
  mounted() {
    stickybits('.sticky');
    zenscroll.setup(400, 60);
  },
  computed: {
    ages: {
      get() {
        return this.$store.state.things.filters.ages;
      },
      set(value) {
        this.$store.dispatch('things/setFilter', {
          filter: 'ages',
          value,
        });
      },
    },
    combinedTags() {
      return !this.filters.category || this.filters.type === 'event'
        ? this.availableTags.all
        : this.availableTags.all.concat(
            this.availableTags[this.filters.category]
          );
    },
    filters() {
      return this.$store.state.things.filters;
    },
    mobileViewToggleText() {
      return this.mobileVisibleView === 'map'
        ? this.$t('main.mobileViewToggle.list')
        : this.$t('main.mobileViewToggle.map');
    },
    pagination() {
      return this.$store.state.things.pagination;
    },
    tags: {
      get() {
        return this.$store.state.things.filters.tags;
      },
      set(value) {
        this.$store.dispatch('things/setFilter', {
          filter: 'tags',
          value,
        });
      },
    },
    things() {
      return this.$store.getters['things/things'];
    },
  },
  filters: {
    capitalize,
  },
  methods: {
    getMarkerIcon,
    getCategory(locations, category) {
      return locations.filter((location) => location.category === category);
    },
    getFiltersFromRoute(route) {
      return {
        category: route.params.category || null,
        city: route.query.city || null,
        end_date: route.query.end_date || null,
        // Remove trailing s character (e.g. locations => location)
        type: route.params.type ? route.params.type.slice(0, -1) : null,
        start_date: route.query.start_date || null,
      };
    },
    fetchData() {
      this.$store.dispatch(
        'things/getThings',
        this.$store.state.things.pagination.page_number
      );
    },
    getId,
    handlePageChange(page) {
      this.$store.dispatch('things/getThings', page);
    },
    onDropdownClick(dropdown) {
      this.visibleDropdown = this.visibleDropdown === dropdown ? '' : dropdown;
    },
    onMapCenterChange: throttle(function (center) {
      this.$store.dispatch('things/setCoordinates', center);
      this.fetchData();
      this.mapCenter = center;
    }),
    onMobileViewToggleClick(event) {
      event.target.classList.add('bounce');
      setTimeout(() => event.target.classList.remove('bounce'), 600);
      this.mobileVisibleView =
        this.mobileVisibleView === 'map' ? 'list' : 'map';

      this.$nextTick(() => {
        if (this.mobileVisibleView === 'map') {
          const target = this.$el.querySelector('main');
          zenscroll.to(target);
          // Apple Maps seems to lose center when hiding/showing so re-center it
          this.$refs.map.setCenter(this.mapCenter);
        }
      });
    },
    onPositionValue(value) {
      const { lat, lng } = value;
      if (lat && lng) {
        this.$store.dispatch('things/setCoordinates', { lat, lng });
        this.mapCenter = { lat, lng };
        this.fetchData();
        this.$refs.map.setCenter({ lat, lng });
      }
    },
    queryToArray(string) {
      let arr = string || '';
      arr = arr.split(',');
      return arr.filter((item) => item.length > 0);
    },
    scrollToContent() {
      const target = this.$el.querySelector('main');
      zenscroll.to(target, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/variables';
@import '../scss/animations';

$sticky-bar-height: 3.5rem;

#main-view {
  width: 100%;
}

.main-view__filters {
  @apply bg-grey-lighter
    p-2
    flex
    items-center
    justify-center;

  height: $sticky-bar-height;
  z-index: $z-index-header - 1;

  & > * {
    @apply mx-1;
  }
}

.main-view__header-popover {
  position: static;
}

.main-view__row {
  display: flex;
}

.main-view__list {
  max-width: 100%;
  height: 100%;
  padding: 0.5rem;

  @media (min-width: 769px) {
    width: 48rem;
    height: 100%;
    padding: 1rem;
  }
}

.main-view__map {
  top: $sticky-bar-height !important;
  height: calc(100vh - #{$sticky-bar-height});
  flex-grow: 2;
  z-index: 1;
}

.is-paddingless--mobile {
  @media (max-width: 768px) {
    padding: 0 !important;
  }
}

.main-view__mobile-view-toggle {
  position: fixed;
  width: 100%;
  bottom: 5vh;
  left: 0;
  text-align: center;
  z-index: $z-index-modal - 1;
  pointer-events: none;

  button {
    pointer-events: auto;
  }
}

.map-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.filters__title {
  @media (max-width: $bp-mobile) {
    font-size: 1.5rem;
  }
}

.filters {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem 2rem;
  transition: opacity 0.5s ease-out;

  @media (max-width: $bp-mobile) {
    padding: 0.25rem;
  }
}

.filters__close {
  @media (max-width: $bp-mobile) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.5rem;
    background-color: white;
  }
}

.filters__category,
.filters__tags {
  @media (min-width: $bp-mobile) {
    margin-left: 0.5rem;
  }

  @media (max-width: $bp-mobile) {
    margin: 0.1rem;
  }
}

.filter {
  &__title {
    margin-top: 1rem;
  }

  input {
    display: none;
  }

  .tag {
    width: 100%;
    margin: 0.25rem;
  }
}

.create-discussion-container .modal {
  z-index: 102;
}

.position-container-mobile {
  width: 100%;
}
</style>

<template>
  <span
    role="radio"
    :class="['button-color', { 'button-color--active': active }]"
    @click="onClick(type === 'color' ? colorId : patternId)"
    :style="{ backgroundColor: color, backgroundImage }"
  ></span>
</template>

<script>
import { colors, patterns } from '../utils/utils';

export default {
  name: 'ButtonColor',
  props: {
    active: { type: Boolean, default: false },
    onClick: { type: Function, default: () => {} },
    colorId: Number,
    patternId: Number,
    type: String,
  },
  data() {
    return {
      color: colors[this.colorId],
      pattern: patterns[this.patternId],
    };
  },
  computed: {
    backgroundImage() {
      return this.type === 'pattern'
        ? `url(/static/patterns/${this.pattern})`
        : 'none';
    },
  },
};
</script>

<style lang="scss">
.button-color {
  display: inline-block;
  height: 5em;
  width: 5em;
  border: 0.25em solid white;
  border-radius: 9999px;
  margin: 0.25em;
  opacity: 0.7;
  background-size: cover;
  cursor: pointer;
}

.button-color--active {
  opacity: 1;
}
</style>

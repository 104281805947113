import Amplify, { Auth } from 'aws-amplify';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
const {
  VUE_APP_AWS_REGION,
  VUE_APP_COGNITO_USER_POOL,
  VUE_APP_COGNITO_APP_ID,
} = process.env;

Amplify.configure({
  Auth: {
    region: VUE_APP_AWS_REGION,
    userPoolId: VUE_APP_COGNITO_USER_POOL,
    userPoolWebClientId: VUE_APP_COGNITO_APP_ID,
  },
  Analytics: {
    autoSessionRecord: false,
  },
});

const auth = {
  login(username, password) {
    return Auth.signIn(username, password);
  },
  logout() {
    return Auth.signOut();
  },
  getSession() {
    return Auth.currentSession();
  },
  register({ username, password, email }) {
    return Auth.signUp({
      username,
      password,
      attributes: {
        email,
      },
    });
  },
  confirmRegistration(username, code) {
    return Auth.confirmSignUp(username, code);
  },
  getApiToken() {
    return Auth.currentSession().then(
      ({ accessToken: { jwtToken } }) => jwtToken
    );
  },
  fbLogin(tokenData) {
    return new Promise((resolve) => {
      const payload = formatAttributes(tokenData.IdToken);

      const cognitoUser = new CognitoUser({
        Username: payload['cognito:username'],
        Pool: new CognitoUserPool({
          UserPoolId: VUE_APP_COGNITO_USER_POOL,
          ClientId: VUE_APP_COGNITO_APP_ID,
        }),
      });

      cognitoUser.signInUserSession = cognitoUser.getCognitoUserSession(
        tokenData
      );

      cognitoUser.cacheTokens();

      resolve(payload);
    });
  },
};

function formatAttributes(jwt) {
  // eslint-disable-next-line
  let body = JSON.parse(atob(jwt.split('.')[1]))

  let attributes = [
    'sub',
    'email',
    'email_verified',
    'cognito:username',
    'cognito:groups',
    'preferred_username',
  ].reduce((obj, attr) => {
    // Needs some UTF-8 decoding
    obj[attr] =
      typeof body[attr] === 'string'
        ? decodeURIComponent(escape(body[attr]))
        : body[attr];

    return obj;
  }, {});

  return attributes;
}

export default auth;

<template>
  <div class="section admin-view">
    <header class="has-text-centered">
      <p class="subtitle is-size-7">welcome to the</p>
      <h1 class="title is-size-1 has-text-danger">DANGER ZONE</h1>
    </header>
    <div class="container">
      <div class="edit-list">
        <article
          v-for="(edit, index) in edits"
          :key="edit.id"
          class="edit-list__item"
        >
          <header class="level">
            <div class="level-left">
              <div class="level-item">{{ edit.createdAt }}</div>
              <div class="level-item">
                <a :href="`${edit.type}/${edit.itemId}`">
                  {{ edit.type }} {{ edit.itemId }}
                </a>
              </div>
              <div class="level-item">{{ edit.username }}</div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <button
                  :class="[
                    'button is-small is-info',
                    { 'is-loading': loadingIndex === index },
                  ]"
                  @click="onOpenClick(edit, index)"
                >
                  Avaa
                </button>
              </div>
            </div>
          </header>
          <div v-show="visibleEdit === index">
            <pre :id="`edit-${index}`"></pre>
            <div class="field is-grouped is-grouped-centered">
              <div class="control">
                <div
                  class="button is-danger"
                  @click="onDecisionClick('REJECT', edit)"
                >
                  Hylkää
                </div>
              </div>
              <div class="control">
                <div
                  class="button is-primary"
                  @click="onDecisionClick('APPROVE', edit)"
                >
                  Hyväksy
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../utils/api';
import auth from '../utils/auth';
import axios from 'axios';
import * as diff from 'diff';

const { VUE_APP_BACKEND_URL } = process.env;

const fetchEditQueue = () => {
  return auth.getApiToken().then((token) => {
    return axios.get(`${VUE_APP_BACKEND_URL}/admin/edits`, {
      headers: { Authorization: token },
    });
  });
};

const postDecision = ({ decision, edit }) => {
  return auth.getApiToken().then((token) => {
    return axios.post(
      `${VUE_APP_BACKEND_URL}/admin/edits/decision`,
      { decision, edit },
      { headers: { Authorization: token } }
    );
  });
};

const fetchEditTarget = (type, id) => api[`${type}s`].get(id);

export default {
  name: 'Admin',
  data() {
    return {
      edits: [],
      loadingIndex: undefined,
      visibleEdit: undefined,
      apiData: {},
    };
  },
  created() {
    fetchEditQueue().then(({ data }) => {
      this.edits = data.Items;
    });
  },
  methods: {
    onDecisionClick(decision, edit) {
      postDecision({ decision, edit })
        .then(() => console.log('success'))
        .catch((error) => console.log(error));
    },
    onOpenClick(edit, index) {
      if (this.apiData[edit.itemId]) return;

      this.loadingIndex = index;
      fetchEditTarget(edit.type, edit.itemId).then(({ data }) => {
        this.apiData[edit.itemId] = data;
        this.loadingIndex = undefined;

        const fullEdit = Object.assign({}, data, edit.data);
        const diffParts = diff.diffJson(data, fullEdit);
        const fragment = document.createDocumentFragment();

        const handleParts = (part) => {
          // green for additions, red for deletions
          // grey for common parts
          let color = part.added ? 'green' : part.removed ? 'red' : 'grey';
          let span = document.createElement('span');
          span.style.color = color;
          span.appendChild(document.createTextNode(part.value));
          fragment.appendChild(span);
        };

        diffParts.forEach(handleParts);

        this.$el.querySelector(`#edit-${index}`).appendChild(fragment);
        this.visibleEdit = index;
      });
    },
  },
};
</script>

<style lang="scss">
.admin-view {
  width: 100%;

  header {
    margin-bottom: 2rem;
  }
}

.edit-list__item {
  &:nth-child(even) {
    background-color: hotpink;
  }

  pre {
    white-space: pre-wrap;
    margin-bottom: 2rem;
  }
}
</style>

<template>
  <button @click="onCheckinClick" role="button" class="button">
    <span class="icon is-small mr-2">
      <i :class="['fa fa-check', isCheckedIn ? 'text-green' : 'text-grey']"></i>
    </span>
    <span>{{ label }}</span>
  </button>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ButtonCheckin',
  props: {
    id: Number,
    isCheckedIn: Boolean,
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn',
    }),
    label() {
      return this.isCheckedIn ? 'Olet käynyt täällä' : 'Merkitse käydyksi';
    },
  },
  methods: {
    async onCheckinClick() {
      if (this.isLoggedIn) {
        const action = this.isCheckedIn
          ? 'checkins/deleteCheckin'
          : 'checkins/createCheckin';

        try {
          await this.$store.dispatch(action, { id: this.id });
          this.$emit('onChange', { isCheckedIn: !this.isCheckedIn });
        } catch (error) {
          console.log('Error setting checkin', error);
        }
      } else {
        this.$store.dispatch('toggleLogin', { visible: true });
      }
    },
  },
};
</script>

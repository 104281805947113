<template>
  <div class="modal is-active background-shapes">
    <div class="modal-background" @click="close"></div>
    <div class="modal-content login__modal-content rounded bg-white p-6">
      <div class="flex flex-col items-center">
        <form class="w-full max-w-xs" @submit.prevent="onLoginClick">
          <div class="mb-4">
            <a :href="fbUrl" class="button button--primary--blue w-full">
              Kirjaudu Facebook-tunnuksella
            </a>
          </div>

          <h4 class="font-bold text-center uppercase mb-4">tai</h4>

          <label for="" class="label">
            Käyttäjätunnus tai sähköpostiosoite
          </label>
          <div class="">
            <input v-model="username" type="text" class="input" />
          </div>
          <label for="" class="label">Salasana</label>
          <div class="">
            <input v-model="password" type="password" class="input" />
          </div>
          <div class="mb-2">
            <button type="submit" class="button button--primary--black w-full">
              Kirjaudu sisään
            </button>
          </div>
          <router-link to="/forgotpassword" class="link" @click.native="close">
            Unohtuiko salasana?
          </router-link>
        </form>
        <h4 class="text-center mb-2 mt-4">Etkö vielä ole jäsen?</h4>
        <div class="w-full max-w-xs mb-4">
          <router-link
            :to="'/register'"
            @click.native="close"
            class="button button--primary--rose w-full"
          >
            Rekisteröidy
          </router-link>
        </div>
      </div>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="close"
    ></button>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      isLoading: false,
      password: '',
      username: '',
    };
  },
  computed: {
    fbUrl() {
      const { VUE_APP_COGNITO_APP_ID } = process.env;
      const state = this.$route.path;

      return encodeURI(
        'https://sandis-users.auth.eu-west-1.amazoncognito.com/authorize' +
          '?identity_provider=Facebook' +
          '&response_type=token' +
          '&redirect_uri=https://www.sandis.co/fbauth' +
          `&client_id=${VUE_APP_COGNITO_APP_ID}` +
          '&scope=openid email aws.cognito.signin.user.admin profile' +
          `&state=${state}`
      );
    },
  },
  methods: {
    close() {
      this.$store.dispatch('toggleLogin', { visible: false });
    },
    async onLoginClick() {
      this.isLoading = true;
      this.loginError = false;

      try {
        await this.$store.dispatch('user/login', {
          username: this.username,
          password: this.password,
        });

        this.isLoading = false;
        this.close();
      } catch (err) {
        console.log('login error', err);
        this.isError = true;
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.login__modal-content {
  max-height: calc(100vh - 80px);
}
</style>

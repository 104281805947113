<template>
  <div class="features bg-white rounded shadow px-4 mb-6">
    <div class="columns">
      <div class="column is-half">
        <Feature name="Etusivu" image="/static/icon-line-phone.svg">
          <p>
            Sandiksen etusivulta löydät ajankohtaisimmat ja inspiroivimmat
            listat sekä kuumimmat tapahtumat.
          </p>
        </Feature>
        <Feature name="Kartta" image="/static/icon-line-map.svg">
          <p>
            Kartan avulla pystyt etsimään helposti etsimäsi asian lähiseudulta.
            Kartta näyttää 15 kilometrin säteellä paikat, joten pidä tämä
            mielessä kun siirrät kartan keskipistettä. Yläriviltä pääset
            tarkentamaan mitä kartalla ja listauksessa näkyy.
          </p>
          <p>
            Klikkaamalla korttia pääset katsomaan paikan tai tapahtuman
            tarkemmat tiedot.
          </p>
        </Feature>
        <Feature name="Liity mukaan" image="/static/icon-line-login.svg">
          <p>
            Liittyminen Sandikseen on helppoa! Luot käyttäjätunnuksen ja
            salasanan ja käyt vielä vahvistamassa tilisi sähköpostiin lähetetyn
            koodin avulla. Ihan siltä varalta, ettet ole mikään robotti.
            Rekisteröityminen onnistuu myös Facebookin avulla. Käy myös
            tutustumassa Sandiksen käyttöehtoihin!
          </p>
        </Feature>
      </div>
      <div class="column is-half">
        <Feature name="Oma profiili" image="/static/icon-line-astronaut.svg">
          <p>
            Sandislaiset pystyvät luomaan uusia paikkoja tai tapahtumia sekä
            muokkaamaan niitä ja jakamaan kokemuksiaan niistä. Rekisteröityneenä
            pystyt myös merkkaamaan paikkoja suosikeiksi, tai luomaan paikoista
            tai tapahtumista listoja. Listoja pystyy myös jakamaan muille tai
            vaikkapa sosiaaliseen mediaan.
          </p>
        </Feature>
        <Feature name="Pisteet" image="/static/icon-line-laptop.svg">
          <p>
            Koska Sandis paranee, mitä enemmän sitä käyttää, olemme luoneet
            pistejärjestelmän. Auttamalla muita vanhempia jakamalla kokemuksia
            ja vinkkejä saat pisteitä, joilla voit osallistua kilpailuihin.
          </p>
          <br />
          <p>Tällä hetkellä saat pisteitä</p>
          <p>Paikan luomisesta 800</p>
          <p>Paikan arvostelemisesta 300</p>
          <p>Päivittämällä paikan tietoja 100</p>
        </Feature>
        <Feature name="Sosiaalinen media" image="/static/icon-line-phone.svg">
          <p>
            Sandis löytyy myös
            <a
              href="https://www.facebook.com/sandiscommunity/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebookista
            </a>
            ja
            <a
              href="https://www.instagram.com/sandis.co/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagramista
            </a>
            ! Jaamme siellä paljon kivoja postauksia, joten olisi mahtavaa jos
            käyt seuraamassa Sandista.
          </p>
        </Feature>
      </div>
    </div>
  </div>
</template>

<script>
import Feature from '@/components/Feature';

export default {
  name: 'Features',
  components: {
    Feature,
  },
};
</script>

<style lang="scss"></style>

<template>
  <div class="feedback-form bg-white rounded p-4">
    <form
      v-show="!isSuccess && !isError"
      class="box"
      @submit.stop.prevent="onSubmit"
    >
      <h2 class="font-bold text-lg mb-6">Anna palautetta</h2>
      <!-- Needed for Netlify -->
      <input type="hidden" name="form-name" value="contact" />

      <fieldset>
        <legend class="label">Aihe</legend>
        <div class="control">
          <label>
            <input
              v-model="feedback.subject"
              type="radio"
              name="subject"
              value="❤️ / 💩 Palautetta"
            />
            ❤️ / 💩 Palautetta
          </label>
          <label class="ml-2">
            <input
              v-model="feedback.subject"
              type="radio"
              name="subject"
              value="❓ Kysymys"
            />
            ❓ Kysymys
          </label>
          <label class="ml-2">
            <input
              v-model="feedback.subject"
              type="radio"
              name="subject"
              value="💥 Vikailmoitus"
            />
            💥 Vikailmoitus
          </label>
        </div>
      </fieldset>

      <div class="field">
        <label class="label" for="message">Viesti</label>
        <div class="control">
          <textarea
            v-model="feedback.message"
            name="message"
            id="message"
            rows="5"
            class="textarea"
          ></textarea>
        </div>
      </div>

      <div class="field">
        <div class="control">
          <label class="checkbox">
            <input v-model="feedback.reply" type="checkbox" />
            Haluan vastauksen viestiini
          </label>
        </div>
      </div>

      <div class="field" v-show="feedback.reply">
        <label class="label" for="email">Sähköpostiosoite</label>
        <div class="control">
          <input
            v-model="feedback.email"
            id="email"
            type="email"
            class="input"
          />
        </div>
        <p class="help">
          Sähköpostiosoite on pakollinen, jos haluat vastauksen viestiisi.
        </p>
      </div>

      <div class="field is-grouped is-grouped-right">
        <div class="control">
          <button
            class="button is-white"
            @click.stop.prevent="$store.dispatch('toggleFeedback')"
          >
            Peruuta
          </button>
        </div>
        <div class="control">
          <button
            :disabled="$v.$invalid"
            type="submit"
            class="button is-primary"
          >
            Lähetä
          </button>
        </div>
      </div>
    </form>
    <div v-if="isSuccess" class="container container--narrow">
      <p class="notification is-primary has-text-centered">
        Kiitos viestistäsi! Jos halusit vastauksen viestiisi, pyrimme vastaamaan
        mahdollisimman pikaisesti.
        <br />
        <br />
        <button
          class="button is-white is-outlined"
          @click="$store.dispatch('toggleFeedback')"
        >
          Takaisin Sandiksen pariin
        </button>
      </p>
    </div>
    <div v-if="isError" class="container container--narrow">
      <p class="notification is-danger has-text-centered">
        Hups! Valitettavasti jotain meni pieleen viestiä lähettäessä.
        <br />
        <br />
        <button class="button is-white is-outlined" @click="isError = false">
          Kokeile uudestaan
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {
  email,
  required,
  requiredIf,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators';

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

export default {
  name: 'Feedback',
  data() {
    return {
      isLoading: false,
      isSuccess: false,
      isError: false,
      feedback: {
        email: '',
        message: '',
        reply: false,
        subject: '',
      },
    };
  },
  validations: {
    feedback: {
      subject: { required },
      message: { required, minLength: minLength(5), maxLength: maxLength(500) },
      email: { email, requiredIf: requiredIf('reply') },
    },
  },
  methods: {
    onSubmit() {
      const vm = this;
      vm.isLoading = true;

      axios
        .post('/', encode({ 'form-name': 'contact', ...vm.feedback }), {
          'Content-Type': 'application/x-www-form-urlencoded',
        })
        .then(() => {
          vm.isLoading = false;
          vm.isSuccess = true;
        })
        .catch(() => {
          vm.isLoading = false;
          vm.isError = true;
        });
    },
  },
};
</script>

<style lang="scss">
.feedback-form {
  legend {
    padding: 0;
    display: table;
  }

  fieldset {
    border: 0;
    padding: 0.01em 0 0 0;
    margin: 0 0 1.5rem;
    min-width: 0;
  }
}
</style>

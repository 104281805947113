<template>
  <div id="create-location" class="centered-container">
    <h1 class="font-bold text-4xl">Lisää paikka Sandikseen</h1>

    <section class="create-section create-section--disabled">
      <label for="name"><h2 class="create-section__title">Nimi</h2></label>
      <p class="create-section__description">
        Minkä paikan haluat lisätä palveluun? Kirjoita tähän paikan nimi,
        koitamme auttaa etsimällä saman nimisiä paikkoja.
      </p>
      <div class="control">
        <input
          class="input"
          id="name"
          type="text"
          v-model="locationObj.name"
          @input="onNameInput"
          @blur="onNameBlur"
        />
        <div class="results-container">
          <div class="results" v-show="queryResults.length > 0">
            <div
              tabindex="0"
              class="result"
              v-for="result in queryResults"
              :key="result.id"
              @click="onResultClick(result)"
            >
              {{ result.description }}
            </div>
            <div class="results__attributions"></div>
          </div>
        </div>
      </div>
      <div v-show="locationObj.name.length < 1" class="message message--yellow">
        Kirjoita nimi jatkaaksesi.
      </div>
      <button
        class="button button--teal create-section__button-next"
        :disabled="locationObj.name.length < 1"
        @click="onNextClick"
      >
        Seuraava
      </button>
    </section>

    <section class="create-section create-section--disabled">
      <h2 class="reate-section__title">Kaupunki</h2>
      <p class="create-section__description">Missä kaupungissa paikka on?</p>
      <div class="create-location__city mb-4">
        <Multiselect
          v-model="locationObj.city"
          :allow-empty="false"
          :options="cityOptions"
          placeholder="Valitse kaupunki"
          :show-labels="false"
          :show-pointer="false"
          :searchable="true"
        >
          <span slot="carret"></span>
        </Multiselect>
      </div>
      <button
        class="button button--teal create-section__button-next"
        :disabled="locationObj.city.length < 1"
        @click="onNextClick"
      >
        Seuraava
      </button>
    </section>

    <section class="create-section create-section--disabled">
      <h2 class="create-section__title">Sijainti</h2>
      <p class="create-section__description">
        Paikalla täytyy olla sijainti, jotta osaamme sijoittaa sen kartalle.
        Voit etsiä paikan kartalta, tai hakea sitä osoitteen perusteella.
      </p>
      <div class="create-map-container">
        <gmap-map
          ref="gmap"
          :center="mapCenter"
          :zoom="15"
          style="width: 100%; height: 100%"
          :options="mapOptions"
          @center_changed="onMapCenterChange"
          @dragend="syncMap"
        >
          <template slot="visible">
            <div class="create-map__target">
              <img src="/static/icon-target.svg" />
            </div>
            <GoogleMapSearch
              v-if="mapReady"
              :map="$refs.gmap.$mapObject"
              @result="onSearchResult"
            ></GoogleMapSearch>
            <div v-show="overlayVisible" class="map__overlay">
              <div>
                <strong>{{ locationObj.address }}</strong>
                <p>
                  Löysimme tämän osoitteen, onko se oikea? Voit muuttaa
                  osoitetietoja, jos tiedät tarkemman sijainnin.
                </p>
                <button class="button button--teal" @click="onOverlayYesClick">
                  Hyvältä näyttää!
                </button>
                <button class="button" @click="onOverlayNoClick">
                  Pieleen meni!
                </button>
              </div>
            </div>
          </template>
        </gmap-map>
      </div>
      <div class="mb-4">
        <label class="label" for="address">Osoite</label>
        <div class="control">
          <input
            v-model="locationObj.address"
            id="address"
            type="text"
            class="input is-medium"
          />
        </div>
        <p class="form-hint">
          Anna mahdollisimman tarkka osoite, esimerkiksi
          <i>Mallikuja 5 a 10, 00100 Helsinki</i>
        </p>
      </div>
      <button
        class="button button--teal create-section__button-next"
        :disabled="
          locationObj.address.length < 1 ||
          !locationObj.location.lat ||
          !locationObj.location.lng ||
          overlayVisible
        "
        @click="onNextClick"
      >
        Seuraava
      </button>
    </section>

    <section class="create-section create-section--disabled">
      <h2 class="create-section__title">Kategoria</h2>
      <p class="create-section__description">
        Mikä kategoria vastaa parhaiten paikkaasi - tullaanko sinne syömään,
        onko se kauppa tai muita palveluita tarjoava paikka vai onko siellä
        jotain hauskaa tekemistä?
      </p>
      <div class="category flex is-mobile">
        <div
          v-for="category in Object.keys(categories)"
          :key="category"
          class="w-1/3 category-selection"
        >
          <input
            type="radio"
            v-model="locationObj.category"
            :id="'category-' + category"
            name="category"
            :value="category"
          />
          <label :for="'category-' + category">
            <img
              class="category-selection__image"
              :src="`/static/icon-${category}.svg`"
            />
            <br />
            <span class="category-selection__title">
              {{ $t('categories.' + category) }}
            </span>
          </label>
        </div>
      </div>
      <div
        v-show="locationObj.category.length < 1"
        class="message message--yellow has-text-centered"
      >
        Valitse kategoria jatkaaksesi.
      </div>
      <button
        class="button button--teal create-section__button-next"
        :disabled="locationObj.category.length < 1"
        @click="onNextClick"
      >
        Seuraava
      </button>
    </section>

    <section class="create-section create-section--disabled">
      <h2 class="create-section__title">Lyhyt kuvaus</h2>
      <p class="create-section__description">
        Anna paikalle lyhyt ja ytimekäs kuvaus. Tämä näkyy palvelun
        karttanäkymässä, joten kuvaile hyvin!
      </p>
      <div>
        <textarea
          class="textarea"
          rows="5"
          v-model="locationObj.short_description"
        ></textarea>
        <strong>
          {{ locationObj.short_description.length }}/160 merkkiä käytetty
        </strong>
      </div>
      <div
        v-show="locationObj.short_description.length < 10"
        class="message message--yellow"
      >
        Kuvauksen täytyy olla ainakin 10 merkkiä pitkä.
      </div>
      <button
        class="button button--teal create-section__button-next"
        :disabled="
          locationObj.short_description.length < 10 ||
          locationObj.short_description.length > 160
        "
        @click="onNextClick"
      >
        Seuraava
      </button>
    </section>

    <section class="create-section create-section--disabled">
      <h2 class="create-section__title">Ikä</h2>
      <p>Minkä ikäisille paikka sopii?</p>
      <div class="age-selection-container has-text-centered">
        <div
          v-for="age in ['baby', 'toddler', 'preschooler', 'gradeschooler']"
          :key="age"
          class="inline-block age-selection"
        >
          <input :id="age" type="checkbox" :value="age" @change="onAgeChange" />
          <label class="tag is-medium" :for="age">
            {{ $t('ages.' + age) | capitalize }}
          </label>
        </div>
      </div>
      <div v-show="locationObj.ages.length < 1" class="message message--yellow">
        Valitse ainakin yksi vaihtoehto.
      </div>
      <button
        class="button button--teal create-section__button-next"
        :disabled="locationObj.ages.length < 1"
        @click="onNextClick"
      >
        Seuraava
      </button>
    </section>

    <section class="create-section create-section--disabled">
      <h2 class="create-section__title">Lisätiedot</h2>
      <p class="create-section__description">
        Mitä kaikkea kivaa paikasta löytyy? Valitse parhaimmat ominaisuudet. Ei
        hätää vaikkei kaikkea muistu nyt mieleen, voit aina lisätä paikkaan
        lisää ominaisuuksia myöhemmin.
      </p>

      <div class="has-text-centered">
        <div
          v-for="tag in combinedTags"
          :key="tag"
          class="inline-block tag-selection"
        >
          <input
            :value="tag"
            :id="tag"
            type="checkbox"
            class="checkbox"
            @change="onTagsChange"
          />
          <label class="tag is-medium" :for="tag">
            {{ $t('tags.' + tag) | capitalize }}
          </label>
        </div>
      </div>

      <button
        class="button button--teal create-section__button-next"
        @click="onNextClick"
      >
        Seuraava
      </button>
    </section>

    <section class="create-section create-section--disabled">
      <h2 class="create-section__title">Muut tiedot</h2>

      <div class="field">
        <label class="label" for="website">Verkko-osoite</label>
        <input
          v-model="locationObj.website"
          type="text"
          class="input is-medium"
        />
        <p class="form-hint">Esimerkiksi paikan Facebook- tai kotisivu</p>
      </div>

      <br />
      <button
        class="button button--teal create-section__button-next"
        :disabled="false"
        @click="onNextClick"
      >
        Seuraava
      </button>
    </section>

    <section class="create-section create-section--disabled">
      <h2 class="create-section__title">Yhteenveto</h2>
      <p class="create-section__description">
        Hyvää työtä! Jos et ole täysin tyytyväinen, niin voit palata ylöspäin
        tekemään korjauksia. Jos kaikki on ok paina tallenna!
      </p>

      <div class="summary">
        <strong>Nimi:</strong>
        {{ locationObj.name }}
        <br />
        <strong>Kategoria:</strong>
        <span v-if="locationObj.category.length > 0">
          {{ $t('categories.' + locationObj.category) }}
        </span>
        <br />
        <strong>Osoite:</strong>
        {{ locationObj.address }}
        <br />
        <strong>Kuvaus:</strong>
        {{ locationObj.short_description }}
      </div>
      <button
        :disabled="$v.$invalid"
        :class="[
          'button button--teal create-section__button-next',
          { 'is-loading': saveLoading },
        ]"
        @click="onSaveClick"
      >
        Tallenna
      </button>
      <div class="has-text-centered" v-if="saveError">
        <br />
        <div class="message message--red">
          Hups! Virhe tallennettaessa paikkaa :(
        </div>
      </div>
    </section>
    <div :class="['modal', { 'is-active': saveSuccess }]">
      <div class="modal-background"></div>
      <div class="modal-content text-center rounded p-4 bg-white">
        <p class="title is-1">🎉</p>
        <p class="subtitle is-2">Mahtavaa!</p>
        <p>Paikka {{ locationObj.name }} lisättiin Sandikseen onnistuneesti!</p>
        <br />
        <router-link :to="'/browse'" class="button is-large is-primary">
          Selaa paikkoja
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import GoogleMapSearch from '../components/GoogleMapSearch.vue';
import Multiselect from 'vue-multiselect';
import { globalMapOptions } from '../utils/map';
import categories from '../utils/categories';
import tags from '../utils/tags';
import api from '../utils/api';
import { capitalize } from '../utils/filters';
import zenscroll from 'zenscroll';
import throttle from 'lodash.throttle';
import { required, maxLength, minLength } from 'vuelidate/lib/validators';
import { sortByProp } from '../utils/utils';

export default {
  name: 'CreateLocation',
  components: {
    GoogleMapSearch,
    Multiselect,
  },
  data() {
    return {
      categories: categories.location,
      tags,
      mapCenter: null,
      reportedCenter: { lat: 60.1699, lng: 24.9384 },
      markerPosition: { lat: 60.1699, lng: 24.9384 },
      overlayVisible: false,
      saveLoading: false,
      saveError: false,
      saveSuccess: false,
      placesService: {},
      detailsService: {},
      queryResults: [],
      locationObj: {
        name: '',
        city: '',
        category: '',
        location: {
          lat: 0,
          lng: 0,
        },
        address: '',
        short_description: '',
        tags: [],
        ages: [],
        website: '',
      },
      mapOptions: Object.assign(
        {},
        { globalMapOptions },
        { zoomControlOptions: { position: 9 } }
      ),
      mapReady: false,
    };
  },
  validations: {
    locationObj: {
      ages: { required, minLength: minLength(1) },
      name: { required, minLength: minLength(1) },
      category: { required },
      location: { lat: required, lng: required },
      address: { required, minLength: minLength(5) },
      short_description: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(160),
      },
    },
  },
  created() {
    this.$store.dispatch('fetchCities');
    this.syncMap();
  },
  mounted() {
    this.$refs.gmap.$mapPromise.then(() => {
      this.placesService = new window.google.maps.places.AutocompleteService();
      this.detailsService = new window.google.maps.places.PlacesService(
        this.$el.querySelector('.results__attributions')
      );
      this.$refs.gmap.$mapPromise.then(() => (this.mapReady = true));
    });

    this.$el
      .querySelector('section')
      .classList.remove('create-section--disabled');
  },
  methods: {
    onMapCenterChange: throttle(function (center) {
      const lat = center.lat();
      const lng = center.lng();

      this.reportedCenter = { lat, lng };
    }),
    syncMap() {
      this.locationObj.location = this.mapCenter = this.reportedCenter;
    },
    onNameInput() {
      const vm = this;
      let input = this.locationObj.name;
      const bounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(60.297839, 25.254485),
        new window.google.maps.LatLng(60.030164, 24.82605)
      );

      if (input.length < 1) return [];

      this.placesService.getPlacePredictions(
        {
          input,
          bounds,
          componentRestrictions: { country: 'fi' },
          types: ['establishment'],
        },
        function (predictions, status) {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            vm.queryResults = predictions;
          } else {
            // No results
          }
        }
      );
    },
    onNameBlur() {
      const vm = this;

      setTimeout(function () {
        if (!document.activeElement.classList.contains('result')) {
          vm.queryResults = [];
        }
      }, 1);
    },
    onResultClick({ place_id }) {
      const vm = this;

      this.detailsService.getDetails(
        { placeId: place_id },
        function (place, status) {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            console.log(place);
            vm.locationObj.name = place.name;
            vm.locationObj.address = place.formatted_address;

            vm.queryResults = [];

            vm.locationObj.location = vm.mapCenter = vm.markerPosition = {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            };

            vm.overlayVisible = true;
          } else {
            // throw('PlacesService is not feeling well :(');
          }
        }
      );
    },
    onNextClick(event) {
      let nextSection = event.target.parentNode.nextElementSibling;
      nextSection.classList.remove('create-section--disabled');
      event.target.parentNode.removeChild(event.target);
      zenscroll.center(nextSection);
    },
    onOverlayYesClick() {
      this.overlayVisible = false;
    },
    onOverlayNoClick() {
      this.locationObj.address = '';
      this.overlayVisible = false;
    },
    onSearchResult(place) {
      // Update map
      const location = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };

      this.mapCenter = location;
      this.markerPosition = location;

      // Get location details provided by Google
      this.locationObj.location = location;
      this.locationObj.address = place.formatted_address;
    },
    onAgeChange(event) {
      if (event.target.checked) {
        event.target.nextElementSibling.classList.add('is-primary');
        this.locationObj.ages.push(event.target.value);
      } else {
        event.target.nextElementSibling.classList.remove('is-primary');
        this.locationObj.ages.splice(
          this.locationObj.ages.indexOf(event.target.value),
          1
        );
      }
    },
    onTagsChange(event) {
      if (event.target.checked) {
        event.target.nextElementSibling.classList.add('is-primary');
        this.locationObj.tags.push(event.target.value);
      } else {
        event.target.nextElementSibling.classList.remove('is-primary');
        this.locationObj.tags.splice(
          this.locationObj.tags.indexOf(event.target.value),
          1
        );
      }
    },
    onSaveClick() {
      if (this.$v.$invalid) return;

      const vm = this;
      vm.saveLoading = true;

      api.locations
        .post({ location: this.locationObj })
        .then(() => {
          vm.saveLoading = false;
          vm.saveSuccess = true;
        })
        .catch((error) => {
          vm.saveLoading = false;
          vm.saveError = true;
          console.error(error);
        });
    },
  },
  computed: {
    combinedTags() {
      return this.locationObj.category
        ? tags.all.concat(tags[this.locationObj.category])
        : tags.all;
    },
    cityOptions() {
      return [...this.$store.state.cities]
        .sort(sortByProp('name'))
        .map((city) => city.name);
    },
  },
  filters: { capitalize },
};
</script>

<style lang="scss" scoped>
@import '../scss/variables';

#create-location {
  padding-bottom: 50vh;
}

h1 {
  margin-top: 1.5rem;
  text-align: center;
}

.create-section {
  @apply bg-white rounded shadow p-4;
  max-width: 40rem;
  margin: 0 auto;

  &:first-of-type {
    margin-top: 5rem;
  }

  &--disabled {
    opacity: 0.5;
    filter: grayscale(100%);
    pointer-events: none;
    user-select: none;
  }

  &__title {
    @apply font-bold text-lg;
  }

  &__title,
  &__description {
    @apply mb-2;
  }

  &__button-next {
    display: block;
    margin: 0 auto;
  }
}

.create-section + .create-section {
  margin-top: 5rem;
}

.results-container {
  position: relative;
}

.results {
  position: absolute;
  width: 100%;
  border: 1px solid #eee;
  background-color: white;
  z-index: 10;
}

.result {
  width: 100%;
  padding: 0.5em 1em;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.create-map-container {
  position: relative;
  width: 100%;
  height: 20rem;
  border: 1px solid rgba(0, 0, 0, 0.25);
  margin-bottom: 1rem;
}

.create-map__target {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  img {
    width: 2rem;
    height: 2rem;
  }
}

.map__overlay {
  display: flex;
  position: absolute;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);

  > div {
    width: 100%;
    padding: 1rem;
    background-color: white;
    text-align: center;

    > * + * {
      margin-top: 0.5rem;
    }
  }
}

.category {
  text-align: center;
  padding: 1rem;
}

.category-selection {
  padding: 0.1rem;

  input {
    display: none;
  }

  input + label {
    @apply border border-teal;
    display: block;
    width: 100%;
    padding: 1rem 0;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
  }

  input:checked + label {
    @apply bg-teal text-white;
  }

  &__image {
    width: 2.5rem;
    margin-bottom: 0.5em;
  }

  @media (min-width: $bp-small) {
    input + label {
      padding: 2rem 0;
    }
  }
}

.age-selection-container {
  padding: 1rem 0;
}

.age-selection,
.tag-selection {
  margin: 0.25rem;

  input {
    display: none;
  }
}

.address {
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-top: none;
  margin-bottom: 0.5rem;
  line-height: 2.5;
  max-height: 100rem;

  &:empty {
    max-height: 1px;
    overflow: hidden;
  }
}

.summary {
  margin-top: 1.5rem;
}

.create-map-form {
  margin-bottom: 5rem;
  padding: 0 1rem;
}
</style>

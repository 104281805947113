<template>
  <article class="container mb-5 pt-5">
    <h1 class="h2 pb-5">Yrityksille ja yhteisöille</h1>
    <div class="p-5 bg-white">
      <p class="mb-4">
        Sandis on avoin kaikille. Olemme kehittäneet Sandiksen verkkopalvelua
        paikaksi, josta löytyy lapsiperheitä kiinnostavia paikkoja, tapahtumia
        ja sellaista hiljaista tietoa, joka helposti unohtuu. Tästä syystä
        Sandis on avoin kaikille, niin yksityisille henkilöille kuin
        yrityksille. Kuka tahansa rekisteröitynyt voi ilmoittaa ilmaiseksi
        tapahtumista tai kivoista paikoista, joista voisi olla iloa
        lapsellisille. Jos edustat yritystä tai yhteisöä, niin saat ihan luvan
        kanssa perustaa tilin ja alkaa päivittämään Sandista.
      </p>
      <h3 class="h4 mb-3">Mainostaminen</h3>
      <p class="mb-3">
        Kiinnostaako tavoittaa yli 10 000 vanhempaa? Sandis tarjoaa ruuhkattoman
        ympäristön mainostaa ruuhkavuosien sankareille. Olemme järjestäneet
        yhteistyökumppaneiden kanssa arvontoja, sponsoroituja mainoksia ympäri
        Sandiksen verkkosivujen, uutiskirjeiden sponsorointia, sosiaalisen
        median julkaisuja
      </p>
      <p class="mb-3">
        Kuule lisää mitä kaikkea kanssamme voi tehdä lähettämällä viestiä
        <a href="mailto:info@playgroundhelsinki.fi">
          info@playgroundhelsinki.fi
        </a>
        .
      </p>
      <p class="mb-3">
        Täällä on näkynyt muun muassa: Xplora Suomi, Samsung, If Vakuutus,
        Messukeskus ja useita pienempiäkin toimijoita.
      </p>
    </div>
  </article>
</template>

<script>
export default {
  name: 'Collaboration',
};
</script>

<template>
  <div class="search p-4">
    <input
      class="input"
      placeholder="Hae paikkoja ja tapahtumia"
      @keyup="onSearchInputChange"
      ref="searchInput"
    />
    <ul class="search-results">
      <li
        class="search-result"
        v-for="result in $store.state.search.results"
        :key="result.id"
      >
        <a
          :href="`/${result.type}/${result.id}/${result.slug}`"
          @click="onResultClick"
        >
          <small class="search-result__subtitle">
            {{ getTypeAndCategory(result) }}, {{ result.city }}
          </small>
          <p class="search-result__title">{{ result.name }}</p>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';
import api from '@/api';

export default {
  name: 'Search',
  data() {
    return {
      isLoading: false,
    };
  },
  created() {
    window.addEventListener('scroll', this.onWindowScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.onWindowScroll);
  },
  mounted() {
    this.$refs.searchInput.focus();
  },
  methods: {
    getTypeAndCategory: function (thing) {
      const i18nString =
        thing.type === 'location'
          ? `categories.${thing.category}`
          : `categories.event`;

      return this.$t(i18nString);
    },
    onSearchInputChange: debounce(async function (e) {
      try {
        const query = e.target.value;

        if (query.length < 1) {
          this.$store.dispatch('search/setResults', []);
          return;
        }

        const response = await api.search.get(e.target.value);
        this.$store.dispatch('search/setResults', response.data.data);
      } catch (error) {
        console.log('search error', error);
      }
    }, 500),
    onResultClick: function () {
      this.$store.dispatch('search/toggleVisibility', false);
    },
    onWindowScroll: throttle(function () {
      if (window.scrollY > 250) {
        this.$store.dispatch('search/toggleVisibility', false);
      }
    }, 200),
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/variables';

.search {
  position: absolute;
  top: calc(#{$header-height} - 2px);
  right: 0;
  width: 100%;
  bottom: 10%;
  background-color: white;
  z-index: 39;
  overflow-y: hidden;
  border-bottom: 2px rgba(#eee, 0.5) solid;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  @media (min-width: $bp-mobile) {
    left: auto;
    max-width: 25rem;
    height: 100%;
    max-height: 30rem;
  }
}

.search-results {
  overflow-y: scroll;
}

.search-result {
  margin-bottom: 0.5rem;
}

.search-result__subtitle {
  color: $color-primary-blue;
}

.search-result__title {
  color: $color-primary-lightblack;
}
</style>

<template>
  <div class="google-search">
    <input
      id="search-location"
      placeholder="Hae osoitetta"
      class="input is-medium google-search__text-input"
      @input="onSearchInput"
      v-model="searchValue"
      type="text"
      autocomplete="off"
    />
    <div class="google-search__query-results">
      <ul>
        <li
          v-for="result in queryResults"
          :key="result.id"
          @click="onResultClick(result.place_id)"
        >
          {{ result.description }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    map: Object,
  },
  data() {
    return {
      searchService: {},
      detailsService: {},
      searchValue: '',
      queryResults: [],
    };
  },
  created: function () {
    const vm = this;

    vm.init(this.map);
  },
  methods: {
    init: function (map) {
      this.searchService = new window.google.maps.places.AutocompleteService(
        map
      );
      this.detailsService = new window.google.maps.places.PlacesService(map);
    },
    onSearchInput: function ($event) {
      const vm = this;
      const input = $event.target.value;

      // https://www.mapdevelopers.com/geocode_bounding_box.php
      const bounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(60.297839, 25.254485),
        new window.google.maps.LatLng(60.030164, 24.82605)
      );

      if (input.length > 0) {
        this.searchService.getPlacePredictions(
          {
            input,
            bounds,
            componentRestrictions: { country: 'fi' },
            types: ['address'],
          },
          function (predictions, status) {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              vm.queryResults = predictions;
            } else {
              throw new Error('AutocompleteService is not feeling well :(');
            }
          }
        );
      } else {
        vm.queryResults = [];
      }
    },
    onResultClick: function (placeId) {
      const vm = this;

      this.detailsService.getDetails({ placeId }, function (place, status) {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          vm.$emit('result', place);

          vm.searchValue = '';
          vm.queryResults = [];
        } else {
          throw new Error('PlacesService is not feeling well :(');
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.google-search {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  right: 0.5rem;
  z-index: 10;

  &__text-input {
    width: 100%;
  }

  &__query-results {
    width: 100%;
    background-color: white;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      &:not(:empty) {
        padding: 0 0.5rem;
      }

      li {
        font-size: 1rem;
        line-height: 2.5rem;

        &:not(:last-child) {
          border-bottom: 1px solid #ced4da;
        }
      }
    }
  }
}
</style>

import api from '../../api';
import auth from '../../utils/auth';

// Initial state
const state = {
  error: false,
  loading: false,
  id: null,
  name: null,
  email: null,
  points: {
    currentPoints: null,
    totalPoints: null,
  },
};

// Getters
const getters = {
  isLoggedIn: (state) =>
    !state.loading && !state.error && Number.isInteger(state.id),
};

// Actions
const actions = {
  async checkLogin({ commit, dispatch }) {
    try {
      await commit('setLoading', true);
      await auth.getSession();
      await dispatch('fetchUser');
      commit('setLoading', false);
    } catch (err) {
      // Not logged in
    }
  },
  async fetchUser({ commit }) {
    try {
      const response = await api.user.get();
      const { data } = response.data;

      commit('setUser', { email: data.email, id: data.id, name: data.name });
      commit('setPoints', {
        currentPoints: data.points.current_points,
        totalPoints: data.points.total_points,
      });
    } catch (err) {
      console.log('Error fetching user', err);
    }
  },
  async login({ commit, dispatch }, { username, password }) {
    commit('setError', false);
    commit('setLoading', true);

    try {
      await auth.login(username, password);
      await dispatch('fetchUser');

      commit('setLoading', false);
    } catch (error) {
      commit('setError', true);
    }
  },
  async logout({ commit }) {
    try {
      await auth.logout();
      await commit('setUser', {
        email: null,
        id: null,
        name: null,
      });
      commit('setPoints', {
        currentPoints: null,
        totalPoints: null,
      });
    } catch (err) {
      console.log('error logging out', err);
    }
  },
  updateUser({ commit }, updatedAttributes) {
    commit('setUser', updatedAttributes);
  },
};

// Mutations
const mutations = {
  setError(state, isError) {
    state.error = isError;
  },
  setLoading(state, isLoading) {
    state.loading = isLoading;
  },
  setPoints(state, { currentPoints, totalPoints }) {
    state.points = {
      currentPoints,
      totalPoints,
    };
  },
  setUser(state, { email, id, name }) {
    if (email) state.email = email;
    if (id) state.id = id;
    if (name) state.name = name;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

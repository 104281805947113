<template>
  <article class="section forgot-password">
    <header class="has-text-centered">
      <h1 class="title is-1">Unohtuiko salasana?</h1>
      <div class="container container--narrow">
        <p class="subtitle is-5">
          Ei hätää, sitä sattuu! Lasten kanssa puuhastellessa on tärkeämpiäkin
          asioita muistettavana. Täällä voit tarvittaessa vaihtaa salasanasi.
        </p>
      </div>
    </header>
    <div class="container forgot-password__content">
      <div v-if="!sendSuccess">
        <div class="field">
          <label class="label" for="username">
            Käyttäjätunnus tai sähköpostiosoite
          </label>
          <div class="control">
            <input v-model="username" class="input is-medium" type="text" />
          </div>
          <p class="help">
            Lähetämme sähköpostiosoitteeseesi linkin jonka avulla voit luoda
            itsellesi uuden salasanan.
          </p>
        </div>

        <div class="field">
          <div class="control">
            <button
              :disabled="username.length < 2"
              @click="onSendVerificationClick"
              class="button is-primary"
            >
              Lähetä linkki sähköpostiini
            </button>
            <router-link
              :to="'/resetpassword'"
              tag="button"
              class="button is-white"
            >
              Minulla on jo palautuskoodi
            </router-link>
          </div>
        </div>

        <transition name="transition-error">
          <div
            v-if="sendFailed"
            class="notification is-danger forgot-password__error"
          >
            Hups! Jotain meni pieleen salasanan palautuksessa, kirjoitithan
            tunnuksesi tai sähköpostiosoitteesi oikein?
          </div>
        </transition>
      </div>
      <div v-if="sendSuccess" class="notification is-success">
        <p>
          Apu on matkalla! Lähetimme sinulle ohjeet salasanasi palauttamiseksi
          osoitteeseen {{ deliveryEmail }}.
        </p>
      </div>
    </div>
  </article>
</template>

<script>
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
const { VUE_APP_COGNITO_USER_POOL, VUE_APP_COGNITO_APP_ID } = process.env;

export default {
  name: 'ForgotPassword',
  data() {
    return {
      username: '',
      enterVerificationVisible: false,
      sendFailed: false,
      sendSuccess: false,
      deliveryEmail: '',
    };
  },
  methods: {
    onSendVerificationClick() {
      const vm = this;
      vm.sendFailed = false;

      const userPool = new CognitoUserPool({
        UserPoolId: VUE_APP_COGNITO_USER_POOL,
        ClientId: VUE_APP_COGNITO_APP_ID,
      });

      const user = new CognitoUser({
        Username: this.username,
        Pool: userPool,
      });

      user.forgotPassword({
        onSuccess() {},
        onFailure(err) {
          console.error(err);
          vm.sendFailed = true;
        },
        inputVerificationCode(data) {
          vm.sendSuccess = true;
          vm.deliveryEmail = data.CodeDeliveryDetails.Destination;
        },
      });
    },
  },
};
</script>

<style lang="scss">
.forgot-password__content {
  margin-top: 2rem;
  max-width: 30rem;
}

.forgot-password__error {
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;

  &.transition-error-enter-active {
    animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>

<template>
  <button @click="onFavoriteClick" role="button" class="button">
    <span class="icon is-small mr-2">
      <i
        :class="[
          'fa',
          isFavorite ? 'fa-heart' : 'fa-heart-o',
          { 'text-red': isFavorite, 'animation-pulse': isFavorite },
        ]"
      ></i>
    </span>
    <span v-if="hasLabel">{{ label }}</span>
  </button>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    color: { type: String, default: 'white' },
    hasLabel: { type: Boolean, default: false },
    id: { type: Number, required: true },
    isFavorite: Boolean,
    isOutlined: Boolean,
    startDate: String,
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn',
    }),
    label() {
      return this.isFavorite ? 'Suosikeissasi' : 'Lisää suosikkeihin';
    },
  },
  methods: {
    onFavoriteClick() {
      if (this.isLoggedIn) {
        this.isFavorite
          ? this.$store
              .dispatch('removeFavorite', { thingId: this.id })
              .then(() => this.$emit('removeFavorite'))
          : this.$store
              .dispatch('addFavorite', { thingId: this.id })
              .then(() => this.$emit('addFavorite'));
      } else {
        this.$store.dispatch('toggleLogin', { visible: true });
      }
    },
  },
};
</script>

<style lang="scss">
.animation-pulse {
  transform: scale(1);
  transition: transform 0.4s cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}
</style>

<template>
  <section class="section favorites">
    <div class="container">
      <div class="has-text-centered">
        <h1 class="title is-2">Suosikkisi</h1>
      </div>
      <div
        v-if="favorites.events.length < 1 && favorites.locations.length < 1"
        class="has-text-centered"
      >
        <p>
          Et ole vielä merkinnyt yhtään paikkaa tai tapahtumaa suosikkeihisi :(
        </p>
      </div>
      <div v-if="favorites.events.length > 0">
        <h2 class="title is-4">Tapahtumat</h2>
        <ul>
          <li v-for="event in favorites.events" :key="event.id">
            <router-link :to="`/event/${event.id}/${event.slug}`">
              {{ event.name }}
            </router-link>
          </li>
        </ul>
      </div>
      <br />
      <div v-if="favorites.locations.length > 0">
        <h2 class="title is-4">Paikat</h2>
        <ul>
          <li v-for="location in favorites.locations" :key="location.id">
            <router-link :to="`/location/${location.id}/${location.slug}`">
              {{ location.name }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import api from '@/utils/api';

export default {
  name: 'Favorites',
  data() {
    return {
      favorites: {
        events: [],
        locations: [],
      },
    };
  },
  created() {
    const vm = this;

    api.favorites.getAll().then(({ data }) => {
      vm.favorites = data;
    });
  },
};
</script>

<style></style>

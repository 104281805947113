<template>
  <div class="bg-blue-darker text-white">
    <div class="container p-4">
      <div v-if="isLoggedIn">
        <small v-if="!user.name">
          <strong class="text-white">
            Sinulla ei ole vielä nimimerkkiä, voit valita nimimerkin
            <router-link to="/profile/name">täältä</router-link>
            .
          </strong>
        </small>
        <h2 class="font-bold text-4xl">
          {{ user.name || `Sandislainen#${user.id}` }}
        </h2>
        <div class="flex">
          <div class="text-center p-2 rounded">
            <div class="text-grey-dark uppercase text-xs">Kokonaispisteet</div>
            <span class="text-2xl font-bold">{{ points.totalPoints }}</span>
          </div>
          <div class="text-center p-2 rounded">
            <div class="text-grey-dark uppercase text-xs">Käydyt paikat</div>
            <span class="text-2xl font-bold">{{ stats.checkins }}</span>
          </div>
        </div>
      </div>
      <div v-else>
        <h2 class="font-bold text-4xl">Oma Sandis</h2>
        <p class="mb-4">
          Kirjaudu sisään tai rekisteröidy niin saat enemmän irti Sandiksesta.
        </p>
        <button
          class="button button--orange rounded-full"
          @click="onLoginClick"
        >
          Kirjaudu sisään
        </button>
        <router-link
          to="/register"
          class="button roundede-full text-white ml-1"
        >
          Rekisteröidy
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import api from '@/api';

export default {
  name: 'UserHeader',
  data() {
    return {
      stats: {
        checkins: null,
        favorites: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn',
    }),
    points() {
      return this.$store.state.user.points;
    },
    user() {
      return this.$store.state.user;
    },
  },
  created() {
    this.fetchStats();
  },
  methods: {
    async fetchStats() {
      try {
        const { data } = await api.user.getStats();
        this.stats = data;
        this.stats = { ...data };
      } catch (err) {
        console.log('error fetching stats', err);
      }
    },
    onLoginClick() {
      this.$store.dispatch('toggleLogin', { visible: true });
    },
  },
};
</script>

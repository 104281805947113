<template>
  <div></div>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    placeholder: { type: Boolean, default: true },
    userId: Number,
  },
  computed: {
    src() {
      if (this.placeholder) {
        return `https://api.hello-avatar.com/adorables/200/${this.userId}`;
      } else {
        return '';
      }
    },
  },
};
</script>

<style lang="scss">
.user-avatar {
  display: inline-block;
  width: 2em;
  height: 2em;
  border-radius: 9999px;
  border: 2px solid white;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  overflow: hidden;

  > img {
    width: 100%;
    height: 100%;
    border-radius: 9999px;
    overflow: hidden;
  }
}
</style>

<template>
  <div :class="['dropdown is-right', { 'is-active': isOpen }]">
    <div class="dropdown-trigger">
      <span role="button" class="button" @click="onListButtonClick">
        <span class="icon is-small"><i class="fa fa-plus mr-2"></i></span>
        <span>Lisää listalle</span>
      </span>
    </div>
    <div class="dropdown-menu">
      <div class="dropdown-content">
        <template v-if="!isError && !isSuccess">
          <!-- pagination -->
          <div v-if="pagination.total_pages > 1" class="dropdown-item">
            <div class="level">
              <div class="level-left">
                <button
                  class="button is-link is-inverted"
                  @click="() => handlePageChange(pagination.page_number - 1)"
                  :disabled="pagination.page_number === 1"
                >
                  <span class="icon is-small">
                    <i class="fa fa-chevron-left"></i>
                  </span>
                </button>
              </div>
              <div class="level-right">
                <button
                  class="button is-link is-inverted"
                  @click="() => handlePageChange(pagination.page_number + 1)"
                  :disabled="pagination.page_number === pagination.total_pages"
                >
                  <span class="icon is-small">
                    <i class="fa fa-chevron-right"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <!-- list items -->
          <a
            href="#"
            @click.stop.prevent="onListClick(list.id)"
            v-for="list in lists"
            :key="list.id"
            class="dropdown-item"
          >
            {{ list.name }}
          </a>
          <!-- actions -->
          <div class="dropdown-divider"></div>
          <router-link :to="'/create/list'" class="dropdown-item">
            Luo uusi lista
          </router-link>
        </template>
        <div v-if="isSuccess">
          <div class="dropdown-item">
            <div class="notification is-success">
              <button class="delete" @click="isSuccess = false"></button>
              Lisätty listalle!
            </div>
          </div>
        </div>
        <div v-if="isError">
          <div class="dropdown-item">
            <div class="notification is-danger">
              <button class="delete" @click="isError = false"></button>
              Hups! Jotain meni pieleen.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import api from '../utils/api';

export default {
  data() {
    return {
      isOpen: false,
      isLoading: false,
      isSuccess: false,
      isError: false,
      lists: [],
      pagination: {},
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn',
    }),
  },
  methods: {
    handlePageChange: async function (page) {
      await this.fetchUserLists(page);
    },
    fetchUserLists: async function (page = 1) {
      const { lists, pagination } = await api.user.getLists({
        sort_by: 'updated_at',
        page,
        page_size: 10,
      });

      this.lists = lists;
      this.pagination = pagination;
    },
    onListClick(listId) {
      const vm = this;
      if (vm.isLoading) return;

      vm.isLoading = true;
      api.lists
        .addItem(listId, this.id)
        .then(() => {
          vm.isLoading = false;
          vm.isSuccess = true;
        })
        .catch(() => {
          vm.isLoading = false;
          vm.isError = true;
        });
    },
    onListButtonClick: async function () {
      if (this.isLoggedIn) {
        if (this.lists.length < 1) await this.fetchUserLists();
        this.isOpen = !this.isOpen;
      } else {
        this.$store.dispatch('toggleLogin', { visible: true });
      }
    },
  },
  props: {
    id: String,
    isOutlined: Boolean,
    type: String,
  },
};
</script>

<template>
  <div class="main-nav ds-bg-lightgrey" ref="nav">
    <router-link to="/" class="main-nav-item">Etusivu</router-link>

    <router-link to="/lists" class="main-nav-item">Listat</router-link>

    <router-link
      v-for="item in browseDropdownItems"
      :key="item.target"
      :to="`/browse/${item.target}`"
      class="main-nav-item"
    >
      {{ item.label }}
    </router-link>

    <router-link
      v-if="isLoggedIn"
      to="/create/location"
      class="button button--success--add ml-4"
    >
      <span class="mr-2">Lisää paikka</span>
      <i class="fas fa-plus"></i>
    </router-link>

    <router-link
      v-if="isLoggedIn"
      to="/create/event"
      class="button button--success--add ml-4"
    >
      <span class="mr-2">Lisää tapahtuma</span>
      <i class="fas fa-plus"></i>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      browseDropdownItems: [
        {
          target: 'events',
          label: 'Tapahtumat',
        },
        {
          target: 'locations/activity',
          label: 'Tekemistä',
        },
        {
          target: 'locations/restaurant',
          label: 'Ravintolat',
        },
        {
          target: 'locations/service',
          label: 'Liikkeet ja palvelut',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn',
    }),
  },
  mounted() {
    this.$nextTick(() => this.centerActiveLink());
  },
  methods: {
    centerActiveLink() {
      const nav = this.$refs.nav;
      const activeLink = nav.querySelector('.router-link-exact-active');

      if (activeLink) {
        const target =
          activeLink.offsetLeft -
          (nav.offsetWidth / 2 - activeLink.offsetWidth / 2);
        this.$refs.nav.scrollTo(target, 0);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.main-nav {
  @apply flex
    items-center
    justify-start
    overflow-scroll
    p-4;

  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  @screen md {
    @apply justify-center flex-wrap;
  }
}

.main-nav-item {
  @apply text-blue-darker
    font-bold
    whitespace-no-wrap
    pt-1
    border-b-4
    border-transparent;

  & + & {
    @apply ml-4;
  }

  &.router-link-exact-active {
    border-color: #f7c5b0;
  }
}
</style>

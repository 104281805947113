const user = (api) => ({
  get() {
    return api.get('/user');
  },
  getStats() {
    return api.get('/user/stats');
  },
  update(updatedAttributes) {
    return api.patch('/user', { user: updatedAttributes });
  },
});

export default user;

<template>
  <section class="block-list">
    <ListHero :id="cmsData.id" :colorOverride="cmsData.bgcolor"></ListHero>
  </section>
</template>

<script>
import ListHero from '@/components/ListHero';

export default {
  name: 'BlockList',
  components: { ListHero },
  props: {
    cmsData: Object,
  },
};
</script>

<style lang="scss"></style>

<template>
  <div>
    <UserHeader />
    <div class="container md:flex">
      <div class="w-full md:w-2/3"></div>
      <div
        class="flex flex-col items-center md:items-end w-full md:w-1/3 pl-4 pb-4"
      >
        <div class="my-2"><TopUsers /></div>
        <ActivityFeed />
      </div>
    </div>
  </div>
</template>

<script>
import UserHeader from './UserHeader';
import TopUsers from '../Front/TopUsers';
import ActivityFeed from '../Front/ActivityFeed';

export default {
  name: 'Community',
  components: {
    ActivityFeed,
    UserHeader,
    TopUsers,
  },
};
</script>

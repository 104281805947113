<template>
  <div class="image-grid mt-4 w-full max-w-xs ds-bg-white p-4">
    <div class="flex justify-between items-center mb-2">
      <h3 class="font-bold">Kuvia</h3>
      <button class="button" @click="() => (isCreateImageVisible = true)">
        + Lisää uusi
      </button>
    </div>

    <div v-if="images.length > 0" class="image-grid">
      <div class="image-grid-main">
        <img
          :src="images[0].sizes.small"
          @click="() => handleImageClick(images[0])"
        />
      </div>
      <div v-if="images.length > 1" class="image-grid-scroller">
        <img
          v-for="image in images.slice(1)"
          :key="image.id"
          :src="image.sizes.small"
          @click="() => handleImageClick(image)"
        />
      </div>
    </div>

    <Modal :onCloseClick="handleViewCloseClick" v-if="isViewImageVisible">
      <div><img :src="viewedImage" /></div>
    </Modal>
    <Modal v-if="isCreateImageVisible" :onCloseClick="handleCreateCloseClick">
      <div class="rounded bg-white shadow p-4">
        <Upload
          :endpoint="`/things/${id}/images`"
          :onSuccess="handleUploadSuccess"
        />
      </div>
    </Modal>
    <Modal
      v-if="isCreateImageSuccessVisible"
      :onCloseClick="() => (isCreateImageSuccessVisible = false)"
    >
      <div class="rounded bg-white shadow p-4">
        <p>Kuva lisätty onnistuneesti, kiitos!</p>
        <br />
        <button
          class="button button--primary--blue"
          @click="() => (isCreateImageSuccessVisible = false)"
        >
          Eteenpäin
        </button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';
import Upload from '@/components/Upload';

export default {
  name: 'ImageGrid',
  components: {
    Modal,
    Upload,
  },
  data() {
    return {
      isCreateImageVisible: false,
      isViewImageVisible: false,
      isCreateImageSuccessVisible: false,
      viewedImage: null,
    };
  },
  props: {
    id: Number,
    images: Array,
    onUploadSuccess: Function,
  },
  methods: {
    handleCreateCloseClick() {
      this.isCreateImageVisible = false;
    },
    handleImageClick(image) {
      this.isViewImageVisible = true;
      this.viewedImage = image.sizes.large;
    },
    handleUploadSuccess() {
      this.isCreateImageVisible = false;
      this.isCreateImageSuccessVisible = true;
      this.onUploadSuccess();
    },
    handleViewCloseClick() {
      this.isViewImageVisible = false;
      this.viewedImage = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.image-grid {
  img {
    cursor: pointer;
  }
}

.image-grid-scroller {
  display: flex;
  overflow: scroll;
  padding-bottom: 1rem;

  img {
    width: 6rem;
    height: 6rem;
    object-fit: cover;
  }

  img + img {
    margin-left: 0.5rem;
  }
}
</style>

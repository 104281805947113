<template>
  <div class="mapkit-map-container" ref="container"></div>
</template>

<script>
const defaultOpts = {
  isRotationEnabled: false,
  showsCompass: false,
  showsMapTypeControl: false,
  showsUserLocationControl: true,
};

const defaultCalloutDelegate = {
  calloutContentForAnnotation: (item) => {
    const { data } = item;
    const {
      name = '',
      short_description = '',
      id = '',
      type = '',
      slug = '',
    } = data;
    const el = document.createElement('div');
    el.className = 'map__callout';

    const content = `
      <strong>${name}</strong>
      <p>${short_description}</p>
      <a href="/${type}/${id}/${slug}">Lue lisää</a>
    `;

    el.innerHTML = content;

    return el;
  },
};

const getAnnotations = (items, disableCallout = false) => {
  const annotations = items.map((item) => {
    const position = new mapkit.Coordinate(
      item.location.lat,
      item.location.lng
    );

    return new mapkit.MarkerAnnotation(position, {
      title: item.name,
      clusteringIdentifier: 'default',
      data: item,
      callout: disableCallout ? {} : defaultCalloutDelegate,
      // glyphImage: { 1: "glyphImage.png" },
      // selectedGlyphImage: { 1: "detailedIcon.png", 2: "detailedIcon_2x.png", 3: "detailedIcon_3x.png" }
    });
  });

  return annotations;
};

export default {
  name: 'Map',
  data() {
    return {
      map: undefined,
    };
  },
  props: {
    center: Object,
    disableCallout: Boolean,
    fitAllItems: Boolean,
    items: Array,
  },
  mounted() {
    console.log('map mounted');
    const { center, disableCallout, fitAllItems, items } = this;
    const $container = this.$refs.container;

    const defaultCenter = center
      ? new mapkit.Coordinate(center.lat, center.lng)
      : null;

    const annotations = getAnnotations(items, disableCallout);

    this.map = new mapkit.Map($container, {
      ...defaultOpts,
      center: defaultCenter,
      cameraDistance: 1000,
      annotations,
    });

    if (fitAllItems) {
      this.map.showItems(this.map.annotations);
    }

    // Map events
    this.map.addEventListener('scroll-end', (e) => {
      const map = e.target;
      if (map) {
        const { latitude, longitude } = map.center;
        this.$emit('center-change', { lat: latitude, lng: longitude });
      }
    });
  },
  methods: {
    setCenter({ lat, lng }) {
      if (this.map) {
        this.map.setCenterAnimated(new mapkit.Coordinate(lat, lng), false);
        this.map.setCameraDistanceAnimated(this.map.cameraDistance, false);
      }
    },
  },
  watch: {
    items(items) {
      if (this.map) {
        const newIds = items.map((item) => item.id);
        const oldIds = this.map.annotations.map(
          (annotation) => annotation.data.id
        );
        const annotationsToRemove = this.map.annotations.filter(
          (annotation) => !newIds.includes(annotation.data.id)
        );
        const newItems = items.filter((item) => !oldIds.includes(item.id));
        const annotationsToAdd = getAnnotations(newItems, this.disableCallout);

        this.map.removeAnnotations(annotationsToRemove);
        this.map.addAnnotations(annotationsToAdd);
      }
    },
  },
};
</script>

<style>
.mapkit-map-container {
  width: 100%;
  height: 100%;
}

.map__callout {
  max-width: 268px;
  font-size: 14px;
}
</style>

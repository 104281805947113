<template>
  <div class="create-review">
    <form
      v-show="!isSuccess && !isError"
      class="rounded bg-white shadow p-4"
      @submit.prevent="onReviewSubmit"
    >
      <h2 class="font-bold text-lg mb-6">Arvostele {{ name }}</h2>
      <fieldset class="field starability-grow mb-6">
        <legend class="label">Arvosana</legend>
        <input
          type="radio"
          id="no-rate"
          class="input-no-rate"
          name="rating"
          value="0"
          checked
          aria-label="No rating."
        />
        <input
          type="radio"
          id="rating-1"
          name="rating"
          value="1"
          v-model="rating"
        />
        <label for="rating-1" title="Kehno">1 star</label>
        <input
          type="radio"
          id="rating-2"
          name="rating"
          value="2"
          v-model="rating"
        />
        <label for="rating-2" title="Ei kummoinen">2 stars</label>
        <input
          type="radio"
          id="rating-3"
          name="rating"
          value="3"
          v-model="rating"
        />
        <label for="rating-3" title="Ihan kiva">3 stars</label>
        <input
          type="radio"
          id="rating-4"
          name="rating"
          value="4"
          v-model="rating"
        />
        <label for="rating-4" title="Hyvä">4 stars</label>
        <input
          type="radio"
          id="rating-5"
          name="rating"
          value="5"
          v-model="rating"
        />
        <label for="rating-5" title="Mahtava">5 stars</label>
        <p class="help create-review__rating-help"></p>
      </fieldset>

      <div class="field">
        <label for="" class="label">Arvostelu</label>
        <div class="control">
          <textarea
            name="review"
            id=""
            cols="30"
            rows="5"
            class="textarea"
            v-model="review"
          ></textarea>
        </div>
      </div>

      <div class="flex justify-end">
        <button class="button mr-2" @click.stop.prevent="onClose">
          Peruuta
        </button>
        <button
          :disabled="$v.$invalid"
          type="submit"
          :class="['button button--teal', { 'is-loading': isLoading }]"
        >
          Arvostele
        </button>
      </div>
    </form>
    <div v-if="isSuccess" class="rounded bg-white shadow p-4">
      <p>
        Kiitos että jaoit kokemuksesi tästä paikasta!
        <br />
        <br />
        <button class="button button--primary--blue" @click="onClose">
          Eipä kestä!
        </button>
      </p>
    </div>
    <div v-if="isError" class="rounded bg-white shadow p-4">
      <p>
        {{ errorMessage }}
        <br />
        <br />
        <button class="button button--primary--blue" @click="isError = false">
          Kokeile uudestaan
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import api from '../utils/api';
import {
  required,
  minLength,
  maxLength,
  between,
} from 'vuelidate/lib/validators';

export default {
  name: 'CreateReview',
  data() {
    return {
      isLoading: false,
      isError: false,
      isSuccess: false,
      errorMessage: '',
      rating: undefined,
      review: undefined,
    };
  },
  props: {
    locationId: String,
    name: String,
    onClose: Function,
  },
  validations: {
    rating: {
      required,
      between: between(1, 5),
    },
    review: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(1000),
    },
  },
  methods: {
    onReviewSubmit() {
      const vm = this;
      vm.isLoading = true;

      api.reviews
        .post(this.locationId, {
          rating: this.rating,
          description: this.review,
        })
        .then(() => {
          vm.isLoading = false;
          vm.isSuccess = true;
        })
        .catch((error) => {
          vm.isLoading = false;
          vm.isError = true;
          const errorCode = error.response.data.code;
          vm.errorMessage =
            errorCode === 'ConditionalCheckFailedException'
              ? 'Hupsista, olet jo arvioinut tämän kohteen!'
              : 'Hups, jotain meni pieleen! Yritäthän hetken päästä uudelleen.';
        });
    },
  },
};
</script>

<style src="starability/starability-css/starability-grow.css"></style>

import axios from 'axios';
import auth from './auth';
const { VUE_APP_BACKEND_URL } = process.env;

const apiClient = axios.create({
  baseURL: VUE_APP_BACKEND_URL,
});

apiClient.interceptors.request.use((config) => {
  return auth
    .getApiToken()
    .then((token) => {
      return Object.assign({}, config, { headers: { Authorization: token } });
    })
    .catch(() => config);
});

const api = {
  locations: {
    getAll(params) {
      return apiClient.get(`/locations`, { params }).then(({ data }) => data);
    },
    get(id) {
      return apiClient.get(`/locations/${id}`).then(({ data }) => data);
    },
    post(data) {
      return apiClient.post(`/locations`, data);
    },
    edit(id, changes) {
      return apiClient.patch(`/locations/${id}`, { location: changes });
    },
    getPhotoUploadUrl(id, data) {
      return apiClient.post(`/locations/${id}/photos`, data);
    },
  },
  events: {
    getAll(params) {
      return apiClient.get(`/events`, { params }).then(({ data }) => data);
    },
    get(id) {
      return apiClient.get(`/events/${id}`).then(({ data }) => data);
    },
    post(data) {
      return apiClient.post(`/events`, data);
    },
    edit(id, changes) {
      return apiClient.patch(`/events/${id}`, { event: changes });
    },
    import(eventUrl) {
      return apiClient.post(`/events/fbimport`, { eventUrl });
    },
  },
  reviews: {
    get(id) {
      return apiClient.get(`/locations/${id}/reviews`).then(({ data }) => data);
    },
    post(id, review) {
      return apiClient.post(`/locations/${id}/reviews`, { review });
    },
  },
  user: {
    get() {
      return apiClient.get(`/user`);
    },
    getPoints() {
      return apiClient.get(`/user/points`);
    },
    getLists(params) {
      return apiClient.get(`/user/lists`, { params }).then(({ data }) => data);
    },
    setName({ preferred_username }) {
      return apiClient.post(`/user/name`, { preferred_username });
    },
  },
  info: {
    get() {
      return apiClient.get(`/info`);
    },
  },
  favorites: {
    getAll() {
      return apiClient.get(`/user/favorites`).then(({ data }) => data);
    },
    add({ thingId }) {
      return apiClient.post(`/user/favorites`, {
        favorite: { thing_id: thingId },
      });
    },
    remove({ thingId }) {
      return apiClient.delete(`/user/favorites/${thingId}`);
    },
  },
  notifications: {
    get() {
      return apiClient.get(`/user/notifications`);
    },
  },
  lists: {
    getAll(params) {
      return apiClient.get(`/lists`, { params }).then(({ data }) => data);
    },
    get({ id }) {
      if (isNaN(id)) {
        return apiClient.get(`/lists?legacy_id=${id}`).then(({ data }) => data);
      } else {
        return apiClient.get(`/lists/${id}`).then(({ data }) => data);
      }
    },
    post(list) {
      return apiClient.post(`/lists/`, { list });
    },
    edit(id, changes) {
      return apiClient.patch(`/lists/${id}`, { list: changes });
    },
    delete(id) {
      return apiClient.delete(`/lists/${id}`);
    },
    getItems({ listId }) {
      return apiClient.get(`/lists/${listId}/items`).then(({ data }) => data);
    },
    addItem(listId, thingId) {
      return apiClient.post(`/lists/${listId}/items`, {
        list_item: { thing_id: thingId },
      });
    },
    updateItem({ listId, itemId, changes }) {
      return apiClient.patch(`/lists/${listId}/items/${itemId}`, {
        list_item: changes,
      });
    },
    removeItem({ listId, itemId }) {
      return apiClient.delete(`/lists/${listId}/items/${itemId}`);
    },
    getUploadUrl({ listId }) {
      return `${VUE_APP_BACKEND_URL}/lists/${listId}/image`;
    },
  },
  campaigns: {
    get({ id }) {
      return apiClient.get(`/campaigns/${id}`).then(({ data }) => data);
    },
    getEntry({ id }) {
      return apiClient.get(`/campaigns/${id}/entry`).then(({ data }) => data);
    },
    postEntry({ id }) {
      return apiClient.post(`/campaigns/${id}/entry`, {});
    },
  },
  cities: {
    getAll() {
      return apiClient.get(`/cities`).then(({ data }) => data);
    },
  },
  maptoken: {
    get() {
      return apiClient.get('/maptoken').then(({ data }) => data);
    },
  },
};

export default api;

import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

const {
  VUE_APP_CONTENTFUL_SPACE_ID,
  VUE_APP_CONTENTFUL_CDA_TOKEN,
} = process.env;

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: `https://graphql.contentful.com/content/v1/spaces/${VUE_APP_CONTENTFUL_SPACE_ID}`,
  headers: {
    Authorization: `Bearer ${VUE_APP_CONTENTFUL_CDA_TOKEN}`,
  },
});

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
});

export default apolloClient;

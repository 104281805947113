<template>
  <article class="card-banner">
    <div class="card-banner__body">
      <div class="card-banner__top">
        <span class="card-banner__label">{{ label }}</span>
      </div>
      <header class="card-banner__header">
        <h1 class="title is-4 card-banner__title">
          <strong>{{ title }}</strong>
        </h1>
      </header>
      <div class="card-banner__content">
        <p>{{ description }}</p>
      </div>
    </div>
    <footer class="card-banner__footer">
      <span class="icon"><i class="fa fa-heart-o"></i></span>
      <span>543</span>
    </footer>
  </article>
</template>

<script>
export default {
  props: {
    description: String,
    label: String,
    title: String,
  },
};
</script>

<style lang="scss">
@import '../scss/variables';

.card-banner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  color: white;
}

.card-banner__top {
  padding: 1rem;
}

.card-banner__label {
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.card-banner__body {
}

.card-banner__header {
  padding: 0 1rem;
}

.card-banner__title {
  color: white;
}

.card-banner__content {
  padding: 1rem;
}

.card-banner__footer {
  padding: 0.5rem 1rem;
  border-top: 1px solid rgba(200, 200, 200, 0.75);
}
</style>

<template>
  <div class="hero bg-white rounded shadow mb-6">
    <div class="hero-body container">
      <div class="columns">
        <div class="column is-half">
          <h2 class="title is-4 pb-4">Hei ja tervetuloa Sandikseen!</h2>
          <p>
            Sandis on kolmen vanhemman iltapuurojen ja päiväkotireissujen
            välissä tekemä palvelu. Tavoitteenamme on inspiroida ja helpottaa
            arkea ja yhdistää lähialueen vanhemmat uudella tavalla. Huomasimme,
            että kaikkein parasta lääkettä arjen rutiineihin on se hiljainen
            tieto, joka kerrotaan leikkipuistojen hiekkalaatikoiden reunoilla
            tai kahvipöytäkeskusteluissa. Sandis on tehty tätä tietoa varten.
            Sinä pystyt auttamaan muita vanhempia jakamalla juuri sinun
            lapsiystävälliset vinkit ja kokemukset. Joten tervetuloa rakentamaan
            sujuvampaa lapsiperharkea!
          </p>
          <br />
          <p>Toivottavasti tämä helpottaa myös sinunkin elämääsi.</p>
          <br />
          <p>Terveisin,</p>
          <p>Lasse, Melina ja Joonas</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutGreeting',
};
</script>

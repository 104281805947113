const things = (api) => ({
  get(params = {}) {
    return api.get('/things', { params });
  },
  getById(id, params = {}) {
    return api.get(`/things/${id}`, { params });
  },
  delete(id) {
    return api.delete(`/things/${id}`);
  },
});

export default things;

<template>
  <div id="app" class="ds-bg-lightgrey">
    <MainHeader />
    <Notifications />
    <MainNav />
    <Search v-if="$store.state.search.visible" />
    <Login v-if="$store.state.loginVisible"></Login>
    <Modal
      v-if="$store.state.feedbackVisible"
      :onCloseClick="
        () => {
          $store.dispatch('toggleFeedback');
        }
      "
    >
      <Feedback></Feedback>
    </Modal>
    <router-view class="app-content"></router-view>
    <MainFooter></MainFooter>
  </div>
</template>

<script>
import Feedback from '@/components/Feedback';
import Login from '@/components/Login';
import MainHeader from '@/components/MainHeader';
import MainFooter from '@/components/Footer';
import MainNav from '@/components/MainNav';
import Modal from '@/components/Modal';
import Notifications from '@/components/Notifications';
import Search from '@/components/Search';
import { setMeta } from '@/utils/utils';
import api from '@/utils/api';
import '@/scss/app.scss';

/* eslint-disable */
const initFacebookSDK = () => {
  window.fbAsyncInit = function() {
    FB.init({
      appId: '191980951206712',
      cookie: true, // enable cookies to allow the server to access the session
      xfbml: true, // parse social plugins on this page
      version: 'v2.8' // use graph api version 2.8
    })
  }
  ;(function(d, s, id) {
    let js,
      fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) return
    js = d.createElement(s)
    js.id = id
    js.src = '//connect.facebook.net/en_US/sdk.js'
    fjs.parentNode.insertBefore(js, fjs)
  })(document, 'script', 'facebook-jssdk')
}
/* eslint-enable */

// Check whether storage of type is supported by client browser
const storageAvailable = (type) => {
  let storage;

  try {
    storage = window[type];
    let x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
};

const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

const checkTokenExpiration = (token) => {
  try {
    const { exp } = parseJwt(token);
    return Date.now() >= exp * 1000;
  } catch (error) {
    console.log('Error checking mapkit jwt', error);
    return true;
  }
};

const initMapkit = () => {
  // Init MapKit
  window.mapkit.init({
    authorizationCallback: async (done) => {
      const localStorageAvailable = storageAvailable('localStorage');
      const token = localStorageAvailable
        ? localStorage.getItem('mapToken')
        : null;

      const tokenExpired = token ? checkTokenExpiration(token) : true;

      if (token && !tokenExpired) {
        done(token);
      } else {
        const newToken = await api.maptoken.get();
        if (localStorageAvailable) {
          localStorage.setItem('mapToken', newToken);
        }

        done(newToken);
      }
    },
    language: 'fi',
  });
};

export default {
  name: 'App',
  components: {
    Feedback,
    Login,
    MainFooter,
    MainHeader,
    MainNav,
    Modal,
    Notifications,
    Search,
  },
  created() {
    initFacebookSDK();
    initMapkit();

    // Minimalist feature switch sypport
    const features = this.$route.query.features
      ? this.$route.query.features.split(',')
      : [];

    if (features.length > 0)
      this.$store.dispatch('updateFeatures', { features });

    // Check if user is logged in
    this.$store.dispatch('user/checkLogin');
  },
  metaInfo: {
    title: 'Sandis',
    titleTemplate: '%s | Sandis - tekemistä, hyötyä ja hupia lapsiperheille',
    meta: [
      ...setMeta({
        title: 'Sandis - tekemistä hyötyä ja hupia lapsiperheille',
        description:
          'Sandiksesta löydät yhteisön lisäämät ja arvostelemat lähialueen seikkailut lapsille',
        url: '',
        ogImage: 'https://www.sandis.co/static/og-image.jpg',
        ogImageWidth: '1200',
        ogImageHeight: '630',
      }),
      { property: 'og:site_name', content: 'Sandis' },
      { property: 'fb:app_id', content: '191980951206712' },
    ],
  },
};
</script>

<style src="pikaday/css/pikaday.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="dropzone/dist/dropzone.css"></style>
<style lang="scss">
@import 'scss/variables';

.app-content {
  min-height: 100%;
}

svg {
  fill: currentColor;
}

.small {
  font-size: 0.8rem;
  color: #adb5bd;
}

.is-uppercase {
  text-transform: uppercase;
}

.centered-container {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
}

.container--narrow {
  max-width: 40rem;
}

.background-shapes {
  background-image: url('/static/background-shapes.svg');
  background-size: 100% auto;

  @media (max-width: $bp-mobile) {
    background-image: url('/static/background-shapes-mobile.svg');
    background-repeat: no-repeat;
  }
}

.has-overlay {
  position: relative;

  & > * {
    position: relative;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(27, 27, 27, 0.6);
  }

  &.has-overlay--lighter {
    &:before {
      background-color: rgba(27, 27, 27, 0.4);
    }
  }

  &.has-overlay--darker {
    &:before {
      background-color: rgba(27, 27, 27, 0.7);
    }
  }
}

@each $category, $color in $categories {
  .background-#{$category} {
    background-color: $color;
  }
}

/* Reset fieldset and legend */
legend {
  display: table;
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;

  + * {
    clear: both;
  }
}

fieldset {
  border: 0;
  padding: 0.01em 0 0 0;
  margin: 0;
  min-width: 0;
}

body:not(:-moz-handler-blocked) fieldset {
  display: table-cell;
}
</style>

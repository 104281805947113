<script>
import auth from '@/utils/auth';

const getHashValue = (hash, key) => {
  const matches = location.hash.match(new RegExp(key + '=([^&]*)'));
  return matches ? matches[1] : null;
};

export default {
  data() {
    return {};
  },
  created() {
    const hash = this.$route.hash;

    const tokenData = {
      AccessToken: getHashValue(hash, 'access_token'),
      ExpiresIn: getHashValue(hash, 'expires_in'),
      IdToken: getHashValue(hash, 'id_token'),
      TokenType: getHashValue(hash, 'token_type'),
    };

    const state = decodeURIComponent(getHashValue(hash, 'state'));

    auth
      .fbLogin(tokenData)
      .then(() => {
        return Promise.all([this.$store.dispatch('user/fetchUser')]);
      })
      .then(this.$router.push(state || '/'))
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>

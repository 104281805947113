import Vue from 'vue';
import Meta from 'vue-meta';
import auth from '../utils/auth';
import Router from 'vue-router';
// Views
import About from '@/views/About';
import Admin from '@/views/Admin';
import Article from '@/views/Article';
import Browse from '@/views/Browse';
import Campaign from '@/views/Campaign';
import Collaboration from '@/views/Collaboration';
import CreateEvent from '@/views/CreateEvent';
import CreateList from '@/views/CreateList';
import CreateLocation from '@/views/CreateLocation';
import Community from '@/views/Community';
import Details from '@/views/Details';
import EditName from '@/views/EditName';
import FBAuth from '@/views/FBAuth';
import Favorites from '@/views/Favorites';
import ForgotPassword from '@/views/ForgotPassword';
import Front from '@/views/Front/Front';
import ListDetails from '@/views/ListDetails';
import Lists from '@/views/Lists';
import NotFound from '@/views/404';
import Register from '@/views/Register';
import ResetPassword from '@/views/ResetPassword';
import Styleguide from '@/views/Styleguide';
import PatternLibrary from '@/views/PatternLibrary';
import Terms from '@/views/Terms';
import UserLists from '@/views/UserLists';
import Verify from '@/views/Verify';

const authorize = (to, from, next) => {
  auth
    .getSession()
    .then(() => next())
    .catch(() => next('/'));
};

Vue.use(Router);
Vue.use(Meta);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      component: Front,
      name: 'front',
      path: '/',
    },
    {
      component: About,
      name: 'about',
      path: '/about',
    },
    {
      component: Browse,
      name: 'browse',
      path: '/browse/:type?/:category?',
    },
    {
      beforeEnter: authorize,
      component: CreateLocation,
      name: 'createLocation',
      path: '/create/location',
    },
    {
      beforeEnter: authorize,
      component: CreateEvent,
      name: 'createEvent',
      path: '/create/event',
    },
    {
      beforeEnter: authorize,
      component: CreateList,
      name: 'createList',
      path: '/create/list',
    },
    {
      component: Community,
      name: 'community',
      path: '/community',
    },
    {
      component: Details,
      name: 'thingDetails',
      // matches /event and /location
      /* eslint-disable no-useless-escape */
      path: '/:type(event|location)/:id/:slug?',
    },
    {
      component: ListDetails,
      name: 'listDetails',
      path: '/list/:id/:slug?',
    },
    {
      component: Register,
      name: 'register',
      path: '/register',
    },
    {
      component: ForgotPassword,
      name: 'forgotPassword',
      path: '/forgotpassword',
    },
    {
      component: ResetPassword,
      name: 'resetPassword',
      path: '/resetpassword',
    },
    {
      path: '/favorites',
      component: Favorites,
      name: 'favorites',
    },
    {
      path: '/lists',
      component: Lists,
      name: 'lists',
    },
    {
      path: '/profile/lists',
      component: UserLists,
      name: 'userLists',
    },
    {
      path: '/admin',
      component: Admin,
      name: 'admin',
    },
    {
      path: '/fbauth',
      component: FBAuth,
      name: 'fbauth',
    },
    {
      path: '/profile/name',
      component: EditName,
      name: 'editname',
      beforeEnter: authorize,
    },
    {
      path: '/campaign/:id/:slug?',
      component: Campaign,
      name: 'campaign',
    },
    {
      path: '/terms',
      component: Terms,
      name: 'terms',
    },
    {
      path: '/verify',
      component: Verify,
      name: 'verify',
    },
    {
      path: '*',
      component: NotFound,
      name: '404',
    },
    {
      path: '/styleguide',
      component: Styleguide,
      name: 'styleguide',
    },
    {
      path: '/patternlibrary',
      component: PatternLibrary,
      name: 'patternlibrary',
    },
    {
      path: '/collaboration',
      component: Collaboration,
      name: 'collaboration',
    },
    {
      path: '/articles/:slug',
      component: Article,
      name: 'article',
    },
  ],
});

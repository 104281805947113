<template>
  <div class="text-center">
    <p class="font-bold text-lg mb-6">{{ title }}</p>
    <div class="flex justify-center">
      <button :class="['button', `is-light`]" @click="onCancelClick">
        Peruuta
      </button>
      <button
        :class="['button button--teal ml-4', { 'is-loading': isLoading }]"
        @click="onConfirmClick"
      >
        {{ confirmText }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Confirmation',
  props: {
    confirmColor: { type: String, default: 'primary' },
    confirmText: String,
    isLoading: Boolean,
    onConfirmClick: Function,
    onCancelClick: Function,
    title: String,
  },
};
</script>

<style></style>

<template>
  <div>
    <header class="hero is-white">
      <div class="hero-body has-text-centered">
        <h1 class="title is-size-1 is-size-3-mobile is-spaced">
          <strong>Luo uusi lista</strong>
        </h1>
        <p class="subtitle is-size-6 container container--narrow">
          Tee oma listasi antamalla sille nimi ja kuvaus. Listan luotuasi pääset
          vaihtamaan listan ulkoasua ja tietoja Muokkaa -napista. Paikat ja
          tapahtumat pääset lisäämään
          <a href="/browse/" class="has-text-primary">kartalta</a>
          avaamalla paikan tarkemman kuvauksen.
        </p>
      </div>
    </header>
    <div class="create-list__content">
      <div class="field">
        <label for="" class="label">Nimi (pakollinen)</label>
        <div class="control">
          <input v-model="name" type="text" class="input is-large" />
        </div>
        <p class="help">
          Esimerkiksi 'Helsingin parhaat brunssit' tai 'Kesän siisteimmät
          tapahtumat'
        </p>
      </div>
      <div class="field has-text-centered">
        <button
          :disabled="name.length < 2"
          @click="onSaveClick"
          :class="['button is-primary is-medium', { 'is-loading': isLoading }]"
        >
          Tallenna
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../utils/api';

export default {
  name: 'CreateList',
  data() {
    return {
      name: '',
      isLoading: false,
    };
  },
  methods: {
    onSaveClick() {
      const vm = this;
      vm.isLoading = true;

      api.lists
        .post({ name: this.name })
        .then(({ headers }) => {
          const id = headers.location.substr(
            headers.location.lastIndexOf('/') + 1
          );
          vm.isLoading = false;
          vm.$router.push(`/list/${id}`);
        })
        .catch(() => {
          vm.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.create-list__content {
  max-width: 30rem;
  margin: 0 auto;
}
</style>

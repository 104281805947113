<template>
  <article
    class="flex flex-col flex-1 justify-between rounded shadow w-full h-full bg-white"
  >
    <component
      :is="wrapperElement"
      v-bind="wrapperAttributes"
      :style="{
        cursor: !!url ? 'pointer' : 'auto',
      }"
      class="text-black"
    >
      <div
        v-if="hasImage"
        class="h-48 relative bg-cover bg-center rounded-t"
        :style="{
          backgroundImage: `url(${image})`,
          backgroundColor: imageBackgroundColor,
        }"
      >
        <div class="h-full flex flex-col justify-between p-2">
          <div class="card__image-overlay absolute pin z-0"></div>
          <div class="flex justify-between relative z-10" @click="onClick">
            <p class="tracking-wide text-sm font-bold text-white uppercase">
              {{ label }}
            </p>
            <Rating
              v-if="!isNaN(rating)"
              size="small"
              :rating="rating"
            ></Rating>
          </div>
          <div>
            <header
              v-if="title"
              class="m-card__title-container z-10 relative ml-1"
            >
              <p v-if="subtitle" class="text-white font-bold text-xs">
                {{ subtitle }}
              </p>
              <h1 class="m-card__title text-white font-bold text-lg">
                {{ title }}
              </h1>
            </header>
          </div>
        </div>
      </div>
      <div class="p-4">
        <p class="card__body" v-if="body">{{ body }}</p>
        <slot name="body-after"></slot>
      </div>
    </component>
    <div v-if="$slots.footer" class="m-card__footer">
      <slot name="footer"></slot>
    </div>
  </article>
</template>

<script>
import Rating from '../components/Rating.vue';

export default {
  components: { Rating },
  props: {
    backgroundColor: { type: String, default: 'white' },
    body: String,
    image: { type: String, default: '' },
    imageBackgroundColor: { type: String, default: 'transparent' },
    label: String,
    rating: Number,
    subtitle: String,
    title: String,
    url: String,
    urlIsExternal: Boolean,
  },
  computed: {
    hasImage() {
      return this.image && this.image.length > 0;
    },
    wrapperElement() {
      if (this.url) {
        if (this.urlIsExternal) {
          return 'a';
        }

        return 'router-link';
      }

      return 'div';
    },
    wrapperAttributes() {
      if (this.url) {
        if (this.urlIsExternal) {
          return {
            href: this.url,
            target: '_blank',
          };
        }

        return {
          to: this.url,
        };
      }

      return {};
    },
  },
  methods: {
    onClick() {
      if (!this.url) return;
      this.urlIsExternal
        ? window.open(this.url, '_blank')
        : this.$router.push(this.url);
    },
  },
};
</script>

<style lang="scss" scoped>
.card__image-overlay {
  background: linear-gradient(
    0deg,
    rgba(46, 51, 86, 0.59) 0%,
    rgba(196, 196, 196, 0) 96.07%
  );
}

.card__body {
  white-space: pre-line;
}
</style>

<template>
  <article>
    <!-- TODO: temp fix for prerendering bug -->
    <img
      :src="data.image.fbpreview"
      style="width: 600px; height: 315px; display: none"
    />
    <header
      class="list-details__header flex flex-col"
      :style="{
        backgroundColor: colors[data.color || 0],
        color: hasImage ? 'white !important' : 'inherit',
      }"
    >
      <div
        :class="['list-details__header-bg z-1', backgroundClass]"
        :style="{ backgroundImage }"
      />
      <div class="container flex w-full justify-end relative p-2 z-10">
        <button
          v-if="isOwn"
          role="button"
          class="button button--small"
          @click="onTogglePublicClick"
        >
          {{ data.is_public ? 'Muuta yksityiseksi' : 'Muuta julkiseksi' }}
        </button>
        <button
          v-if="isOwn"
          role="button"
          class="button button--small ml-2"
          @click="isEditModalOpen = true"
        >
          Muokkaa listaa
        </button>
        <button
          v-if="isOwn"
          role="button"
          class="button button--small ml-2"
          @click="isUploadModalOpen = true"
        >
          Lisää kuva
        </button>
        <div v-if="!isOwn && data.created_by.id" class="level is-mobile">
          <div class="level-left"></div>
          <div class="level-right">
            <div class="level-item">Listan laatinut</div>
            <div class="level-item">
              <strong>{{ data.created_by.name }}</strong>
            </div>
            <div class="level-item">
              <Avatar :userId="data.created_by.id"></Avatar>
            </div>
          </div>
        </div>
      </div>
      <div class="container flex flex-grow relative z-10">
        <div
          class="flex flex-col flex-grow justify-end w-full max-w-md p-4 pb-6"
        >
          <h1
            :class="[
              'text-4xl md:text-5xl font-bold leading-none mb-4',
              hasImage ? 'text-white' : '',
            ]"
          >
            {{ data.name }}
          </h1>
        </div>
      </div>
    </header>
    <div class="container p-5">
      <p
        v-if="data.description"
        :class="[
          'subtitle list-details__description',
          hasImage ? 'has-text-white' : '',
        ]"
        v-html="linkifiedDescription"
      ></p>
      <button
        v-else
        @click="isEditModalOpen = true"
        class="button is-black is-outlined"
      >
        Lisää listalle kuvaus
      </button>
    </div>
    <div class="container list-details__content">
      <div
        v-if="items.length > 0"
        :class="[
          'flex flex-wrap',
          { 'justify-center': items.length < 4 || hasComments },
        ]"
      >
        <div
          v-for="item in itemsWithPromotions"
          :key="!!item.thing ? item.thing.id : item.title.rendered"
          :class="[
            'list-item',
            hasComments
              ? 'list-item--has-comment w-full w-4/5 lg:w-2/3 p-4 z-10'
              : 'w-full md:w-1/3 p-4',
          ]"
        >
          <Card
            v-if="!!item.thing"
            :class="[
              'list-item__card',
              { 'list-item__card--disabled': isCardDisabled(item) },
            ]"
            :title="item.thing.name"
            :subtitle="
              item.thing.type === 'event'
                ? parseDate(item.thing.dates[0].start_date)
                : ''
            "
            :hasImage="true"
            :image="getImage(item.thing)"
            :imageBackgroundColor="
              categoryColors[
                item.thing.type === 'event' ? 'event' : item.thing.category
              ]
            "
            :body="item.thing.short_description"
            :url="`/${item.thing.type}/${item.thing.id}/${item.thing.slug}`"
          >
            <template slot="footer">
              <template v-if="!isOwn">
                <div class="w-full">
                  <ButtonFavorite
                    :id="item.thing.id"
                    :name="item.thing.name"
                    :type="item.thing.type"
                    class="w-full"
                  ></ButtonFavorite>
                </div>
              </template>
              <template v-if="isOwn">
                <div class="w-full">
                  <span
                    @click="onCommentItemClick(item)"
                    role="button"
                    class="button w-full"
                  >
                    <span class="icon is-small">
                      <i class="fa fa-comment-o"></i>
                    </span>
                  </span>
                </div>
                <div class="w-full">
                  <span
                    @click="onRemoveItemClick(item.thing.id)"
                    role="button"
                    class="button w-full"
                  >
                    <span class="icon is-small">
                      <i class="fa fa-trash"></i>
                    </span>
                  </span>
                </div>
              </template>
            </template>
          </Card>
          <Card
            v-if="!item.thing"
            header="Kaupallinen yhteistyö"
            :title="item.title.rendered"
            :subtitle="`Yhteistyössä ${item.acf.partner}`"
            :hasImage="true"
            :image="item.acf.image.sizes.medium"
            :body="item.acf.description"
            :url="item.acf.click_tag"
            urlIsExternal
            @click.native="() => sendClickEvent(item.title.rendered)"
          >
            <template slot="body-after">
              <img
                :src="getRandomTag(item.acf.image_tag)"
                border="0"
                height="1"
                width="1"
              />
              <img :src="item.acf.pixel_url" border="0" height="1" width="1" />
            </template>
          </Card>
          <div v-if="item.description" class="list-item__comment">
            {{ item.description }}
          </div>
        </div>
      </div>
      <div v-if="items.length < 1" class="has-text-centered">
        <br />
        <p class="title is-3">:(</p>
        <p class="title is-5">Täällä ei ole vielä mitään.</p>
      </div>
    </div>
    <div class="list-details__map mb-4">
      <AppleMap
        fitAllItems
        v-if="items.length > 0"
        :items="items.map((i) => i.thing)"
        :center="
          items.length > 0
            ? {
                lat: items[0].thing.location.lat,
                lng: items[0].thing.location.lng,
              }
            : null
        "
      />
    </div>
    <Modal
      v-if="isEditModalOpen"
      :onCloseClick="
        () => {
          isEditModalOpen = false;
        }
      "
    >
      <EditList
        :currentColor="data.color"
        :currentName="data.name"
        :currentDescription="data.description"
        :currentPattern="data.pattern"
        :id="data.id"
        :onCancelClick="
          () => {
            isEditModalOpen = false;
          }
        "
        :onSuccess="onEditSuccess"
      />
    </Modal>
    <Modal
      v-if="isCommentItemModalOpen"
      :onCloseClick="
        () => {
          isCommentItemModalOpen = false;
        }
      "
    >
      <CommentListItem
        :itemDescription="itemToComment.description"
        :itemId="itemToComment.thing.id"
        :itemName="itemToComment.thing.name"
        :listId="this.data.id"
        :onSuccess="onCommentItemSuccess"
      />
    </Modal>
    <Modal
      v-if="isRemoveItemModalOpen"
      :onCloseClick="
        () => {
          isRemoveItemModalOpen = false;
        }
      "
    >
      <div class="bg-white rounded p-4">
        <Confirmation
          confirmColor="danger"
          title="Poista listalta?"
          :isLoading="isRemoveLoading"
          :onCancelClick="
            () => {
              isRemoveItemModalOpen = false;
            }
          "
          :onConfirmClick="onConfirmRemoveClick"
          confirmText="Poista"
        />
      </div>
    </Modal>
    <Modal
      v-if="isUploadModalOpen"
      :onCloseClick="
        () => {
          isUploadModalOpen = false;
        }
      "
    >
      <div class="bg-white rounded p-4">
        <Upload
          :onSuccess="() => onUploadSuccess()"
          :endpoint="`/lists/${data.id}/image`"
        />
      </div>
    </Modal>
    <aside class="section container"><AboutGreeting /></aside>
    <aside class="section container"><AboutFeatures /></aside>
  </article>
</template>

<script>
import Vue from 'vue';
import AboutGreeting from '@/components/AboutGreeting';
import AboutFeatures from '@/components/AboutFeatures';
import Avatar from '@/components/Avatar';
import ButtonFavorite from '@/components/ButtonFavorite';
import Card from '@/components/Card';
import Confirmation from '@/components/Confirmation';
import CommentListItem from '@/components/CommentListItem';
import EditList from '@/components/EditList';
import Modal from '@/components/Modal';
import Upload from '@/components/Upload';
import AppleMap from '@/components/Map';
import api from '@/utils/api';
import cms from '@/utils/cms';
import {
  categoryColors,
  categoryPatterns,
  colors,
  getMarkerIcon,
  patterns,
  setMeta,
  loadExternalScripts,
} from '../utils/utils.js';
import { format, isPast } from 'date-fns';
import { any } from 'ramda';
import sanitizeHtml from 'sanitize-html';
import linkifyStr from 'linkifyjs/string';

const LISTS_WITH_NO_PROMOTIONS = [
  236,
];

function insertItemAtPosition(array, item, position) {
  return [...array.slice(0, position), item, ...array.slice(position)];
}

/**
 * Randomly shuffle an array
 * https://stackoverflow.com/a/2450976/1293256
 * @param  {Array} array The array to shuffle
 * @return {String}      The first item in the shuffled array
 */
function shuffle(array) {
  let currentIndex = array.length;
  let temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export default {
  name: 'ListDetails',
  components: {
    AboutGreeting,
    AboutFeatures,
    Avatar,
    ButtonFavorite,
    Card,
    Confirmation,
    CommentListItem,
    EditList,
    AppleMap,
    Modal,
    Upload,
  },
  data() {
    return {
      categoryColors,
      categoryPatterns,
      colors,
      data: {
        created_by: {
          name: undefined,
          id: undefined,
        },
        color: undefined,
        description: undefined,
        id: undefined,
        is_public: undefined,
        image: {
          fbpreview: undefined,
          large: undefined,
          thumb: undefined,
        },
        name: undefined,
        pattern: undefined,
      },
      infoWindow: {
        description: '',
        open: false,
        options: { maxWidth: 310 },
        position: { lat: 0, lng: 0 },
        target: '',
        title: '',
      },
      isCommentItemModalOpen: false,
      isEditModalOpen: false,
      isUploadModalOpen: false,
      isRemoveItemModalOpen: false,
      isRemoveLoading: false,
      items: [],
      itemToComment: undefined,
      itemToRemove: undefined,
      promotions: [],
    };
  },
  computed: {
    backgroundClass() {
      return this.hasImage
        ? 'list-details__header-bg--image'
        : 'list-details__header-bg--pattern';
    },
    backgroundImage() {
      return this.hasImage
        ? `url(${this.data.image.large})`
        : `url(/static/patterns/${patterns[this.data.pattern]})`;
    },
    hasComments() {
      return any((item) => item.description && item.description.length > 0)(
        this.items
      );
    },
    hasImage() {
      return !!this.data.image.large;
    },
    itemsWithPromotions() {
      const PROMOTION_EVERY_NTH = 5;
      const { items, promotions } = this;
      if (promotions.length > 0) {
        const shuffledPromotions = shuffle(promotions);
        let itemsWithPromotions = items;

        shuffledPromotions.forEach((promotion, i) => {
          const pos = (i + 1) * PROMOTION_EVERY_NTH;

          if (items.length >= pos) {
            itemsWithPromotions = insertItemAtPosition(
              itemsWithPromotions,
              promotion,
              pos
            );
          }
        });

        return itemsWithPromotions;
      } else {
        return items;
      }
    },
    isOwn() {
      return this.data.created_by.id === this.$store.state.user.id;
    },
    linkifiedDescription() {
      const sanitized = sanitizeHtml(this.data.description, {
        allowedTags: [],
        allowedAttributes: [],
      });

      return linkifyStr(sanitized);
    },
  },
  mounted() {
    const scripts = [
      "https://wonderus.bbvms.com/a/sandis_outstream.js",
    ];

    loadExternalScripts(scripts);
  },
  created() {
    this.fetchList();
  },
  beforeRouteEnter(to, from, next) {
    window.scrollTo(0, 0);
    next();
  },
  onRouteChange() {
    this.fetchList();
  },
  metaInfo() {
    return {
      title: this.data.name,
      meta: [
        ...setMeta({
          title: this.data.name,
          description: this.data.description
            ? this.data.description.substring(0, 159)
            : '',
          url: `list/${this.data.id}/${this.data.slug}`,
          ogImage: this.data.image.fbpreview,
          ogImageWidth: '600',
          ogImageHeight: '315',
        }),
      ],
    };
  },
  methods: {
    getMarkerIcon,
    getImage(item) {
      if (item.images.length > 0) {
        return item.images[0].sizes.small;
      } else {
        return `/static/patterns/${
          categoryPatterns[item.type === 'event' ? 'event' : item.category]
        }`;
      }
    },
    getRandomTag(url) {
      return url.replace('$RANDOM', Math.floor(Math.random() * 10000000));
    },
    fetchList() {
      api.lists
        .get({ id: this.$route.params.id })
        .then(({ data }) => {
          const vm = this;
          vm.data = data;

          if (isNaN(this.$route.params.id)) {
            return this.$router.replace(`/list/${data.id}/${data.slug}`);
          }

          if (!this.$route.params.slug || this.$route.params.slug.length < 0) {
            const params = { ...vm.$route.params, slug: data.slug };
            vm.$router.replace({ params });
          }

          if (vm.data.created_by.id === 8 && !LISTS_WITH_NO_PROMOTIONS.includes(vm.data.id)) vm.fetchPromotions();

          api.lists.getItems({ listId: data.id }).then((results) => {
            if (results.data && results.data.length > 0) {
              const sortedItems = results.data.reduce((acc, item) => {
                return item.thing.type === 'event' &&
                  isPast(item.thing.dates[0].end_date)
                  ? [...acc, item]
                  : [item, ...acc];
              }, []);

              vm.items = sortedItems;
            }
          });
        })
        .catch((error) => console.log(error));
    },
    fetchPromotions() {
      cms.getPromotions().then((data) => {
        this.promotions = data;
        data.forEach((promotion) => {
          this.$ga.event('Ads', 'view', promotion.title.rendered);
        });
      });
    },
    isCardDisabled(item) {
      return (
        item.thing.type === 'event' && isPast(item.thing.dates[0].end_date)
      );
    },
    onRemoveItemClick(itemId) {
      this.itemToRemove = itemId;
      this.isRemoveItemModalOpen = true;
    },
    onCommentItemClick(item) {
      this.itemToComment = item;
      this.isCommentItemModalOpen = true;
    },
    onCommentItemSuccess() {
      this.isCommentItemModalOpen = false;
      this.fetchList();
    },
    onConfirmRemoveClick() {
      const vm = this;
      const itemId = this.itemToRemove;
      const index = this.items.findIndex((item) => item.id === itemId);

      vm.isRemoveLoading = true;
      api.lists
        .removeItem({ listId: this.data.id, itemId })
        .then(() => {
          vm.isRemoveLoading = false;
          vm.isRemoveItemModalOpen = false;
          vm.items.splice(index, 1);
        })
        .catch((error) => {
          vm.isRemoveLoading = false;
          console.log('error', error);
        });
    },
    onEditSuccess() {
      this.isEditModalOpen = false;
      this.fetchList();
    },
    onTogglePublicClick: async function () {
      try {
        await api.lists.edit(this.data.id, { is_public: !this.data.is_public });
        this.data = {
          ...this.data,
          is_public: !this.data.is_public,
        };
      } catch (error) {
        console.log('Error updating publicity', error);
      }
    },
    parseDate(isoDate) {
      return format(isoDate, 'D.M.YYYY');
    },
    onUploadSuccess() {
      this.fetchList();
      this.isUploadModalOpen = false;
    },
    sendClickEvent(title) {
      this.$ga.event('Ads', 'click', title);
    },
  },
};
</script>

<style lang="scss">
@import '../scss/variables';

.list-details__header {
  position: relative;
  min-height: 25rem;
}

.list-details__header-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(27, 27, 27, 0.6);
    background: linear-gradient(
      0deg,
      rgba(46, 51, 86, 0.59) 0%,
      rgba(196, 196, 196, 0) 96.07%
    );
  }
}

.list-details__header-bg--image {
  background-position: center;
  background-size: cover;
}

.list-details__header-bg--pattern {
  opacity: 0.1;
}

.list-details__description {
  white-space: pre-line;

  a {
    @apply font-bold
      text-teal;
  }
}

.list-item {
  display: flex;
}

.list-item--has-comment {
  padding: 1rem;
  background-color: white;

  @media (max-width: $bp-mobile) {
    flex-direction: column;
  }
}

.list-item--has-comment .list-item__card {
  @apply flex-grow flex-no-shrink w-full;

  @screen md {
    @apply flex-no-grow;

    flex-basis: 20rem;
  }
}

.list-item__card--disabled {
  position: relative;
  opacity: 0.4;
  filter: grayscale(100%);
}

.list-item__comment {
  position: relative;
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;
  padding: 3rem 3rem 3rem 4rem;
  font-size: 1.15rem;

  @media (max-width: $bp-mobile) {
    padding: 1rem 3rem;
  }

  &:before,
  &:after {
    position: absolute;
    font-size: 4rem;

    @media (max-width: $bp-mobile) {
      font-size: 3rem;
    }
  }

  &:before {
    content: '“';
    top: 0rem;
    left: 1.5rem;

    @media (max-width: $bp-mobile) {
      left: 0.5rem;
    }
  }

  &:after {
    content: '”';
    right: 1rem;
  }
}

.list-details__map {
  position: relative;
  height: 25rem;
  width: 100%;
  margin-top: 5rem;
}
</style>

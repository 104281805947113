import axios from 'axios';
const CMS_URL = 'https://cms.sandis.co';

export default {
  getPage({ id }) {
    return axios.get(`${CMS_URL}/wp-json/acf/v3/pages/${id}`);
  },
  getCampaign({ id }) {
    return axios
      .get(`${CMS_URL}/wp-json/wp/v2/campaigns?api_id=${id}`)
      .then(({ data }) => data);
  },
  getPromotion({ slug }) {
    return axios
      .get(`${CMS_URL}/wp-json/wp/v2/promotions?slug=${slug}`)
      .then(({ data }) => data);
  },
  getPromotions() {
    return axios
      .get(`${CMS_URL}/wp-json/wp/v2/promotions`)
      .then(({ data }) => data);
  },
};

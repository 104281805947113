const locales = {
  fi: {
    categories: {
      activity: 'Tekemistä',
      all: 'Näytä kaikki',
      culture: 'Kulttuuri',
      event: 'Tapahtumat',
      events: 'Tapahtumat',
      other: 'Muu',
      restaurant: 'Ravintolat',
      service: 'Liikkeet ja palvelut',
      sports: 'Liikunta',
    },
    subcategories: {
      activity: {
        playground: 'Leikkipuistot',
      },
      events: {
        culture: 'Kulttuuri',
        sports: 'Liikunta',
      },
      restaurant: {},
      service: {
        fashion: 'Muoti',
        flea_market: 'Kirpputorit',
        health: 'Terveys',
        toys: 'Lelut',
      },
    },
    tags: {
      brunch: 'brunssi',
      child_care_room: 'lastenhoitohuone',
      free: 'ilmainen',
      kids_menu: 'lasten ruokalista',
      playroom: 'leikkinurkka',
      room_for_stroller: 'tilaa lastenvaunuille',
    },
    ages: {
      baby: 'vauvoille',
      toddler: 'taaperoille',
      preschooler: 'leikki-ikäisille',
      gradeschooler: 'kouluikäisille',
    },
    pikaday: {
      previousMonth: 'Edellinen Kuukausi',
      nextMonth: 'Seuraava Kuukausi',
      months: [
        'Tammikuu',
        'Helmikuu',
        'Maaliskuu',
        'Huhtikuu',
        'Toukokuu',
        'Kesäkuu',
        'Heinäkuu',
        'Elokuu',
        'Syyskuu',
        'Lokakuu',
        'Marraskuu',
        'Joulukuu',
      ],
      weekdays: [
        'Sunnuntai',
        'Maanantai',
        'Tiistai',
        'Keskiviikko',
        'Torstai',
        'Perjantai',
        'Lauantai',
      ],
      weekdaysShort: ['Su', 'Ma', 'Ti', 'Ke', 'To', 'Pe', 'La'],
    },
    main: {
      mobileViewToggle: {
        map: 'Näytä kartalla',
        list: 'Näytä listana',
      },
      filteredCategories: {
        all: 'Paikat',
        restaurant: 'Ravintolat',
        activity: 'Tekemistä',
        service: 'Liikkeet ja palvelut',
        event: 'Tapahtumat',
      },
    },
    details: {
      name: 'nimi',
      category: 'kategoria',
      shortDescription: 'lyhyt kuvaus',
      description: 'lisätietoja',
      tags: 'ominaisuudet',
      location: 'sijainti',
    },
    notifications: {
      EDIT_APPROVED: 'Muokkauksesi hyväksyttiin',
      points: {
        CAMPAIGN_ENTER: 'Osallistuit kilpailuun',
        LOCATION_CREATE: 'Lisäsit paikan',
        LOCATION_EDIT: 'Muokkasit paikkaa',
        REVIEW_CREATE: 'Kirjoitit arvostelun',
      },
    },
  },
};

export default locales;

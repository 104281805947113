<template>
  <section v-if="promotion" class="block-promotion w-full h-full">
    <Card
      :backgroundColor="cmsData.bgcolor"
      :hasShadow="false"
      :label="`yhteistyössä ${promotion.acf.partner}`"
      :image="promotion.acf.image.sizes.medium"
      :title="promotion.title.rendered"
      :body="promotion.acf.description"
      :url="promotion.acf.click_tag"
      urlIsExternal
    />
    <img :src="randomTag" border="0" height="1" width="1" />
    <img :src="promotion.acf.pixel_url" border="0" height="1" width="1" />
  </section>
</template>

<script>
import Card from '@/components/Card';
import cms from '@/utils/cms';

export default {
  name: 'BlockPromotion',
  components: { Card },
  props: {
    cmsData: Object,
  },
  data() {
    return {
      promotion: undefined,
    };
  },
  computed: {
    randomTag() {
      return this.promotion.acf.image_tag.replace(
        '$RANDOM',
        Math.floor(Math.random() * 10000000)
      );
    },
  },
  created() {
    this.cmsData.type === 'static' ? () => {} : this.fetchData();
  },
  methods: {
    fetchData() {
      const vm = this;

      cms.getPromotion({ slug: vm.cmsData.promotion_slug }).then((data) => {
        vm.promotion = data[0];
        this.$ga.event('Ads', 'view', data[0].title.rendered);
      });
    },
    sendClickEvent() {
      this.$ga.event('Ads', 'click', this.promotion.title.rendered);
    },
  },
};
</script>

<style lang="scss">
.block-promotion {
  position: relative;
  width: 100%;
  min-height: 25rem;
  background-size: cover;
  background-position: center center;
}

.block-promotion__content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 50%;
  padding: 2rem 1.5rem 1.5rem;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.4) 40%
  );
}

.block-promotion__description {
  white-space: pre-line;
}

.block-promotion__label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.75rem;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 2px;
}
</style>

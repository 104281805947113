<template>
  <div class="bg-white rounded p-4 shadow w-full max-w-xs">
    <h2 class="text-lg font-bold mb-3">Aktiivisimmat Sandislaiset</h2>
    <div class="mb-4">
      <button
        :class="[
          'top-users-tab',
          activeTab === 'month' && 'top-users-tab--active',
        ]"
        @click="() => (activeTab = 'month')"
      >
        Viimeinen kuukausi
      </button>
      <button
        :class="[
          'top-users-tab',
          activeTab === 'all_time' && 'top-users-tab--active',
        ]"
        @click="() => (activeTab = 'all_time')"
      >
        Kautta aikain
      </button>
    </div>
    <ul class="border-b-2 border-grey-lighter mb-2 pb-1">
      <li
        class="flex justify-between mb-1"
        v-for="user in visibleUsers"
        :key="user.id"
      >
        <div>{{ user.name || `Sandislainen#${user.id}` }}</div>
        <div>{{ user.points }}p</div>
      </li>
    </ul>
    <small>
      Voit ansaita pisteitä lisäämällä paikkoja ja kuvia ja jakamalla
      kokemuksia.
    </small>
  </div>
</template>

<script>
import api from '@/api';

export default {
  name: 'TopUsers',
  data() {
    return {
      activeTab: 'month',
      topUsers: {
        all_time: [],
        month: [],
      },
    };
  },
  computed: {
    visibleUsers() {
      return this.topUsers[this.activeTab];
    },
  },
  created() {
    this.fetchTopUsers();
  },
  methods: {
    async fetchTopUsers() {
      try {
        const resp = await api.activity.getTopUsers();

        this.topUsers = resp.data;
      } catch (err) {
        console.log('error fetching top users', err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.top-users-tab {
  @apply font-bold text-blue-darkest border-b-4 border-transparent py-1;

  & + & {
    @apply ml-4;
  }

  &--active {
    @apply border-orange;
  }
}
</style>

<template>
  <div>
    <form action="" id="dropzone" class="dropzone">
      <div class="dz-message">
        Pudota kuva tai klikkaa valitaksesi tiedosto.
      </div>
    </form>
    <br />
    <div class="flex justify-end">
      <button class="button button--teal" @click="onUploadClick">
        Lähetä kuva
      </button>
    </div>
  </div>
</template>

<script>
import Dropzone from 'dropzone';
import { getBaseUrl } from '../api';
import auth from '../utils/auth';
Dropzone.autoDiscover = false;

export default {
  props: {
    onSuccess: Function,
    endpoint: String,
  },
  data() {
    return {
      dropzone: {},
    };
  },
  mounted: function () {
    this.initDropzone();
  },
  methods: {
    async initDropzone() {
      const token = await auth.getApiToken();
      const baseUrl = getBaseUrl();
      const url = `${baseUrl}${this.endpoint}`;

      const options = {
        url,
        method: 'post',
        maxFiles: 1,
        autoProcessQueue: false,
        paramName: 'image',
        success: this.onSuccess,
        headers: {
          Authorization: token,
        },
      };

      this.dropzone = new Dropzone('form#dropzone', options);
    },
    onUploadClick() {
      this.dropzone.processQueue();
    },
  },
};
</script>

<style lang="scss" scoped>
#dropzone {
  height: 20rem;
}
</style>

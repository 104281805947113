<template>
  <div>
    <div
      v-show="!editIsSuccess && !editIsError && !isConfirmDeleteVisible"
      class="rounded shadow bg-white p-4"
    >
      <h2 class="font-bold text-lg mb-6">Muokkaa paikkaa {{ currentName }}</h2>
      <!-- name -->
      <div class="mb-6">
        <label class="label" for="input-name">Nimi</label>
        <div class="control">
          <input
            v-model="item.name"
            @input="$v.item.name.$touch()"
            class="input"
            id="input-name"
            type="text"
          />
        </div>
      </div>

      <!-- category -->
      <div class="mb-6">
        <fieldset>
          <legend class="label">Kategoria</legend>
          <div class="tags">
            <label
              v-for="category in Object.keys(categories[type])"
              :key="category"
              :class="[
                'tag is-medium',
                { 'is-primary': item.category === category },
              ]"
            >
              {{ $t('categories.' + category) }}
              <input
                v-model="item.category"
                @change="$v.item.category.$touch()"
                :value="category"
                class="hidden"
                type="radio"
                name="category"
              />
            </label>
          </div>
        </fieldset>
      </div>

      <!-- subcategory -->
      <!--
        <div class="field" v-if="item.category">
          <fieldset>
            <legend class="label">Tarkempi kategoria</legend>
            <div class="tags">
              <label
                v-for="subcategory in categories[type][item.category].subcategories"
                :class="['tag is-medium', { 'is-primary': item.subcategory === subcategory }]">
                {{ $t(`subcategories.${item.category}.${subcategory}`) }}
                <input
                  v-model="item.subcategory"
                  @change="$v.item.subcategory.$touch()"
                  :value="subcategory"
                  class="hidden"
                  type="radio"
                  name="subcategory" >
              </label>
            </div>
          </fieldset>
        </div>
      -->

      <!-- location & address -->

      <div class="mb-6">
        <label class="label">Sijainti</label>
        <div class="edit-map-container">
          <gmap-map
            ref="gmap"
            :center="mapCenter"
            :zoom="15"
            style="width: 100%; height: 100%"
            :options="mapOptions"
            @center_changed="onMapCenterChange"
          >
            <template slot="visible">
              <div class="create-map__target">
                <img src="/static/icon-target.svg" />
              </div>
              <GoogleMapSearch
                v-if="mapReady"
                :map="$refs.gmap.$mapObject"
                @result="onSearchResult"
              ></GoogleMapSearch>
            </template>
          </gmap-map>
        </div>
      </div>

      <div class="mb-6">
        <label class="label" for="input-address">Osoite</label>
        <div class="control">
          <input
            type="text"
            v-model="item.address"
            @input="$v.item.address.$touch()"
            id="input-addess"
            class="input"
          />
        </div>
      </div>

      <!-- short_description -->
      <div class="mb-6">
        <label class="label" for="">Lyhyt kuvaus</label>
        <div class="control">
          <textarea
            v-model="item.short_description"
            @input="$v.item.short_description.$touch()"
            class="textarea"
            rows="4"
          ></textarea>
        </div>
      </div>

      <!-- description -->
      <div class="mb-6">
        <label class="label" for="">Lisätietoja</label>
        <div class="control">
          <textarea
            v-model="item.description"
            @input="$v.item.description.$touch()"
            class="textarea"
            rows="10"
          ></textarea>
        </div>
      </div>

      <!-- tags -->
      <div class="mb-6" v-if="availableTags.length > 0">
        <fieldset>
          <legend class="label">Ominaisuudet</legend>
          <div class="tags">
            <label
              v-for="tag in availableTags"
              :class="[
                'tag is-medium',
                { 'is-primary': ~item.tags.indexOf(tag) },
              ]"
              :key="tag"
            >
              {{ $t('tags.' + tag) }}
              <input
                type="checkbox"
                v-model="item.tags"
                @change="$v.item.tags.$touch()"
                :value="tag"
                class="hidden"
                name="tags"
              />
            </label>
          </div>
        </fieldset>
      </div>

      <!-- ages -->
      <div class="mb-6">
        <fieldset>
          <legend class="label">Ikä</legend>
          <div class="tags">
            <label
              v-for="age in ['baby', 'toddler', 'preschooler', 'gradeschooler']"
              :key="age"
              :class="[
                'tag is-medium',
                { 'is-primary': ~item.ages.indexOf(age) },
              ]"
            >
              {{ $t('ages.' + age) }}
              <input
                type="checkbox"
                v-model="item.ages"
                @change="$v.item.ages.$touch()"
                :value="age"
                class="hidden"
                name="ages"
              />
            </label>
          </div>
        </fieldset>
      </div>

      <!-- website -->
      <div class="mb-6">
        <label for="input-website" class="label">WWW-osoite</label>
        <div class="control">
          <input
            class="input"
            type="text"
            id="input-website"
            v-model="item.website"
            @input="$v.item.website.$touch()"
          />
        </div>
      </div>

      <br />

      <div class="flex justify-between">
        <button class="button button--red" @click="onDeleteClick">
          Poista
        </button>
        <button
          :disabled="dirtyInputs.length < 1"
          :class="['button button--teal', { 'is-loading': editIsLoading }]"
          @click="onSubmitClick"
        >
          Tallenna muutokset
        </button>
      </div>
    </div>
    <!-- Edit success -->
    <div v-if="editIsSuccess" class="p-4">
      <div class="rounded shadow bg-green text-white p-4">
        Tekemäsi muutokset tallennettiin onnistuneesti, kiitos!
      </div>
    </div>
    <!-- Edit Error -->
    <div v-if="editIsError" class="p-4">
      <div class="rounded shadow bg-red text-white p-4">
        Hups, jotain meni pieleen! Yritä hetken päästä uudelleen.
      </div>
    </div>
    <!-- Delete confirm -->
    <div v-if="isConfirmDeleteVisible" class="p-4">
      <div class="bg-white rounded shadow p-4">
        <p>Oletko varma että haluat poistaa kohteen {{ item.name }}?</p>
        <div class="flex justify-between mt-4">
          <button class="button" @click="onCancelDeleteClick">Peruuta</button>
          <button class="button button--red" @click="onConfirmDeleteClick">
            Poista
          </button>
        </div>
      </div>
    </div>
    <!-- Delete success -->
    <div v-if="isDeleteSuccess" class="p-4">
      <div class="rounded shadow bg-green text-white p-4">
        {{ item.nime }} poistettiin.
      </div>
    </div>
  </div>
</template>

<script>
import GoogleMapSearch from '@/components/GoogleMapSearch';
import oldApi from '../utils/api';
import api from '@/api';
import categories from '../utils/categories';
import tags from '../utils/tags';
import throttle from 'lodash.throttle';
import { globalMapOptions } from '../utils/map';

export default {
  name: 'Edit',
  components: {
    GoogleMapSearch,
  },
  props: {
    currentAddress: String,
    currentAges: Array,
    currentCategory: String,
    currentDescription: String,
    currentLocation: {
      lat: Number,
      lng: Number,
    },
    currentName: String,
    currentShortDescription: String,
    currentSubcategory: String,
    currentTags: Array,
    currentWebsite: String,
    id: String,
    type: String,
  },
  data() {
    return {
      // general
      categories,
      editIsLoading: false,
      editIsSuccess: false,
      editIsError: false,
      isConfirmDeleteVisible: false,
      isDeleteSuccess: false,
      mapCenter: this.currentLocation,
      mapOptions: globalMapOptions,
      mapReady: false,
      // location/event
      item: {
        address: this.currentAddress,
        ages: this.currentAges || [],
        category: this.currentCategory,
        description: this.currentDescription,
        location: this.currentLocation,
        name: this.currentName,
        short_description: this.currentShortDescription,
        subcategory: this.currentSubcategory,
        tags: this.currentTags || [],
        website: this.currentWebsite,
      },
    };
  },
  mounted() {
    this.$refs.gmap.$mapPromise.then(() => (this.mapReady = true));
  },
  validations: {
    item: {
      address: {},
      ages: {},
      category: {},
      description: {},
      location: {},
      name: {},
      short_description: {},
      subcategory: {},
      tags: {},
      website: {},
    },
  },
  computed: {
    availableTags() {
      return tags.all.concat(tags[this.category] || []);
    },
    dirtyInputs() {
      return Object.keys(this.$v.item).filter(
        (key) => this.$v.item[key].$dirty
      );
    },
  },
  methods: {
    onMapCenterChange: throttle(function (center) {
      const lat = center.lat();
      const lng = center.lng();

      this.mapCenter = { lat, lng };
      this.item.location = { lat, lng };
      this.$v.item.location.$touch();
    }),
    onCancelDeleteClick() {
      this.isConfirmDeleteVisible = false;
    },
    async onConfirmDeleteClick() {
      try {
        await api.things.delete(this.id);
        this.isConfirmDeleteVisible = false;
        this.isDeleteSuccess = true;
      } catch (err) {
        console.log('error deleting thing', err);
      }
    },
    onDeleteClick() {
      this.isConfirmDeleteVisible = true;
    },
    onSearchResult(place) {
      // Update map
      const location = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };

      this.mapCenter = location;

      // Get location details provided by Google
      this.item.location = location;
      this.item.address = this.item.address || place.formatted_address;
    },
    onSubmitClick() {
      const vm = this;
      vm.editIsError = false;
      vm.editIsSuccess = false;
      vm.editIsLoading = true;

      const data = this.dirtyInputs.reduce((acc, key) => {
        acc[key] = this.item[key];
        return acc;
      }, {});

      oldApi[`${vm.type}s`]
        .edit(this.id, data)
        .then((data) => {
          console.log(data);
          vm.editIsLoading = false;
          vm.editIsSuccess = true;
        })
        .catch((error) => {
          console.log(error);
          vm.editIsLoading = false;
          vm.editIsError = true;
        });
    },
  },
};
</script>

<style lang="scss">
.edit-map-container {
  height: 20rem;
  width: 100%;
}

.create-map__target {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  img {
    width: 2rem;
    height: 2rem;
  }
}
</style>

<template>
  <div class="section create-event">
    <div class="container">
      <header class="text-center">
        <h1 class="font-bold text-4xl">Lisää tapahtuma Sandikseen</h1>
      </header>

      <!-- Disable until fb approval -->
      <section v-if="false" class="create-section">
        <div class="has-text-centered">
          <button
            class="button is-primary is-medium"
            @click="importVisible = !importVisible"
            type="button"
            name="button"
          >
            Tuo tapahtuma Facebookista
          </button>
        </div>
        <br />
        <div v-show="importVisible" class="box facebook-import">
          <label for="import-url">Tapahtuman www-osoite</label>
          <small>Esim. https://www.facebook.com/events/1234566781010/</small>
          <input
            class="input"
            id="import-url"
            v-model="eventUrl"
            type="text"
            name="name"
            value=""
            @input="fbImportError = false"
          />
          <br />
          <br />
          <div v-if="fbImportError" class="message is-danger has-text-centered">
            <div class="message-body">
              Voi ei! Tapahtumatietojen hakeminen epäonnistui :(
            </div>
          </div>
          <div class="has-text-centered">
            <button
              :class="[
                'button is-primary is-medium',
                { 'is-loading': fbImportLoading },
              ]"
              @click="onImportClick"
              type="button"
              name="button"
            >
              Hae tiedot
            </button>
          </div>
        </div>
      </section>

      <section class="create-section">
        <label for="event-name">
          <h2 class="create-section__title">Tapahtuman nimi</h2>
        </label>
        <p class="create-section__description">
          Minkä nimisen tapahtuman haluat lisätä palveluun?
        </p>
        <div class="control">
          <input
            class="input is-medium"
            v-model="eventObj.name"
            id="event-name"
            type="text"
            value=""
          />
        </div>
        <div v-show="eventObj.name.length < 1" class="message message--yellow">
          <div class="message-body">Syötä tapahtuman nimi jatkaaksesi.</div>
        </div>
        <button
          class="button button--teal create-section__button-next"
          :disabled="eventObj.name.length < 1"
          @click="onNextClick"
        >
          Seuraava
        </button>
      </section>

      <section class="create-section create-section--disabled">
        <h2 class="create-section__title">Kaupunki</h2>
        <p class="create-section__description">Missä kaupungissa paikka on?</p>
        <div class="create-location__city mb-4">
          <Multiselect
            v-model="eventObj.city"
            :allow-empty="false"
            :options="cityOptions"
            placeholder="Valitse kaupunki"
            :show-labels="false"
            :show-pointer="false"
            :searchable="true"
          >
            <span slot="carret"></span>
          </Multiselect>
        </div>
        <button
          class="button button--teal create-section__button-next"
          :disabled="eventObj.city.length < 1"
          @click="onNextClick"
        >
          Seuraava
        </button>
      </section>

      <section class="create-section create-section--disabled">
        <label for="event-description">
          <h2 class="create-section__title">Lyhyt kuvaus</h2>
        </label>
        <p class="create-section__description">
          Kerro lyhyesti mistä tapahtumassa on kyse. Voit tarvittaessa lisätä
          pidemmän kuvauksen myöhemmin.
        </p>
        <div class="control">
          <textarea
            class="textarea"
            v-model="eventObj.short_description"
            id="event-description"
            rows="5"
          ></textarea>
          <strong>
            {{ eventObj.short_description.length }}/160 merkkiä käytetty
          </strong>
        </div>
        <div
          v-show="eventObj.short_description.length < 10"
          class="message message--yellow"
        >
          <div class="message-body">
            Kuvauksen täytyy olla ainakin 10 merkkiä pitkä.
          </div>
        </div>
        <button
          class="button button--teal create-section__button-next"
          :disabled="
            eventObj.short_description.length < 10 ||
            eventObj.short_description.length > 160
          "
          @click="onNextClick"
        >
          Seuraava
        </button>
      </section>

      <section
        v-if="eventObj.description"
        class="create-section create-section--disabled"
      >
        <label for="event-description">
          <h2 class="create-section__title">Lisätietoja</h2>
        </label>
        <p class="create-section__description">
          Tässä voit kertoa laajemmin, mistä tapahtumassa on kyse.
        </p>
        <div class="control">
          <textarea
            class="textarea"
            v-model="eventObj.description"
            id="event-description"
            rows="15"
          ></textarea>
        </div>
        <button
          class="button button--teal create-section__button-next"
          @click="onNextClick"
        >
          Seuraava
        </button>
      </section>

      <section class="create-section create-section--disabled">
        <h2 class="create-section__title">Ajankohta</h2>
        <br />
        <h3 class="title is-4">Tapahtuma alkaa</h3>
        <p class="subtitle is-6 create-section__description">
          Millon tapahtuma järjestetään?
        </p>
        <br />
        <div class="date-selection columns">
          <div class="date-selection__date column is-9">
            <label class="label" for="event-start-date">Päivämäärä</label>
            <input class="input is-medium" type="text" id="event-start-date" />
          </div>
          <div class="date-selection__time column is-3">
            <label class="label" for="event-start-time">
              Kellonaika
              <small>(hh:mm)</small>
            </label>
            <input
              class="input is-medium"
              v-model="startDate.time"
              type="text"
              id="event-start-time"
              value=""
              placeholder="00:00"
            />
          </div>
        </div>
        <div v-show="!startDateValid" class="message message--yellow">
          <div class="message-body">
            Valitse tapahtumalle ensin alkamisajankohta.
          </div>
        </div>
        <br />
        <div :class="{ ['create-section--disabled']: !startDateValid }">
          <h3 class="title is-4">Tapahtuma päättyy</h3>
          <p class="subtitle is-6 create-section__description">
            Valitse tapahtumalle myös päättymisajankohta.
          </p>
          <br />
          <div class="date-selection columns">
            <div class="date-selection__date column is-9">
              <label class="label" for="event-end-date">Päivämäärä</label>
              <input class="input is-medium" type="text" id="event-end-date" />
            </div>
            <div class="date-selection__time column is-3">
              <label class="label" for="event-end-time">
                Kellonaika
                <small>(hh:mm)</small>
              </label>
              <input
                class="input is-medium"
                v-model="endDate.time"
                type="text"
                id="event-end-time"
                value=""
                placeholder="00:00"
              />
            </div>
          </div>
          <br />
        </div>

        <button
          class="button button--teal create-section__button-next"
          :disabled="!startDateValid || !endDateValid"
          @click="onNextClick"
        >
          Seuraava
        </button>
      </section>

      <section class="create-section create-section--disabled">
        <h2 class="create-section__title">Sijainti</h2>
        <p class="create-section__description">
          Missä tapahtuma on? Auta meitä sijoittamaan se kartalle.
        </p>
        <div class="create-event__map-container">
          <gmap-map
            ref="gmap"
            :center="mapCenter"
            :zoom="15"
            style="width: 100%; height: 100%"
            :options="mapOptions"
            @center_changed="onMapCenterChange"
            @dragend="syncMap"
          >
            <template slot="visible">
              <div class="create-event__map-target">
                <img src="/static/icon-target.svg" />
              </div>
              <GoogleMapSearch
                v-if="mapReady"
                :map="$refs.gmap.$mapObject"
                @result="onSearchResult"
              ></GoogleMapSearch>
            </template>
          </gmap-map>
        </div>
        <div class="field">
          <label class="label" for="address">Osoite</label>
          <div class="control">
            <input
              v-model="eventObj.address"
              id="address"
              type="text"
              class="input is-medium"
            />
          </div>
          <p class="help">
            Anna mahdollisimman tarkka osoite, esimerkiksi
            <i>Mallikuja 5 a 10, 00100 Helsinki</i>
          </p>
        </div>

        <br />
        <button
          class="button button--teal create-section__button-next"
          :disabled="
            eventObj.address.length < 1 ||
            !eventObj.location.lat ||
            !eventObj.location.lng
          "
          @click="onNextClick"
        >
          Seuraava
        </button>
      </section>

      <section class="create-section create-section--disabled">
        <h2 class="create-section__title">Muut tiedot</h2>

        <div class="field">
          <label class="label" for="website">Verkko-osoite</label>
          <div class="control">
            <input
              v-model="eventObj.website"
              type="text"
              class="input is-medium"
            />
          </div>
          <p class="help">Esimerkiksi tapahtuman Facebook-sivu tai kotisivu</p>
        </div>

        <br />
        <button
          class="button button--teal create-section__button-next"
          :disabled="false"
          @click="onNextClick"
        >
          Seuraava
        </button>
      </section>

      <section class="create-section create-section--disabled">
        <h2 class="create-section__title">Yhteenveto</h2>

        <div class="box summary">
          <strong>Nimi:</strong>
          {{ eventObj.name }}
          <br />
          <strong>Kuvaus:</strong>
          {{ eventObj.short_description }}
          <br />
          <strong>Osoite:</strong>
          {{ eventObj.address }}
          <br />
        </div>
        <button
          :class="[
            'button button--teal create-section__button-next',
            { 'is-loading': eventSaveLoading },
          ]"
          @click="onEventSaveClick"
          type="button"
        >
          Lisää tapahtuma
        </button>
        <div class="has-text-centered" v-if="saveError">
          <br />
          <div class="notification is-danger">
            Hups! Virhe tallennettaessa tapahtumaa :(
          </div>
        </div>
      </section>
      <div :class="['modal', { 'is-active': saveSuccess }]">
        <div class="modal-background"></div>
        <div class="modal-content text-center bg-white rounded shadow">
          <p class="title is-1">🎉</p>
          <p class="subtitle is-2">Mahtavaa!</p>
          <p>
            Tapahtuma {{ eventObj.name }} lisättiin Sandikseen onnistuneesti!
          </p>
          <br />
          <router-link
            :to="'/browse/events'"
            class="button is-large is-primary"
          >
            Selaa tapahtumia
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pikaday from 'pikaday';
import zenscroll from 'zenscroll';
import GoogleMapSearch from '../components/GoogleMapSearch.vue';
import Multiselect from 'vue-multiselect';
import locales from '../i18n/locales';
import api from '../utils/api';
import throttle from 'lodash.throttle';
import { globalMapOptions } from '../utils/map';
import { format, isValid, parse, setHours, setMinutes } from 'date-fns';
import { sortByProp } from '../utils/utils';

const hhmmRegex = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

export default {
  name: 'CreateEvent',
  components: {
    GoogleMapSearch,
    Multiselect,
  },
  data() {
    return {
      reportedCenter: { lat: 60.1699, lng: 24.9384 },
      mapCenter: null,
      markerPosition: { lat: 60.1699, lng: 24.9384 },
      importVisible: false,
      endDateVisible: false,
      fbImportLoading: false,
      fbImportError: false,
      eventSaveLoading: false,
      saveError: false,
      saveSuccess: false,
      eventUrl: '',
      startPicker: {},
      startDate: {
        date: new Date(),
        time: '',
      },
      endPicker: {},
      endDate: {
        date: new Date(),
        time: '',
      },
      duration: '',
      eventObj: {
        name: '',
        short_description: '',
        city: '',
        description: '',
        location: {
          lat: 0,
          lng: 0,
        },
        address: '',
        dates: [],
        website: '',
      },
      mapOptions: {
        ...globalMapOptions,
        zoomControlOptions: { position: 9 },
      },
      mapReady: false,
    };
  },
  created() {
    this.syncMap();
    this.$store.dispatch('fetchCities');
  },
  mounted() {
    const vm = this;

    this.$refs.gmap.$mapPromise.then(() => {
      this.$refs.gmap.$mapPromise.then(() => (this.mapReady = true));
    });

    vm.startPicker = new Pikaday({
      field: document.getElementById('event-start-date'),
      format: 'D.M.YYYY',
      firstDay: 1,
      minDate: new Date(),
      i18n: locales.fi.pikaday,
      onSelect(date) {
        vm.startDate.date = date;
        vm.endPicker.setDate(date);
      },
    });

    vm.endPicker = new Pikaday({
      field: document.getElementById('event-end-date'),
      format: 'D.M.YYYY',
      firstDay: 1,
      minDate: new Date(),
      i18n: locales.fi.pikaday,
      onSelect(date) {
        vm.endDate.date = date;
      },
    });
  },
  methods: {
    onNextClick(event) {
      let nextSection = event.target.parentNode.nextElementSibling;
      nextSection.classList.remove('create-section--disabled');
      event.target.parentNode.removeChild(event.target);
      zenscroll.center(nextSection);
    },
    onEventSaveClick() {
      const vm = this;

      vm.eventSaveLoading = true;

      const createDate = (obj) => {
        const [hours, minutes] = obj.time.split(':');
        return setMinutes(setHours(obj.date, hours), minutes);
      };

      vm.eventObj.dates = [
        ...vm.eventObj.dates,
        {
          start_date: createDate(vm.startDate),
          end_date: createDate(vm.endDate),
        },
      ];

      api.events.post({ event: this.eventObj }).then(
        () => {
          vm.eventSaveLoading = false;
          vm.saveSuccess = true;
        },
        (error) => {
          console.error(error);
          vm.eventSaveLoading = false;
          vm.saveError = true;
        }
      );
    },
    onImportClick() {
      const vm = this;
      vm.fbImportLoading = true;
      api.events.import(this.eventUrl).then(
        (response) => {
          vm.fbImportLoading = false;

          const event = JSON.parse(response.data);
          console.log(event);

          vm.eventObj.name = event.name;
          vm.eventObj.description = event.description;

          vm.eventObj.startDate = parse(event.start_time);
          vm.startPicker.setDate(parse(event.start_time));
          vm.startDate.time = format(event.start_time, 'HH:mm');

          if (event.end_time) {
            vm.eventObj.endDate = parse(event.end_time);
            vm.endPicker.setDate(parse(event.start_time));
            vm.endDate.time = format(event.end_time, 'HH:mm');
          }

          vm.eventObj.website = vm.eventUrl;

          const {
            city,
            latitude,
            longitude,
            street,
            zip,
          } = event.place.location;

          vm.eventObj.address =
            city && street && zip ? `${street}, ${zip} ${city}` : '';

          vm.setLocation({
            lat: latitude,
            lng: longitude,
          });
        },
        (error) => {
          vm.fbImportLoading = false;
          vm.fbImportError = true;
          console.error(error);
        }
      );
    },
    onSearchResult(place) {
      // Update map
      const location = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };

      this.setLocation(location);
      this.eventObj.address = place.formatted_address;
    },
    setLocation(location) {
      this.mapCenter = location;
      this.markerPosition = location;
      this.eventObj.location = location;
    },
    syncMap() {
      this.eventObj.location = this.mapCenter = this.reportedCenter;
    },
    onMapCenterChange: throttle(function (center) {
      const lat = center.lat();
      const lng = center.lng();

      this.reportedCenter = { lat, lng };
    }),
  },
  computed: {
    startDateValid() {
      return (
        isValid(this.startDate.date) && hhmmRegex.test(this.startDate.time)
      );
    },
    endDateValid() {
      return isValid(this.endDate.date) && hhmmRegex.test(this.endDate.time);
    },
    cityOptions() {
      return [...this.$store.state.cities]
        .sort(sortByProp('name'))
        .map((city) => city.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.create-event {
  padding: 1rem;
  padding-bottom: 25vh;

  &__map-container {
    position: relative;
    width: 100%;
    height: 20rem;
    margin-bottom: 0.5rem;
  }
}

.create-event__map-target {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  img {
    width: 2rem;
    height: 2rem;
  }
}

.create-section {
  @apply bg-white rounded shadow p-4;
  max-width: 40rem;
  margin: 0 auto;

  &:first-of-type {
    margin-top: 5rem;
  }

  &--disabled {
    opacity: 0.5;
    filter: grayscale(100%);
    pointer-events: none;
    user-select: none;
  }

  &__title {
    @apply font-bold text-lg;
  }

  &__title,
  &__description {
    @apply mb-2;
  }

  &__button-next {
    display: block;
    margin: 0 auto;
  }
}

.create-section + .create-section {
  margin-top: 5rem;
}

.facebook-import {
  display: inline-block;
  width: 100%;
  padding: 1rem;
  margin-bottom: 1.5rem;
}
</style>

<template>
  <div class="bg-white rounded p-4">
    <h2 class="font-bold text-lg mb-6">Muokkaa listaa</h2>
    <div class="field">
      <label for="input-name">Nimi</label>
      <div class="control">
        <input
          v-model="name"
          @input="$v.name.$touch()"
          id="input-name"
          type="text"
          class="input"
        />
      </div>
    </div>
    <div class="field">
      <label for="input-description">Kuvaus</label>
      <div class="control">
        <textarea
          v-model="description"
          @input="$v.description.$touch()"
          id="input-description"
          class="textarea"
          rows="4"
        ></textarea>
      </div>
    </div>
    <div class="field">
      <label>Väri</label>
      <div class="control">
        <ButtonColor
          v-for="c in colors"
          :key="c"
          type="color"
          :onClick="onColorClick"
          :colorId="c"
          :active="color === c"
        ></ButtonColor>
      </div>
    </div>
    <div class="field">
      <label>Kuvio</label>
      <div class="control">
        <ButtonColor
          v-for="p in patterns"
          :key="p"
          type="pattern"
          :onClick="onPatternClick"
          :patternId="p"
          :colorId="color"
          :active="pattern === p"
        ></ButtonColor>
      </div>
    </div>
    <div class="flex justify-end">
      <button class="button is-light" @click="onCancelClick">Peruuta</button>
      <button
        :disabled="dirtyInputs.length < 1"
        @click="onSaveClick"
        :class="['button button--teal', { 'is-loading': isLoading }]"
      >
        Tallenna
      </button>
    </div>
  </div>
</template>

<script>
import ButtonColor from '../components/ButtonColor.vue';
import { required, minLength } from 'vuelidate/lib/validators';
import api from '../utils/api';
import { colors, patterns } from '../utils/utils';

export default {
  name: 'EditList',
  props: {
    currentColor: Number,
    currentDescription: String,
    currentName: String,
    currentPattern: Number,
    id: Number,
    onCancelClick: Function,
    onSuccess: { type: Function, default: () => {} },
  },
  data() {
    return {
      color: this.currentColor,
      colors: Object.keys(colors).map((key) => parseInt(key)),
      description: this.currentDescription,
      name: this.currentName,
      isLoading: false,
      pattern: this.currentPattern,
      patterns: Object.keys(patterns).map((key) => parseInt(key)),
    };
  },
  components: { ButtonColor },
  computed: {
    dirtyInputs() {
      return ['name', 'description', 'color', 'pattern'].filter((key) => {
        return this.$v[key].$dirty;
      });
    },
  },
  methods: {
    onColorClick(color) {
      this.color = color;
      this.$v.color.$touch();
    },
    onPatternClick(pattern) {
      this.pattern = pattern;
      this.$v.pattern.$touch();
    },
    onSaveClick() {
      const data = this.dirtyInputs.reduce((acc, key) => {
        acc[key] = this[key];
        return acc;
      }, {});

      const vm = this;
      vm.isLoading = true;
      api.lists
        .edit(this.id, data)
        .then(() => {
          console.log('much success');
          vm.isLoading = false;
          vm.onSuccess();
        })
        .catch((error) => {
          vm.isLoading = false;
          console.log('error', error);
        });
    },
  },
  validations: {
    name: {
      required,
      minLength: minLength(2),
    },
    description: {},
    color: {},
    pattern: {},
  },
};
</script>

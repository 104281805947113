<template>
  <div class="modal is-active background-shapes">
    <div @click="onCloseClick" class="modal-background"></div>
    <div class="modal-content"><slot></slot></div>
    <button @click="onCloseClick" class="modal-close is-large"></button>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    isOpen: { type: Boolean, default: false },
    onCloseClick: { type: Function, default: () => {} },
  },
};
</script>

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'babel-polyfill';
import smoothscroll from 'smoothscroll-polyfill';
import Vue from 'vue';
import VueGtag from 'vue-gtag';
import Vuelidate from 'vuelidate';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueApollo from 'vue-apollo';
import App from './App';
import i18n from './i18n';
import router from './router';
import store from './store';
import cmsClient from './cms/apollo';
// import './registerServiceWorker'

// Polyfill for smooth scrolling
smoothscroll.polyfill();

Vue.config.productionTip = false;

// Google Tag Manager
const gtagId = 'G-ZDJM4Y6KX9';

Vue.use(VueGtag, {
  config: { id: gtagId },
  appName: 'My application',
  pageTrackerScreenviewEnabled: true
}, router);

// Form validation
Vue.use(Vuelidate);

// Google Maps
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: 'places', // This is required if you use the Autocomplete plugin
  },
});

// Apollo
Vue.use(VueApollo);
const apolloProvider = new VueApollo({
  clients: {
    // Can define another client if we switch to graphql backend
    cms: cmsClient,
  },
  defaultClient: cmsClient,
});

new Vue({
  i18n,
  router,
  store,
  apolloProvider,
  render: (h) => h(App),
  watch: {
    $route() {
      // Call resizePreserveCenter() on all maps
      Vue.$gmapDefaultResizeBus.$emit('resize');

      // Check that user has a preferred_username set
      // const user = this.$store.state.user
      // if (!user.name) {
      //   this.$router.push(`/profile/name?source=redirect&to=${to.path}`)
      // }
    },
  },
}).$mount('#app');

<template>
  <article class="section reset-password">
    <header class="has-text-centered">
      <h1 class="title is-1">Uusi salasana</h1>
      <p class="subtitle is-5">
        Valitse itsellesi uusi salasana, niin pääset taas mukaan Sandikseen!
      </p>
    </header>
    <div class="container reset-password__content">
      <div v-if="!resetSuccess">
        <div class="field">
          <label class="label" for="username">
            Käyttäjätunnus tai sähköpostiosoite
          </label>
          <div class="control">
            <input
              v-model="username"
              id="username"
              class="input is-medium"
              type="text"
            />
          </div>
        </div>
        <div class="field" v-show="!verificationDisabled">
          <label class="label" for="verification-code">Palautuskoodisi</label>
          <div class="control">
            <input
              :disabled="verificationDisabled"
              v-model="verificationCode"
              id="verification-code"
              class="input is-medium"
              type="text"
            />
          </div>
          <p class="help">
            Tämän koodin löydät sinulle lähettämästämme sähköpostista.
          </p>
        </div>
        <br />
        <div class="field">
          <label class="label" for="new-password">Uusi salasana</label>
          <div class="control">
            <input
              v-model="password"
              id="new-password"
              class="input is-medium"
              type="password"
            />
          </div>
          <p class="help">Salasanan täytyy olla vähintään 8 merkkiä pitkä.</p>
        </div>
        <div class="field">
          <label class="label" for="new-password-verification">
            Uusi salasana uudestaan
          </label>
          <div class="control">
            <input
              v-model="passwordVerification"
              @input="$v.passwordVerification.$touch"
              id="new-password-verification"
              class="input is-medium"
              type="password"
            />
          </div>
          <strong
            v-if="
              $v.passwordVerification.$dirty && $v.passwordVerification.$invalid
            "
            class="help is-danger"
          >
            Salasanojen täytyy täsmätä.
          </strong>
        </div>
        <div class="field">
          <div class="control">
            <button
              :disabled="!isValid"
              @click="onSendVerificationClick"
              class="button is-primary"
            >
              Vaihda salasana
            </button>
          </div>
        </div>
        <div v-if="resetFailed" class="notification is-danger">
          Hups! Jotain meni pieleen salasanaa vaihtaessa, kirjoitithan
          tunnuksesi tai sähköpostiosoitteesi oikein?
        </div>
      </div>
      <div v-if="resetSuccess" class="has-text-centered">
        <div class="notification is-success">
          <p>
            Salasanasi on nyt vaihdettu. Voit kirjautua sisään uudella
            salasanallasi vaikka samantien. Huisia!
          </p>
        </div>
        <router-link :to="'/'" tag="button" class="button is-primary">
          Palaa Sandiksen etusivulle
        </router-link>
      </div>
    </div>
  </article>
</template>

<script>
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
const { VUE_APP_COGNITO_USER_POOL, VUE_APP_COGNITO_APP_ID } = process.env;

export default {
  name: 'ResetPassword',
  data() {
    return {
      username: '',
      verificationCode: '',
      password: '',
      passwordVerification: '',
      verificationDisabled: false,
      resetFailed: false,
      resetSuccess: false,
    };
  },
  computed: {
    isValid() {
      return (
        !this.$v.username.$invalid &&
        !this.$v.verificationCode.$invalid &&
        !this.$v.password.$invalid &&
        !this.$v.passwordVerification.$invalid
      );
    },
  },
  created() {
    if (this.$route.query.code && this.$route.query.code.length > 0) {
      this.verificationCode = this.$route.query.code;
      this.verificationDisabled = true;
    }
  },
  methods: {
    onSendVerificationClick() {
      const vm = this;
      vm.resetFailed = false;

      const userPool = new CognitoUserPool({
        UserPoolId: VUE_APP_COGNITO_USER_POOL,
        ClientId: VUE_APP_COGNITO_APP_ID,
      });

      const user = new CognitoUser({
        Username: this.username,
        Pool: userPool,
      });

      user.confirmPassword(this.verificationCode, this.password, {
        onSuccess() {
          vm.resetSuccess = true;
        },
        onFailure(err) {
          console.error(err);
          vm.resetFailed = true;
        },
      });
    },
  },
  validations: {
    username: {
      required,
    },
    password: {
      required,
      minLength: minLength(8),
    },
    verificationCode: {
      required,
    },
    passwordVerification: {
      required,
      sameAsPassword: sameAs('password'),
    },
  },
};
</script>

<style lang="scss">
.reset-password__content {
  margin-top: 2rem;
  max-width: 30rem;
}
</style>

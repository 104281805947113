<template>
  <section class="container p-4">
    <header class="mb-6">
      <h1 class="font-bold leading-tight mb-4">Valitse nimimerkki</h1>
      <p>
        Nimimerkkisi erottaa sinut muista Sandislaisista ja se näkyy kaikille
        yhteisön käyttäjille.
      </p>
    </header>
    <div>
      <form @submit.stop.prevent="onNameSubmit">
        <label class="label is-medium" for="input-name">Nimimerkki</label>
        <div class="control">
          <input
            v-model="name"
            id="input-name"
            class="input is-medium"
            type="text"
            @input="changed ? () => {} : (changed = true)"
          />
          <small class="text-grey-dark leading-none">
            Nimimerkissä voi olla 4-16 kirjainta ja se saa sisältää aakkosia,
            numeroita tai merkit - ja _
          </small>
        </div>
        <div class="pt-4">
          <button
            type="submit"
            :disabled="!name || name.length < 4 || name.length > 16 || !changed"
            :class="['button button--teal', { 'is-loading': isLoading }]"
          >
            Tallenna nimimerkki
          </button>
        </div>
      </form>
      <div class="div edit-name__messages has-text-centered">
        <div v-if="isError" class="font-bold text-red">{{ error }}</div>
        <div v-if="isSuccess" class="font-bold text-green">
          Nimimerkki vaihdettu onnistuneesti!
        </div>
      </div>
      <br />
    </div>
  </section>
</template>

<script>
import api from '@/api';

export default {
  name: 'EditName',
  data() {
    return {
      changed: false,
      isError: false,
      error: '',
      isLoading: false,
      isSuccess: false,
      name: this.$store.state.user.name,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    async onNameSubmit() {
      this.isLoading = true;
      this.isError = false;
      this.isSuccess = false;
      this.changed = false;

      try {
        const name = this.name;
        await api.user.update({ name });
        await this.$store.dispatch('user/updateUser', { name });
        this.isLoading = false;
        this.isSuccess = true;
      } catch (err) {
        this.isLoading = false;
        this.isError = true;

        if (
          err.response &&
          err.response.data &&
          err.response.data.errors &&
          err.response.data.errors.name
        ) {
          console.log(err.response.data.errors.name);
          if (
            err.response.data.errors.name.indexOf('has already been taken') > -1
          ) {
            this.error = 'Valitsemasi nimimerkki on jo käytössä!';
          }

          if (
            err.response.data.errors.name.indexOf('has invalid format') > -1
          ) {
            this.error =
              'Nimimerkissä voi olla vain kirjaimia, numeroita tai merkit - ja _';
          }
        } else {
          this.error = 'Hups, jotain meni pieleen! Yritä myöhemmin uudestaan.';
        }
      }
    },
  },
};
</script>

<style lang="scss">
.edit-name__messages {
  margin-top: 1rem;
}
</style>

<template>
  <aside class="reviews w-full max-w-xs">
    <h3 class="font-bold mt-6 mb-4" v-if="reviews.length > 0">
      Sandislaisten kokemuksia paikasta
    </h3>
    <article
      v-for="review in reviews"
      :key="review.id"
      class="review rounded shadow p-4 mb-2 flex flex-col w-full bg-white"
    >
      <div class="flex items-center">
        <Avatar :userId="review.created_by.id" class="mr-2"></Avatar>
        <p>{{ review.created_by.name }}</p>
      </div>
      <div class="my-4">
        <p>{{ review.description }}</p>
      </div>
      <div class="flex items-center justify-between">
        <Rating class="review__rating" :rating="review.rating"></Rating>
        <i class="text-sm">{{ review.created_at | formatDate }}</i>
      </div>
    </article>
  </aside>
</template>

<script>
import { format } from 'date-fns';
import api from '../utils/api';
import Avatar from '../components/Avatar.vue';
import Rating from '../components/Rating.vue';

export default {
  name: 'Reviews',
  components: { Avatar, Rating },
  data() {
    return {
      isLoading: false,
      reviews: [],
    };
  },
  props: {
    locationId: String,
    showCreateReview: Function,
  },
  created() {
    const vm = this;
    vm.isLoading = true;

    api.reviews
      .get(vm.locationId)
      .then(({ data }) => {
        vm.reviews = data;
        vm.isLoading = false;
      })
      .catch((error) => console.log(error));
  },
  methods: {
    type(foo) {
      return typeof foo;
    },
  },
  filters: {
    formatDate(isoDate) {
      return format(isoDate, 'D.M.YYYY');
    },
  },
};
</script>

<style lang="scss">
.review {
  .user-avatar {
    font-size: 1rem;
  }
}
</style>

<template>
  <div class="bg-white rounded p-4">
    <h2 class="font-bold text-lg mb-6">Kommentoi kohdetta {{ itemName }}</h2>
    <textarea class="textarea" v-model="description"></textarea>
    <br />
    <div class="flex justify-end">
      <button
        :class="['button button--teal', { 'is-loading': isLoading }]"
        @click="onConfirmCommentClick"
      >
        Lisää kommentti
      </button>
    </div>
    <strong v-if="isError" class="comment-list-item__error has-text-danger">
      Hups, jotain meni pieleen!
    </strong>
  </div>
</template>

<script>
import api from '../utils/api';

export default {
  name: 'CommentListItem',
  props: {
    itemDescription: String,
    itemId: Number,
    itemName: String,
    listId: Number,
    onSuccess: Function,
  },
  data() {
    return {
      description: this.itemDescription,
      isError: false,
      isLoading: false,
    };
  },
  methods: {
    onConfirmCommentClick() {
      const vm = this;
      vm.isError = false;
      vm.isLoading = true;

      api.lists
        .updateItem({
          listId: this.listId,
          itemId: this.itemId,
          changes: { description: this.description },
        })
        .then(() => {
          vm.isLoading = false;
          vm.onSuccess();
        })
        .catch(() => {
          vm.isError = true;
          vm.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.comment-list-item__error {
  display: block;
  margin-top: 1.5rem;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:['hero list-hero', { 'has-overlay': !!_vm.backgroundImage }],style:({
    backgroundColor: _vm.colorOverride || _vm.colors[_vm.color],
    backgroundImage: _vm.backgroundImage ? ("url('" + _vm.backgroundImage + "')") : 'none',
  })},[_c('div',{staticClass:"hero-body list-hero__body"},[_c('header',{staticClass:"container container--narrow has-text-centered"},[_c('h2',{class:[
          'title is-size-2 is-size-3-mobile is-spaced',
          ("has-text-" + _vm.textColor) ]},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('p',{class:['list-hero__subtitle', ("has-text-" + _vm.textColor)]},[_vm._v(" "+_vm._s(_vm.description)+" ")])]),_c('div',{staticClass:"container list-hero__list"},[_c('swiper',{ref:"swiperInstance",attrs:{"options":_vm.swiperOptions}},_vm._l((_vm.sortedList),function(item){return _c('swiper-slide',{key:item.id},[_c('Card',{attrs:{"labelColor":_vm.categoryColors[item.category || item.type],"title":item.name,"subtitle":item.type === 'event' ? _vm.parseDate(item.startDate) : '',"body":item.short_description,"url":("/" + (item.type) + "/" + (item.id) + "/" + (item.slug))}},[_c('template',{slot:"footer"},[_c('div',{staticClass:"card-footer-item is-paddingless"},[_c('ButtonFavorite',{attrs:{"id":item.id,"label":("" + (item.favorites || 0)),"name":item.name,"startDate":item.startDate || undefined,"type":item.type}})],1)])],2)],1)}),1),_c('div',{class:[
          'list-shadow list-shadow--left',
          { 'list-shadow--visible': _vm.leftShadowVisible } ]}),_c('div',{class:[
          'list-shadow list-shadow--right',
          { 'list-shadow--visible': _vm.rightShadowVisible } ]})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
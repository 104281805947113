<template>
  <div class="p-8 bg-white footer">
    <div class="level flex flex-wrap w-full max-w-lg">
      <div class="flex-grow py-2 md:px-2 md:w-1/3">
        <div>
          <ul id="info">
            <li>
              <img
                src="/static/sandis-logo.png"
                alt="Sandis - tekemistä, hyötyä ja hupia lapsiperheille"
                class="w-24"
              />
            </li>
            <li>
              <p>
                Lapsuus koostuu yhteisistä hetkistä ja pienistä kokemuksista.
                Niiden vuoksi syntyi Sandis.
                <router-link to="/about">Lue lisää Sandiksesta</router-link>
              </p>
            </li>
            <li class="pt-4">
              <small>
                <span class="icon is-small"><i class="fa fa-heart"></i></span>
                :lla Playground Helsinki Oy
              </small>
            </li>
            <li class="pt-5">
              <router-link to="/collaboration">
                Sandis yrityksille ja yhteisöille
              </router-link>
            </li>
            <li class="pt-1">
              <router-link to="/terms">Palvelun käyttöehdot</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="flex-grow py-2 md:px-2 md:w-2/3">
        <div>
          <ul>
            <li>
              <h5 class="bold">Ota yhteyttä</h5>
            </li>
            <li class="mt-4">
              <a href="mailto:info@playgroundhelsinki.fi">
                info@playgroundhelsinki.fi
              </a>
            </li>
            <li>
              <span
                role="button"
                class="button button--primary--rose mt-4"
                @click="$store.dispatch('toggleFeedback')"
              >
                Anna palautetta
              </span>
            </li>
            <li class="mt-4"><h5 class="bold">Sandis muualla</h5></li>
            <li>
              <a
                href="https://www.facebook.com/sandiscommunity/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/sandis.co/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<template>
  <div class="card-list">
    <div class="flex flex-wrap card-list__collection is-multiline">
      <div
        v-for="item in itemsWithPromotions"
        :key="getThingId(item)"
        class="w-full md:w-1/2 p-2 card-list__item"
      >
        <Card
          v-if="!!item.name"
          :type="item.type"
          :hasImage="true"
          :image="getImage(item)"
          :imageBackgroundColor="
            categoryColors[item.type === 'event' ? 'event' : item.category]
          "
          :title="item.name"
          :subtitle="getSubtitle(item)"
          :body="item.short_description"
          :rating="item.type === 'location' ? item.average_rating : undefined"
          :url="getThingUrl(item)"
          :label="
            item.type === 'event'
              ? $t('categories.event')
              : $t('categories.' + item.category)
          "
        ></Card>
        <Card
          v-if="!item.name"
          header="Kaupallinen yhteistyö"
          :title="item.title.rendered"
          :subtitle="`Yhteistyössä ${item.acf.partner}`"
          :hasImage="true"
          :image="item.acf.image.sizes.medium"
          :body="item.acf.description"
          :url="item.acf.click_tag"
          urlIsExternal
          @click.native="() => sendClickEvent(item.title.rendered)"
        >
          <template slot="body-after">
            <img
              :src="getRandomTag(item.acf.image_tag)"
              border="0"
              height="1"
              width="1"
            />
            <img :src="item.acf.pixel_url" border="0" height="1" width="1" />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import { format, isSameDay } from 'date-fns';
import Card from '../components/Card.vue';
import {
  categoryColors,
  categoryPatterns,
  getThingId,
  getThingUrl,
} from '../utils/utils';
import cms from '../utils/cms';

export default {
  name: 'CardList',
  data() {
    return {
      categoryColors,
      categoryPatterns,
      promotions: [],
    };
  },
  components: {
    Card,
  },
  props: {
    items: Array,
  },
  computed: {
    itemsWithPromotions() {
      const INDEX = 5;
      const itemsArr = Object.keys(this.items).map((item) => this.items[item]);

      if (itemsArr.length > 3 && this.promotions.length > 0) {
        return [
          ...itemsArr.slice(0, INDEX),
          ...this.promotions,
          ...itemsArr.slice(INDEX),
        ];
      } else {
        return itemsArr;
      }
    },
  },
  created() {
    this.fetchPromotions();
  },
  methods: {
    getThingId,
    getThingUrl,
    getRandomTag(url) {
      return url.replace('$RANDOM', Math.floor(Math.random() * 10000000));
    },
    getImage(item) {
      if (item.images.length > 0) {
        return item.images[0].sizes.small;
      } else {
        return `/static/patterns/${
          categoryPatterns[item.type === 'event' ? 'event' : item.category]
        }`;
      }
    },
    getSubtitle(item) {
      if (item.type === 'event') {
        if (isSameDay(item.start_date, item.end_date)) {
          return format(item.start_date, 'D.M.YYYY HH:mm');
        } else {
          return `${format(item.start_date, 'D.M.')} - ${format(
            item.end_date,
            'D.M.YYYY'
          )}`;
        }
      } else {
        return '';
      }
    },
    fetchPromotions() {
      cms.getPromotions().then((data) => {
        this.promotions = data;
        data.forEach((promotion) => {
          this.$ga.event('Ads', 'view', promotion.title.rendered);
        });
      });
    },
    parseDate(isoDate) {
      return format(isoDate, 'D.M.YYYY HH:mm');
    },
    sendClickEvent(title) {
      this.$ga.event('Ads', 'click', title);
    },
  },
};
</script>

<style lang="scss">
@import '../scss/variables';

.card-list {
}

.card-list__title {
  font-size: 1.25rem;

  @media (max-width: $bp-mobile) {
    font-size: 1rem !important;
  }
}

.card-list__item {
  display: flex;

  .card {
    width: 100%;
  }
}

.card-list--compact {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;

  .card-list__collection {
    align-items: stretch;
  }

  .card-list__item {
    width: 45% !important;

    @media (max-width: $bp-mobile) {
      width: 80% !important;
    }
  }
}
</style>

const categories = {
  location: {
    activity: {
      subcategories: ['playground'],
    },
    restaurant: {
      subcategories: [],
    },
    service: {
      subcategories: ['flea_market', 'fashion', 'health', 'toys'],
    },
  },
  event: {
    culture: {
      subcategories: [],
    },
    sports: {
      subcategories: [],
    },
    other: {
      subcategories: [],
    },
  },
};

export default categories;

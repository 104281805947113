import axios from 'axios';
import auth from '../utils/auth';
// Endpoints
import activity from './activity';
import checkins from './checkins';
import things from './things';
import user from './user';
import search from './search';
const { VUE_APP_BACKEND_URL } = process.env;

const apiClient = axios.create({
  baseURL: VUE_APP_BACKEND_URL,
});

apiClient.interceptors.request.use((config) => {
  return auth
    .getApiToken()
    .then((token) => {
      return Object.assign({}, config, { headers: { Authorization: token } });
    })
    .catch(() => config);
});

export const getBaseUrl = () => VUE_APP_BACKEND_URL;

export default {
  activity: activity(apiClient),
  checkins: checkins(apiClient),
  things: things(apiClient),
  user: user(apiClient),
  search: search(apiClient),
};

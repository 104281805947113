<template>
  <div class="campaign">
    <article>
      <div
        class="campaign__hero is-medium has-overlay"
        :style="{ 'background-image': `url(${cmsData.heroImage})` }"
      >
        <div class="container p-6">
          <div class="w-full max-w-md">
            <header class="campaign__header">
              <h1 class="font-bold text-5xl leading-none text-white">
                {{ cmsData.title }}
              </h1>
              <p class="campaign__description text-white">
                {{ cmsData.description }}
              </p>
              <br />
              <div>
                <div
                  class="inline-block p-1"
                  v-for="link in cmsData.links"
                  :key="link.url"
                >
                  <a
                    target="_blank"
                    class="button button--primary--rose"
                    :href="link.url"
                  >
                    {{ link.description }}
                  </a>
                </div>
              </div>
            </header>
          </div>
          <div class="campaign__details">
            <button
              v-if="!isLoggedIn && isAvailable"
              @click="onLoginClick"
              class="button button--primary--blue"
            >
              Kirjaudu sisään ja osallistu arvontaan
            </button>

            <div v-if="isLoggedIn && isAvailable" class="bg-white rounded p-4">
              <h2 class="font-bold text-lg mb-6">Osallistu kilpailuun</h2>
              <p>{{ cmsData.prizeDescription }}</p>
              <br />
              <p>
                Kilpailu päättyy
                <strong>{{ formattedEndDate }}</strong>
                , jonka jälkeen otamme yhteyttä voittajaan.
              </p>
              <p>
                Osallistumiseen vaaditaan
                <strong>{{ apiData.cost }}</strong>
                Sandis-pistettä. Sinulla on
                <strong
                  :class="[
                    isValid >= 0 ? 'has-text-primary' : 'has-text-danger',
                  ]"
                >
                  {{ points }}
                </strong>
                pistettä.
              </p>
              <br />
              <button
                @click="onEnterClick"
                v-if="!entryExists"
                :disabled="!isValid"
                :class="[
                  'button button--primary--blue',
                  { 'is-loading': isLoading },
                ]"
              >
                Osallistu arvontaan (-{{ apiData.cost }} pistettä)
              </button>
              <strong v-if="entryExists" class="has-text-primary">
                Mahtavaa! Olet mukana arvonnassa.
              </strong>
            </div>

            <div v-if="!isAvailable" class="box">
              <p>
                Kilpailu on päättynyt, ilmoitamme voittajille
                henkilökohtaisesti.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="hero">
        <div class="hero-body container">
          <div class="flex flex-wrap">
            <div class="w-full md:w-1/2 p-4">
              <h2 class="font-bold text-lg mb-6">{{ cmsData.detailsTitle }}</h2>
              <p class="campaign__about">{{ cmsData.details }}</p>
            </div>
            <div class="w-full md:w-1/2 p-4">
              <h2 class="font-bold text-lg mb-6">Tietoa arvonnasta</h2>
              <p>
                Osallistuminen arvontaan vaatii {{ apiData.cost }} pistettä.
              </p>
              <p>
                Arvonta suoritetaan kampanja-ajan päätyttyä
                {{ formattedEndDate }}. Voittaja valitaan satunnaisesti kaikkien
                osallistuneiden kesken. Voittajalle ilmoitetaan voitosta
                henkilökohtaisesti sähköpostitse, joten ilmoitathan toimivan
                sähköpostiosoitteen rekisteröityessä Sandis-palveluun.
              </p>
              <br />
              <button class="button is-link" @click="termsVisible = true">
                Tarkemmat säännöt
              </button>
            </div>
          </div>
        </div>
      </div>
    </article>
    <aside><AboutGreeting></AboutGreeting></aside>
    <aside class="campaign__features section container">
      <AboutFeatures></AboutFeatures>
    </aside>
    <aside>
      <Modal
        :isOpen="true"
        v-show="termsVisible"
        :onCloseClick="onTermsCloseClick"
      >
        <div class="bg-white rounded shadow p-4">
          <p class="campaign__terms">{{ cmsData.terms }}</p>
        </div>
      </Modal>
    </aside>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import api from '@/utils/api';
import cms from '@/utils/cms';
import { setMeta } from '@/utils/utils';
import AboutGreeting from '@/components/AboutGreeting';
import AboutFeatures from '@/components/AboutFeatures';
import Modal from '@/components/Modal';
import { format, isPast } from 'date-fns';

export default {
  name: 'Campaign',
  components: {
    AboutGreeting,
    AboutFeatures,
    Modal,
  },
  data() {
    return {
      apiData: {
        cost: undefined,
        end_date: undefined,
        id: undefined,
        start_date: undefined,
      },
      cmsData: {
        description: undefined,
        details: undefined,
        detailsTitle: undefined,
        fbImage: undefined,
        heroImage: undefined,
        id: undefined,
        links: undefined,
        prizeDescription: undefined,
        slug: undefined,
        terms: undefined,
        title: undefined,
      },
      isLoading: false,
      entryExists: undefined,
      termsVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn',
    }),
    isValid() {
      return this.points - this.apiData.cost >= 0;
    },
    points() {
      return this.$store.state.user.points.currentPoints;
    },
    isAvailable() {
      return !isPast(this.apiData.endDate);
    },
    formattedEndDate() {
      return format(this.apiData.end_date, 'D.M.YYYY');
    },
  },
  beforeRouteEnter(to, from, next) {
    // Handles back button logic
    window.scrollTo(0, 0);
    next();
  },
  created() {
    const id = this.$route.params.id;

    const promises = [api.campaigns.get({ id }), cms.getCampaign({ id })];

    Promise.all(promises).then((responses) => {
      this.apiData = responses[0].data;

      const cmsResponse = responses[1][0];

      this.cmsData = {
        ...this.cmsData,
        description: cmsResponse.acf.description,
        details: cmsResponse.acf.details,
        detailsTitle: cmsResponse.acf.details_title,
        fbImage: cmsResponse.acf.hero_image.sizes.social_share,
        heroImage: cmsResponse.acf.hero_image.sizes.large,
        id: cmsResponse.acf.api_id,
        links: cmsResponse.acf.links,
        prizeDescription: cmsResponse.acf.prize_description,
        slug: cmsResponse.slug,
        terms: cmsResponse.acf.terms,
        title: cmsResponse.title.rendered,
      };

      const params = { ...this.$route.params, slug: cmsResponse.slug };
      this.$router.replace({ params });
    });

    if (this.isLoggedIn) this.fetchEntry();
  },
  methods: {
    onTermsCloseClick() {
      this.termsVisible = false;
    },
    fetchEntry() {
      this.isLoading = true;

      return api.campaigns
        .getEntry({ id: this.$route.params.id })
        .then(({ data }) => {
          this.isLoading = false;
          this.entryExists = data.entry_exists;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log('error fetching entry', error.response);
        });
    },
    onEnterClick() {
      this.isLoading = true;

      api.campaigns
        .postEntry({ id: this.$route.params.id })
        .then(() => {
          this.entryExists = true;
          this.$store.dispatch('updatePoints', { change: this.apiData.cost });
          this.isLoading = false;
        })
        .catch((error) => {
          this.isError = true;
          this.isLoading = false;
          console.log(error);
        });
    },
    onLoginClick() {
      this.$store.dispatch('toggleLogin', { visible: true });
    },
  },
  metaInfo() {
    return {
      title: this.cmsData.title,
      meta: [
        ...setMeta({
          title: this.cmsData.title,
          description: this.cmsData.description,
          url: `campaign/${this.cmsData.id}/${this.cmsData.slug}`,
          ogImage: this.cmsData.fbImage,
          ogImageWidth: '1200',
          ogImageHeight: '630',
        }),
      ],
    };
  },
  watch: {
    isLoggedIn(newValue) {
      if (newValue === true) this.fetchEntry();
    },
  },
};
</script>

<style lang="scss">
.campaign__hero {
  background-size: cover;
  background-position: center center;
}

.campaign__header {
  margin-bottom: 2rem;
}

.campaign__details {
  max-width: 40rem;
}

.campaign__description,
.campaign__about,
.campaign__terms {
  white-space: pre-line;
}
</style>

const activity = (api) => ({
  get() {
    return api.get('/activity');
  },
  getTopUsers() {
    return api.get('/activity/top_users');
  },
});

export default activity;

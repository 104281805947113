<template>
  <section
    :class="['hero list-hero', { 'has-overlay': !!backgroundImage }]"
    :style="{
      backgroundColor: colorOverride || colors[color],
      backgroundImage: backgroundImage ? `url('${backgroundImage}')` : 'none',
    }"
  >
    <div class="hero-body list-hero__body">
      <header class="container container--narrow has-text-centered">
        <h2
          :class="[
            'title is-size-2 is-size-3-mobile is-spaced',
            `has-text-${textColor}`,
          ]"
        >
          {{ name }}
        </h2>
        <p :class="['list-hero__subtitle', `has-text-${textColor}`]">
          {{ description }}
        </p>
      </header>
      <div class="container list-hero__list">
        <swiper :options="swiperOptions" ref="swiperInstance">
          <swiper-slide v-for="item in sortedList" :key="item.id">
            <Card
              :labelColor="categoryColors[item.category || item.type]"
              :title="item.name"
              :subtitle="item.type === 'event' ? parseDate(item.startDate) : ''"
              :body="item.short_description"
              :url="`/${item.type}/${item.id}/${item.slug}`"
            >
              <template slot="footer">
                <div class="card-footer-item is-paddingless">
                  <ButtonFavorite
                    :id="item.id"
                    :label="`${item.favorites || 0}`"
                    :name="item.name"
                    :startDate="item.startDate || undefined"
                    :type="item.type"
                  ></ButtonFavorite>
                </div>
              </template>
            </Card>
          </swiper-slide>
        </swiper>
        <div
          :class="[
            'list-shadow list-shadow--left',
            { 'list-shadow--visible': leftShadowVisible },
          ]"
        ></div>
        <div
          :class="[
            'list-shadow list-shadow--right',
            { 'list-shadow--visible': rightShadowVisible },
          ]"
        ></div>
      </div>
    </div>
  </section>
</template>

<script>
import ButtonFavorite from '../components/ButtonFavorite.vue';
import Card from '../components/Card.vue';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import api from '../utils/api';
import { categoryColors, categoryPatterns, colors } from '../utils/utils';
import { prop, sortBy } from 'ramda';
import { format } from 'date-fns';

export default {
  props: {
    backgroundImage: String,
    colorOverride: String,
    id: String,
    textColor: { type: String, default: 'black' },
  },
  data() {
    return {
      color: 0,
      categoryColors,
      categoryPatterns,
      colors,
      name: '',
      description: '',
      items: [],
      fetchedItems: [],
      itemsFetched: false,
      leftShadowVisible: false,
      rightShadowVisible: true,
      swiperOptions: {
        slidesPerView: 4.25,
        spaceBetween: 16,
        breakpoints: {
          1400: { slidesPerView: 3.25 },
          900: { slidesPerView: 2.25, centeredSlides: false },
          680: { slidesPerView: 1.2, centeredSlides: true },
        },
      },
    };
  },
  components: { ButtonFavorite, Card, swiper, swiperSlide },
  computed: {
    sortedList() {
      return sortBy(prop('startDate'), this.fetchedItems);
    },
  },
  created() {
    const vm = this;
    api.lists
      .get({ id: this.id })
      .then(({ data }) => {
        const { name, color, description, author, items } = data;

        const firstItems = items; // items.slice(0, 4)

        Object.assign(this, {
          name,
          color,
          description,
          author,
          items: firstItems,
        });

        api.lists.getItems(firstItems).then((results) => {
          vm.fetchedItems = results.map((result) => {
            const item = result.data;
            return item;
          });
        });
      })
      .catch((error) => console.log(error));
  },
  mounted() {
    const swiperInstance = this.$refs.swiperInstance.swiper;
    swiperInstance.on('slideChange', this.checkShadows);
  },
  methods: {
    checkShadows() {
      const swiperInstance = this.$refs.swiperInstance.swiper;
      this.leftShadowVisible = swiperInstance.activeIndex !== 0;
      this.rightShadowVisible = !swiperInstance.isEnd;
    },
    parseDate(isoDate) {
      return format(isoDate, 'D.M.YYYY');
    },
  },
};
</script>

<style src="swiper/dist/css/swiper.css"></style>
<style lang="scss">
@import '../scss/variables';

.list-hero {
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.list-hero__body {
  @media (max-width: $bp-mobile) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.list-shadow {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2rem;
  z-index: 2;
  opacity: 0;

  &--visible {
    opacity: 1;
  }

  &--left {
    left: 0;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.25),
      rgba(0, 0, 0, 0) 20%
    );
  }

  &--right {
    right: 0;
    background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.25),
      rgba(0, 0, 0, 0) 20%
    );
  }
}

.list-hero__list {
  position: relative;
  width: 100%;
  padding: 0.25rem;
  margin-top: 2.5rem;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow: hidden;
}

.list-hero__subtitle {
  line-height: 1.5;
}

.list-hero__footer {
  padding: 0.5rem;
}

.swiper-container {
  overflow: visible !important;
}

.swiper-wrapper,
.swiper-slide {
  height: auto !important;
}

.swiper-slide {
  display: flex;
}
</style>

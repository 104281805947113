<template>
  <div class="registration">
    <div class="flex min-h-screen overflow-hidden">
      <div class="bg-white background-shapes flex-grow"></div>
      <div class="w-full max-w-lg shadow p-4">
        <div class="flex flex-col items-center">
          <h1 class="font-bold text-2xl text-center my-6">
            Tervetuloa mukaan!
          </h1>
        </div>
        <!-- Sign up -->
        <div class="flex flex-col items-center" v-if="!success">
          <div>
            <a :href="fbUrl" class="button button--primary--blue">
              Rekisteröidy Facebook-tunnuksella
            </a>
          </div>

          <!-- Divider -->
          <h4 class="text-center uppercase mt-8">tai</h4>
          <p class="mb-5">
            Luo itsellesi Sandikseen omat tunnukset ja nauti hyödyistä
          </p>

          <!-- Form -->
          <div class="w-full max-w-sm">
            <!-- Username -->
            <div class="flex flex-wrap w-full mb-6">
              <div class="w-full px-3">
                <label for="username" class="label">Käyttäjätunnus</label>
                <input
                  type="text"
                  id="username"
                  :class="['input', usernameInUse ? 'input--invalid' : '']"
                  placeholder="Käyttäjätunnus"
                  v-model.trim="username"
                />
                <p class="form-hint text-red" v-if="usernameInUse">
                  Valitsemasi käyttäjätunnus on jo käytössä.
                </p>
              </div>
            </div>

            <!-- Email -->
            <div class="flex flex-wrap w-full mb-6">
              <div class="w-full px-3">
                <label for="email" class="label">Sähköpostiosoite</label>
                <input
                  type="email"
                  id="email"
                  class="input"
                  placeholder="Sähköpostiosoite"
                  v-model.trim="email"
                />
                <p class="form-hint text-red" v-if="!$v.email.email">
                  Tarkista sähköpostiosoite
                </p>
              </div>
            </div>

            <!-- Password -->
            <div class="flex flex-col w-full mb-6">
              <div class="flex">
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label for="password" class="label">Salasana</label>
                  <input
                    type="password"
                    class="input"
                    placeholder="Salasana"
                    v-model.trim="password"
                  />
                </div>
                <div class="w-full md:w-1/2 px-3">
                  <label for="password-verification" class="label">
                    Salasana uudestaan
                  </label>
                  <input
                    type="password"
                    id="password-verification"
                    class="input"
                    placeholder="Salasana"
                    v-model.trim="passwordVerification"
                  />
                </div>
              </div>
              <div class="px-3">
                <p class="form-hint text-red" v-if="!$v.password.minLength">
                  Salasanan täytyy olla vähintään 8 merkkiä pitkä.
                </p>
                <p
                  class="form-hint text-red"
                  v-if="!$v.passwordVerification.sameAsPassword"
                >
                  Salasanat eivät täsmää.
                </p>
              </div>
            </div>

            <!-- Submit -->
            <div class="flex flex-wrap w-full px-3 mb-6">
              <button
                class="button button--primary--rose w-full"
                :disabled="!inputsValid"
                @click="onRegisterClick"
              >
                Rekisteröidy
              </button>
            </div>
          </div>
        </div>
        <!-- Success -->
        <div class="flex flex-col items-center" v-if="success">
          <div class="w-full max-w-sm">
            <h2 class="text-3xl font-bold leading-tight my-6">
              Rekisteröityminen onnistui, mahtavaa!
            </h2>
            <p>
              Sinun täytyy vielä vahvistaa käyttäjätunnuksesi, saat
              sähköpostiisi ({{ email }}) linkin jota klikkaamalla
              käyttäjätunnuksesi aktivoituu.
            </p>
          </div>
          <span class="text-5xl mt-8">🎉</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  CognitoUserPool,
  CognitoUserAttribute,
} from 'amazon-cognito-identity-js';
import { required, minLength, sameAs, email } from 'vuelidate/lib/validators';
const { VUE_APP_COGNITO_USER_POOL, VUE_APP_COGNITO_APP_ID } = process.env;

export default {
  data() {
    return {
      // For registration
      email: '',
      username: '',
      password: '',
      passwordVerification: '',
      // For verification
      verificationUser: '',
      verificationCode: '',
      // View state
      success: false,
      usernameInUse: false,
    };
  },
  computed: {
    fbUrl() {
      return encodeURI(
        'https://sandis-users.auth.eu-west-1.amazoncognito.com/authorize' +
          '?identity_provider=Facebook' +
          '&response_type=token' +
          '&redirect_uri=https://www.sandis.co/fbauth' +
          `&client_id=${VUE_APP_COGNITO_APP_ID}` +
          '&scope=openid email aws.cognito.signin.user.admin profile' +
          '&state=/'
      );
    },
    inputsValid() {
      return (
        this.email.length > 0 &&
        this.username.length > 0 &&
        this.password.length > 0 &&
        this.password === this.passwordVerification
      );
    },
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      minLength: minLength(8),
    },
    passwordVerification: {
      sameAsPassword: sameAs('password'),
    },
  },
  methods: {
    onRegisterClick() {
      const userPool = new CognitoUserPool({
        UserPoolId: VUE_APP_COGNITO_USER_POOL,
        ClientId: VUE_APP_COGNITO_APP_ID,
      });

      const emailAttribute = new CognitoUserAttribute({
        Name: 'email',
        Value: this.email,
      });

      userPool.signUp(
        this.username,
        this.password,
        [emailAttribute],
        null,
        (error) => {
          if (error) {
            if (error.code === 'UsernameExistsException')
              this.usernameInUse = true;
          } else {
            this.success = true;
          }
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <article class="campaign-hero">
    <div class="hero-body container">
      <div class="columns">
        <header class="campaign-hero__header column is-half">
          <h2 class="campaign-hero__title title is-size-1 is-size-4-mobile">
            {{ campaign.title }}
          </h2>
          <p class="is-size-5 is-size-6-mobile">{{ campaign.details }}</p>
        </header>
        <div class="campaign-hero__card-container column is-half">
          <Card
            class="campaign-hero__card"
            :hasImage="true"
            :image="campaign.heroImage"
          >
            <template slot="body-after">
              <router-link
                :to="`campaign/${campaign.id}`"
                class="button is-black is-outlined is-fullwidth"
              >
                Osallistu arvontaan
              </router-link>
            </template>
          </Card>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import Card from '@/components/Card.vue';
import api from '@/utils/api';

export default {
  name: 'CampaignHero',
  components: { Card },
  props: {
    id: String,
  },
  data() {
    return {
      campaign: {},
    };
  },
  created() {
    api.campaigns.get({ id: this.id }).then(({ data }) => {
      this.campaign = data;
    });
  },
};
</script>

<style lang="scss">
@import '../scss/variables';

.campaign-hero {
  background-color: lighten($color-jungle, 50%);
}

.campaign-hero__header {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.campaign-hero__card-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.campaign-hero__card {
  max-width: 20rem;
}
</style>

<template>
  <article class="activity-feed w-full max-w-xs rounded shadow bg-white">
    <div class="card-tabs">
      <div
        role="button"
        :class="[
          'card-tabs-item',
          { 'card-tabs-item--active': activeTab === 'things' },
        ]"
        @click="() => (activeTab = 'things')"
      >
        Juuri lisättyä
      </div>
      <div
        role="button"
        :class="[
          'card-tabs-item',
          { 'card-tabs-item--active': activeTab === 'reviews' },
        ]"
        @click="() => (activeTab = 'reviews')"
      >
        Kokemukset
      </div>
    </div>
    <div
      v-for="item in items"
      :key="`${item.type}-${item.id}`"
      class="activity-item"
    >
      <div class="flex justify-between">
        <div class="text-xs">
          <strong class="text-grey">{{ getType(item) }}</strong>
        </div>
        <div class="text-xs text-grey">{{ formatDate(item.created_at) }}</div>
      </div>
      <router-link :to="getUrl(item)" class="text-black">
        <strong class="block mb-2">{{ getTitle(item) }}</strong>
      </router-link>
      <div class="text-xs italic mb-2">{{ getDescription(item) }}</div>
      <div v-if="item.type === 'review'" class="mb-2">
        <Rating :rating="item.rating" size="small" />
      </div>
      <div class="flex items-center">
        <Avatar :userId="item.created_by.id" />
        <small class="ml-2">{{ item.created_by.name }}</small>
      </div>
    </div>
  </article>
</template>

<script>
import { format, isToday } from 'date-fns';
import Avatar from '@/components/Avatar';
import Rating from '@/components/Rating';
import api from '@/api';

export default {
  name: 'ActivityFeed',
  components: {
    Avatar,
    Rating,
  },
  data() {
    return {
      activeTab: 'things',
      reviews: [],
      things: [],
    };
  },
  computed: {
    items() {
      return this[this.activeTab];
    },
  },
  created() {
    this.fetchFeed();
  },
  methods: {
    getDescription(item) {
      const types = {
        event: () => item.short_description,
        location: () => item.short_description,
        review: () => item.description,
      };

      return types[item.type]();
    },
    getTitle(item) {
      const types = {
        event: () => item.name,
        location: () => item.name,
        review: () => item.location.name,
      };

      return types[item.type]();
    },
    getType(item) {
      const types = {
        event: 'Tapahtuma',
        location: 'Paikka',
        review: 'Arvostelu',
      };

      return types[item.type];
    },
    getUrl(item) {
      const types = {
        event: () => `/event/${item.id}/${item.slug}`,
        location: () => `/location/${item.id}/${item.slug}`,
        review: () => `/location/${item.location.id}/${item.location.slug}`,
      };

      return types[item.type]();
    },
    async fetchFeed() {
      try {
        const response = await api.activity.get();
        this.reviews = response.data.reviews.slice(0, 5);
        this.things = response.data.things.slice(0, 5);
      } catch {
        // Error
      }
    },
    formatDate(isoDate) {
      const date = new Date(isoDate);
      const dateFormat = isToday(date) ? 'HH:mm' : 'DD.MM.';

      return format(date, dateFormat);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../scss/variables';

.activity-item {
  @apply p-4 mx-1 border-b border-grey-lighter;
}

.card-tabs {
  display: flex;
  border-bottom: 1px solid $color-primary-grey;
}

.card-tabs-item {
  color: $color-primary-black;
  padding: 0.75rem 1rem;
  font-weight: bold;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  flex: 1;
  text-align: center;

  & + & {
    border-left: 1px solid $color-primary-grey;
  }

  &--active {
    color: $color-primary-blue;
  }
}
</style>

<template>
  <div class="banner-loader">
    <slot :body="body" :title="title" :url="url"></slot>
  </div>
</template>

<script>
import api from '../utils/api';

export default {
  name: 'BannerLoader',
  props: {
    id: String,
    type: String,
  },
  data() {
    return {
      body: '',
      title: '',
    };
  },
  computed: {
    url() {
      return `/${this.type}/${this.id}`;
    },
  },
  created() {
    const types = {
      list: (id) => this.getList(id),
    };

    types[this.type](this.id);
  },
  methods: {
    getList(id) {
      const vm = this;

      api.lists
        .get({ id })
        .then(({ data }) => {
          vm.title = data.name;
          vm.body = data.description;
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>

<style lang="scss">
.banner-loader {
  display: flex;
}
</style>

<template>
  <section v-if="item" class="w-full">
    <Card
      :backgroundColor="cmsData.bgcolor"
      :hasShadow="false"
      :label="cmsData.label"
      :image="cmsData.bgimage.sizes.medium"
      :title="cmsData.title || item.name"
      :body="cmsData.description || item.shortDescription || item.description"
    >
      <template slot="body-after">
        <br />
        <a
          :href="cmsData.url || `/${cmsData.type}/${cmsData.id}`"
          class="button"
        >
          <span>Lue lisää</span>
        </a>
      </template>
    </Card>
  </section>
</template>

<script>
import Card from '@/components/Card';
import api from '@/utils/api';

export default {
  name: 'BlockFullwidth',
  components: { Card },
  props: {
    cmsData: Object,
  },
  data() {
    return {
      item: null,
    };
  },
  computed: {
    hasBgImage() {
      return this.cmsData.bgimage && this.cmsData.bgimage.sizes;
    },
  },
  created() {
    if (this.cmsData.type === 'static') {
      this.item = {};
    } else {
      this.fetchData();
    }
  },
  methods: {
    async fetchData() {
      const vm = this;
      const id =
        vm.cmsData.type === 'list' ? { id: this.cmsData.id } : this.cmsData.id;

      try {
        const { data } = await api[`${this.cmsData.type}s`].get(id);
        vm.item = data;
      } catch {
        vm.item = null;
      }
    },
  },
};
</script>

<style lang="scss"></style>

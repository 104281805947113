<template>
  <section class="container mb-5 pt-5">
    <article class="p-4 bg-white">
      <h1 class="font-bold mb-4">Käyttöehdot</h1>
      <p class="mb-2">
        Tervetuloa käyttämään Sandis-palvelua! Hienoa, että palvelun käyttöehdot
        kiinnostavat, sillä ne sisältävät sinulle tärkeää tietoa muun muassa
        tekijänoikeuksista ja siitä, miten käytämme sinun dataasi.
      </p>
      <p class="mb-2">
        Sandiksen tarkoitus on tarjota tietoja ja tekemistä, joiden avulla
        lapsiperheen elämästä tulee hieman helpompaa ja hauskempaa yhdessä.
        Mahdollistamme myös mielipiteiden julkaisun ja keskusten käymisen
        vanhemmuuteen ja lapsiperheiden elämäntilanteesein liittyvistä aiheista,
        niin valtakunnallisesti kuin lähialueen tasolla. Palvelu on suunniteltu
        käytettäväksi verkkosivustona tai mobiilisovelluksena.
      </p>
      <p class="mb-2">
        Sandis- palvelu (myöhemmin ”Palvelu”,”Sandis”) on Playground Helsinki
        Oyn omistama. Tekijänoikeudet (tekstit, kuvat, grafiikat yms.) kuuluvat
        Playground Helsinki Oy:lle (y-tunnus 2785641-5).
      </p>
      <p class="mb-2">
        Sandista voi käyttää ja selailla ilman rekisteröintiä, mutta jotkin
        toiminnallisuudet vaativat rekisteröinnin. Rekisteröimällä itsesi
        Sandikseen vakuutat, että antamasi tiedot ovat totta.
      </p>
      <p class="mb-2">
        Palvelussa on keskustelualueita, ilmoituksen jättämismahdollisuuksia ja
        muita foorumeita, joiden kautta sinä tai muut voivat lähettää viestejä,
        kommentteja tai muuta materiaalia. Näiden alueiden käyttö on käyttäjän
        omalla vastuulla, eli käytäthän hyvää etikettiä sekä noudatat Suomen
        lakia lisätessäsi palveluun sisältöä.
      </p>
      <p class="mb-2">
        Sandis ei ole vastuussa eikä korvausvelvollinen virheellisen tai
        laittoman kolmannen osapuolen lisäämän sisällön johdosta. Sandis ei
        vastaa palveluun syötettyjen tietojen oikeellisuudesta tai
        ajantasaisuudesta, eikä niiden www-sivujen sisällöstä tai
        lainmukaisuudesta, joihin palvelusta on linkkejä.
      </p>
      <p class="mb-2">
        Sandis pidättää oikeuden poistaa mitä tahansa kolmannen osapuolen
        viestejä oman harkintansa mukaan. Pyrimme kuitenkin päivittämään ja
        poistamaan epäasiallisia tai virheellisiä tietoja, jotka palveluun on
        syötetty.
      </p>

      <p class="mb-2">
        Kaikki oikeudet käyttäjän Sandis palveluun lataamaan sisältöön jäävät
        käyttäjälle, mutta käyttäjä myöntää Playground Helsingille oikeuden
        valmistaa, muokata ja hyödyntää sisältöä palvelun parantamiseen ja
        palvelun markkinointiin korvauksetta. Käyttäjä vastaa siitä, että
        ladatulla materiaalilla on siihen tarvittavat oikeudet ja luvat.
      </p>

      <p class="mb-2">
        Sandiksella on oikeus muuttaa Palvelun käyttöehtoja sekä mahdollisia
        muita Palvelun yhteydessä ilmoitettuja sääntöjä ja ohjeita. Ehtojen
        muutoksista ilmoitetaan Sandiksessa tai muulla vastaavalla tavalla.
        Muutokset astuvat voimaan ilmoitettuna ajankohtana.
      </p>
      <p class="mb-2">
        Palveluun rekisteröidyillä on mahdollisuus saada palvelun käyttämisestä
        pisteitä. Palvelu pidättää oikeuden muuttaa pisteiden palkitsemistapoja
        ja niiden sääntöjä, sekä oikeuden muuttaa, lisätä tai poistaa käyttäjän
        ansaitsemia pisteitä.
      </p>

      <h2 class="my-4">Rekisteriseloste</h2>
      <p class="mb-3">Henkilötietolaki (523/1999) 10 §</p>
      <ul>
        <li class="mb-1">
          Rekisterinpitäjä: Playground Helsinki Oy, osoite: Varpustie 3a C 00780
          Helsinki sähköposti: lasse@playgroundhelsinki.fi
        </li>
        <li class="mb-1">
          Yhteyshenkilö rekisteriä koskevissa asioissa: Lasse Iskanius
        </li>
        <li class="mb-1">Rekisterin nimi: käyttäjärekisteri</li>
        <li class="mb-1">
          Henkilötietojen käsittelyn tarkoitus: Rekisteri on perustettu
        </li>
        <li class="mb-1">
          Rekisterin tietosisältö: Rekisteri voi sisältää seuraavia tietoja:
          nimi, sähköpostiosoite.
        </li>
        <li class="mb-1">Säännönmukaiset tietolähteet:</li>
        <li class="mb-1">
          Tietojen säännönmukaiset luovutukset: Tietoja ei luovuteta
          säännönmukaisesti yrityksen ulkopuolelle.
        </li>
        <li class="mb-1">
          Tietojen siirto EU:n tai ETA:n ulkopuolelle: Tietoja ei siirretä EU:n
          tai ETA:n ulkopuolelle.
        </li>
        <li class="mb-1">
          Rekisterin suojauksen periaatteet: Tiedot suojataan tietoteknisin
          menetelmin organisaation ulkopuolisilta. Tietoihin on pääsy vain
          Playground Oyn henkilöstöllä
        </li>
      </ul>
      <h2 class="my-4">Käyttäjätietojen poistaminen</h2>
      <p>
        Ota yhteyttä sähköpostitse osoitteeseen
        <a href="mailto:info@playgroundhelsinki.fi">
          info@playgroundhelsinki.fi
        </a>
        jos toivot että käyttäjätietosi poistetaan käyttäjärekisteristämme.
      </p>
    </article>
  </section>
</template>

<script>
export default {
  name: 'Terms',
};
</script>

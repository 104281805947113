<template>
  <div v-if="!isNaN(rating)" class="is-clearfix">
    <span
      v-for="i in Math.floor(rating)"
      :key="`${i}-rating`"
      :class="['icon text-yellow is-pulled-left', `is-${size}`]"
    >
      <i class="fa fa-star"></i>
    </span>
    <span
      v-for="i in 5 - Math.floor(rating)"
      :key="`${i}-max`"
      :class="['icon text-grey-light is-pulled-left', `is-${size}`]"
    >
      <i class="fa fa-star"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Rating',
  props: {
    rating: Number,
    size: { type: String, default: 'normal' },
  },
};
</script>

<template>
  <header :class="['header', { 'header--fixed': $store.state.search.visible }]">
    <!-- Left -->
    <div class="hidden sm:block px-4">
      <router-link
        v-if="!isLoggedIn"
        to="/register"
        class="text-blue-darker font-bold"
      >
        Liity mukaan
      </router-link>
    </div>
    <!-- Center -->
    <router-link to="/" class="flex items-center px-4">
      <img
        src="/static/sandis-logo.png"
        alt="Sandis - tekemistä, hyötyä ja hupia lapsiperheille"
        class="w-24"
      />
    </router-link>
    <!-- Right -->
    <div class="h-full flex items-center">
      <button
        :class="[
          'flex justify-center items-center h-full w-12 mr-1',
          { ['search-button--active']: $store.state.search.visible },
        ]"
        @click="onSearchClick"
      >
        <div class="fas fa-search" />
      </button>
      <!-- Logged Out -->
      <button
        v-if="!isLoggedIn"
        class="flex items-center h-full bg-orange-lighter px-4"
        @click="onLoginClick"
      >
        <strong>Kirjaudu</strong>
      </button>
      <!-- Logged in -->
      <button
        v-if="isLoggedIn"
        class="flex items-center h-full bg-orange-lighter px-4"
        @click="isMenuOpen = !isMenuOpen"
      >
        <div class="mr-4"><Avatar :userId="user.id" /></div>
        <div class="flex flex-col justify-center items-end text-white">
          <strong>{{ user.name }}</strong>
          <strong class="text-orange-darker">
            <small>{{ user.points.currentPoints }}p</small>
          </strong>
        </div>
      </button>
      <!-- User menu -->
      <div v-if="isLoggedIn" class="user-menu" v-show="isMenuOpen">
        <div class="flex flex-col items-center">
          <div class="flex mb-4">
            <div class="mr-4">
              <Avatar class="h-16 w-16" :userId="user.id" />
            </div>
            <div class="flex flex-col">
              <strong class="text-lg">{{ user.name }}</strong>
              <strong class="text-sm text-orange-darker">
                Pisteet: {{ user.points.currentPoints }}
              </strong>
              <strong class="text-sm text-orange-darker">
                Kokonaispisteet: {{ user.points.totalPoints }}
              </strong>
            </div>
          </div>
          <router-link
            class="user-menu-item"
            :to="'/favorites'"
            @click.native="isMenuOpen = false"
          >
            Omat suosikit
          </router-link>
          <router-link
            class="user-menu-item"
            :to="'/profile/lists'"
            @click.native="isMenuOpen = false"
          >
            Omat listat
          </router-link>
          <button class="user-menu-item" @click.prevent="onLogoutClick">
            Kirjaudu ulos
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import Avatar from '@/components/Avatar.vue';

export default {
  name: 'MainHeader',
  data() {
    return {
      authenticatedUser: '',
      isMenuOpen: false,
    };
  },
  components: { Avatar },
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn',
    }),
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    onLoginClick: async function () {
      await this.$store.dispatch('toggleLogin', { visible: true });
      this.isMenuOpen = false;
    },
    onLogoutClick: async function () {
      await this.$store.dispatch('user/logout');
      this.isMenuOpen = false;
    },
    onSearchClick: async function () {
      this.$store.dispatch('search/toggleVisibility');
      this.isMenuOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/variables';

.header {
  @apply relative z-40 flex items-center justify-between bg-white;
  height: $header-height;
  border-bottom: 2px rgba(#eee, 0.5) solid;
  width: 100%;
}

.user-menu {
  @apply absolute bg-orange-lighter p-4 w-full;

  @screen sm {
    @apply max-w-xs;
  }

  right: 0;
  top: $header-height - 0.125rem;
}

.user-menu-item {
  @apply text-orange-darker text-lg font-bold p-2 leading-normal;
}

.search-button--active {
  svg {
    color: $color-primary-rose;
  }
}
</style>

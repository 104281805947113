import Vue from 'vue';
import Vuex from 'vuex';
import api from '../utils/api';
// Store modules
import checkins from './modules/checkins';
import things from './modules/things';
import user from './modules/user';
import search from './modules/search';

Vue.use(Vuex);

const types = {
  ADD_FAVORITE: 'ADD_FAVORITE',
  LOG_IN: 'LOG_IN',
  LOG_OUT: 'LOG_OUT',
  REMOVE_FAVORITE: 'REMOVE_FAVORITE',
  TOGGLE_FEEDBACK: 'TOGGLE_FEEDBACK',
  TOGGLE_LOGIN: 'TOGGLE_LOGIN',
  UPDATE_CITIES: 'UPDATE_CITIES',
  UPDATE_DETAILS: 'UPDATE_DETAILS',
  UPDATE_FAVORITES: 'UPDATE_FAVORITES',
  UPDATE_FEATURES: 'UPDATE_FEATURES',
  UPDATE_GEOLOCATION: 'UPDATE_GEOLOCATION',
  UPDATE_LISTS: 'UPDATE_LISTS',
  UPDATE_MAP_CENTER: 'UPDATE_MAP_CENTER',
  UPDATE_NOTIFICATIONS: 'UPDATE_NOTIFICATIONS',
  UPDATE_POINTS: 'UPDATE_POINTS',
};

const {
  TOGGLE_FEEDBACK,
  TOGGLE_LOGIN,
  UPDATE_CITIES,
  UPDATE_DETAILS,
  UPDATE_FEATURES,
  UPDATE_GEOLOCATION,
  UPDATE_LISTS,
  UPDATE_MAP_CENTER,
  UPDATE_NOTIFICATIONS,
  UPDATE_POINTS,
} = types;

const mutations = {
  [UPDATE_CITIES](state, payload) {
    state.cities = payload;
  },
  [UPDATE_DETAILS](state, { type, data }) {
    state.details = Object.assign({}, data, { type });
  },
  [UPDATE_LISTS](state, payload) {
    state.lists = payload;
  },
  [UPDATE_POINTS](state, payload) {
    state.points = { ...state.points, ...payload };
  },
  [UPDATE_GEOLOCATION](state, payload) {
    state.geolocation = payload;
  },
  [TOGGLE_LOGIN](state, payload) {
    state.loginVisible = payload;
  },
  [TOGGLE_FEEDBACK](state, payload) {
    state.feedbackVisible = payload;
  },
  [UPDATE_FEATURES](state, payload) {
    state.features = payload;
  },
  [UPDATE_NOTIFICATIONS](state, payload) {
    state.notifications = payload;
  },
  [UPDATE_MAP_CENTER](state, payload) {
    state.mapCenter = payload;
  },
};

const actions = {
  fetchCities({ commit, state }) {
    if (state.cities.length > 0) {
      return Promise.resolve();
    } else {
      return api.cities.getAll().then(({ data }) => {
        commit(UPDATE_CITIES, data);
      });
    }
  },
  fetchDetails({ commit }, { type, id }) {
    if (type === 'event') {
      return api.events.get(id).then(({ data }) => {
        commit(UPDATE_DETAILS, {
          type,
          data,
        });
      });
    } else if (type === 'location') {
      return api.locations.get(id).then(({ data }) => {
        commit(UPDATE_DETAILS, {
          type,
          data,
        });
      });
    }
  },
  updatePoints({ commit, state }, { change }) {
    return commit(UPDATE_POINTS, {
      currentPoints: state.points.currentPoints + change,
    });
  },
  fetchNotifications({ commit }) {
    return api.notifications.get().then(({ data }) => {
      commit(UPDATE_NOTIFICATIONS, data);
    });
  },
  addFavorite(_, { thingId }) {
    return api.favorites.add({ thingId });
  },
  removeFavorite(_, { thingId }) {
    return api.favorites.remove({ thingId });
  },
  fetchLists({ commit }) {
    return api.user.getLists().then(({ lists }) => {
      commit(UPDATE_LISTS, lists);
    });
  },
  getGeolocation({ commit }) {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition((position) => {
        resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      }, reject);
    }).then(({ lat, lng }) => {
      commit(UPDATE_GEOLOCATION, { lat, lng });
      return { lat, lng };
    });
  },
  toggleLogin({ commit }, { visible }) {
    commit(TOGGLE_LOGIN, visible);
  },
  toggleFeedback({ commit, state }) {
    commit(TOGGLE_FEEDBACK, !state.feedbackVisible);
  },
  updateFeatures({ commit }, { features }) {
    const payload = features.reduce((obj, feature) => {
      obj[feature] = true;

      return obj;
    }, {});

    commit(UPDATE_FEATURES, payload);
  },
  updateUser() {
    console.log('deprecated updateUser called');
  },
  refreshUser() {
    console.log('deprecated refreshUser called');
  },
  updateMapCenter({ commit }, coordinates) {
    return commit(UPDATE_MAP_CENTER, coordinates);
  },
};

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    checkins,
    things,
    user,
    search,
  },
  state: {
    cities: [],
    details: {},
    feedbackVisible: false,
    geolocation: {
      lat: null,
      lng: null,
    },
    lists: [],
    loginVisible: false,
    mapCenter: {
      lat: null,
      lng: null,
    },
    notifications: [],
  },
  mutations,
  actions,
});

export default store;

<template>
  <div class="position-search">
    <Multiselect
      v-model="selectedPosition"
      :allow-empty="false"
      label="name"
      :loading="isLoading"
      :options="options"
      placeholder="Valitse sijainti"
      :show-labels="false"
      :show-pointer="false"
      :searchable="true"
      track-by="name"
    >
      <span slot="carret"></span>
    </Multiselect>
    <br />
    <p>
      Listassa näkyvät vain kaupungit, joihin on Sandiksessa lisätty paikkoja.
      Jos etsimäsi kaupunkia ei löydy listalta, voit auttaa muita Sandiksen
      käyttäjiä täydentämällä alueen lapsiystävällisiä kohteita!
    </p>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { sortByProp } from '@/utils/utils';

export default {
  name: 'Position',
  components: { Multiselect },
  data() {
    return {
      isLoading: false,
      selectedPosition: {},
    };
  },
  created() {
    this.$store.dispatch('fetchCities');
  },
  computed: {
    options() {
      return [
        {
          name: 'Lähellä minua',
          value: {},
          isGeolocation: true,
        },
        ...this.$store.state.cities
          .filter((city) => city.things_count > 0)
          .sort(sortByProp('name'))
          .map((city) => ({
            name: city.name,
            value: city.location,
          })),
      ];
    },
    geolocation() {
      return this.$store.state.geolocation;
    },
  },
  methods: {
    getGeolocation() {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    searchLocation() {
      let vm = this;
      this.isLoading = true;
      window.setTimeout(() => {
        vm.isLoading = false;
      }, 1000);
    },
  },
  watch: {
    selectedPosition(selected) {
      const vm = this;

      if (selected.isGeolocation) {
        if (!vm.geolocation.lat || !vm.geolocation.lng) {
          vm.isLoading = true;

          vm.$store.dispatch('getGeolocation').then((data) => {
            console.log(data);
            vm.isLoading = false;
            vm.$emit('value', data);
          });
        } else {
          vm.$emit('value', vm.geolocation);
        }
      } else {
        vm.$emit('value', selected.value);
      }
    },
  },
};
</script>

<style lang="scss">
@import '../scss/variables';

.position-search {
  width: 20rem;

  @media (max-width: $bp-mobile) {
    width: 100%;
  }
}

.geolocation-button {
  padding: 0.5rem;
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 1rem !important;
}

.multiselect--active {
  position: relative !important;
  z-index: $z-index-modal !important;
}

.multiselect__spinner {
  top: 2px !important;
  right: 2px !important;
}
</style>

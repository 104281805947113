<template>
  <div>
    <div v-if="isLoggedIn && !this.user.name" class="bg-blue px-2 py-1 text-sm">
      <router-link to="/profile/name" class="text-white">
        Erotu joukosta, valitse itsellesi nimimerkki!
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Notifications',
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn',
    }),
    user() {
      return this.$store.state.user;
    },
  },
};
</script>

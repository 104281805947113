import api from '../../api';
import auth from '../../utils/auth';

// Initial state
const state = {
  loading: false,
  visible: false,
  results: [],
};

// Actions
const actions = {
  toggleVisibility({ commit, state }, isVisible) {
    const toState = isVisible ? isVisible : !state.visible;

    commit('setVisibility', toState);
  },
  setResults({ commit }, results) {
    commit('setResults', results);
  },
};

// Mutations
const mutations = {
  setVisibility(state, isVisible) {
    state.visible = isVisible;
  },
  setResults(state, results) {
    state.results = results;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};

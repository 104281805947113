<template>
  <div class="user-lists">
    <header class="hero">
      <div class="hero-body has-text-centered">
        <div class="container container--narrow">
          <h1 class="title is-1 is-spaced">Listasi</h1>
          <p class="subtitle is-6 mb-4">
            Listat on Sandiksen tapa järjestää ja esitellä paikkoja ja
            tapahtumia. Voit luoda omia listoja vaikka suosikkiravintoloistasi,
            apuna suunnitellessasi viikonloppureissua tai esitellä lähialueen
            tuntemattomimmat seikkailukohteet.
          </p>
          <div class="flex justify-center">
            <router-link
              to="/create/list"
              class="button bg-yellow rounded-full"
            >
              Luo uusi lista
            </router-link>
          </div>
        </div>
      </div>
    </header>
    <div class="container px-2">
      <ul class="column is-half is-offset-one-quarter">
        <li v-for="list in lists" :key="list.id">
          <article class="rounded shadow mb-6 w-full bg-white p-2">
            <div class="flex">
              <div>
                <ButtonColor
                  type="pattern"
                  :colorId="list.color"
                  :patternId="list.pattern"
                ></ButtonColor>
              </div>
              <div class="p-4 w-full">
                <div>
                  <div class="text-sm">
                    {{ list.is_public ? 'Julkinen' : 'Yksityinen' }} lista
                  </div>
                  <h3 class="font-bold text-lg mb-4">{{ list.name }}</h3>
                  <p>{{ list.description }}</p>
                </div>
              </div>
            </div>
            <div class="flex justify-between">
              <router-link
                :to="`/list/${list.id}/${list.slug}`"
                class="button is-primary is-inverted"
              >
                Näytä lista
              </router-link>
              <button
                class="button rounded-full bg-red text-white"
                @click="() => onRemoveListClick(list.id)"
              >
                Poista
              </button>
            </div>
          </article>
        </li>
      </ul>
      <div class="column is-half is-offset-one-quarter">
        <Pagination
          v-if="pagination"
          :current="pagination.page_number"
          :total="pagination.total_pages"
          :onPageChange="handlePageChange"
        ></Pagination>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/utils/api';
import ButtonColor from '@/components/ButtonColor';
import Pagination from '@/components/Pagination';

export default {
  name: 'UserLists',
  components: { ButtonColor, Pagination },
  data() {
    return {
      lists: [],
      pagination: null,
    };
  },
  created() {
    this.fetchUserLists();
  },
  methods: {
    handlePageChange: async function (page) {
      await this.fetchUserLists(page);
    },
    fetchUserLists: async function (page = 1) {
      const { lists, pagination } = await api.user.getLists({
        sort_by: 'updated_at',
        page,
      });

      this.lists = lists;
      this.pagination = pagination;
    },
    onRemoveListClick: async function (id) {
      try {
        await api.lists.delete(id);

        this.lists = this.lists.filter((list) => {
          return list.id !== id;
        });
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

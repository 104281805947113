<template>
  <div class="container p-4">
    <header class="mb-10">
      <h1 class="font-bold text-5xl">Listat</h1>
      <p>Täältä löydät kaikki Sandis-tiimin laatimat listat</p>
    </header>
    <div class="w-full max-w-lg">
      <article
        v-for="list in lists"
        :key="list.id"
        class="flex flex-col md:flex-row bg-white rounded shadow overflow-hidden mb-4"
      >
        <div
          class="lists-list__image"
          :style="{ backgroundImage: `url(${list.image.thumb})` }"
        ></div>
        <div class="p-4">
          <router-link :to="`/list/${list.id}/${list.slug}`">
            <h2 class="font-bold text-lg">{{ list.name }}</h2>
          </router-link>
          <p class="text-sm mb-4">
            Päivitetty {{ list.updated_at | formatListDate }}
          </p>
          <p class="lists-list__description">{{ list.description }}</p>
          <br />
          <router-link :to="`/list/${list.id}/${list.slug}`">
            Tutustu listaan
          </router-link>
        </div>
      </article>
      <Pagination
        v-if="pagination"
        :current="pagination.page_number"
        :total="pagination.total_pages"
        :onPageChange="handlePageChange"
      ></Pagination>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';
import api from '../utils/api';
import Pagination from '../components/Pagination';

export default {
  name: 'Lists',
  components: { Pagination },
  data() {
    return {
      lists: [],
      pagination: {},
    };
  },
  created() {
    this.fetchLists();
  },
  filters: {
    formatListDate(date) {
      return format(date, 'D.M.YYYY');
    },
  },
  methods: {
    handlePageChange: async function (page) {
      await this.fetchLists(page);
    },
    fetchLists: async function (page = 1) {
      const { lists, pagination } = await api.lists.getAll({
        is_editorial: true,
        sort_by: 'updated_at',
        page,
      });
      this.lists = lists;
      this.pagination = pagination;
    },
  },
};
</script>

<style lang="scss">
@import '../scss/variables';

.lists-list__image {
  flex-shrink: 0;
  flex-basis: 10rem;
  background-size: cover;
  background-position: center center;
}

.lists-list__description {
  word-break: break-word;
}
</style>

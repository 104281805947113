import { format } from 'date-fns';

export const capitalize = (value) => {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const parseDate = (isoDate) => {
  return format(isoDate, 'D.M.YYYY, HH:mm');
};

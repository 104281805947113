import api from '../../api';

// Initial state
const state = {
  allIds: [],
  byId: {},
  error: false,
  filters: {
    ages: [],
    category: null,
    city: null,
    end_date: null,
    location: {
      lat: 60.1699,
      lng: 24.9384,
    },
    start_date: null,
    subcategory: null,
    tags: [],
    type: null,
  },
  loading: false,
  pagination: {},
};

// Getters
const getters = {
  things: (state) => state.allIds.map((id) => state.byId[id]),
};

// Actions
const actions = {
  async getThings({ commit, state }, page = 1) {
    commit('setError', false);
    commit('setLoading', true);

    try {
      const params = {
        ...filtersToParams(state.filters),
        page,
        sort_by: 'location',
      };

      const response = await api.things.get(params);
      const { data, pagination } = response.data;

      commit('setLoading', false);
      commit('setThings', data);
      // TODO: Reset pagination properly when switching between filters
      commit('setPagination', pagination);
    } catch (error) {
      commit('setError', true);
    }
  },
  setCoordinates({ commit, state }, coordinates) {
    const { filters } = state;

    commit('setFilters', {
      ...filters,
      location: coordinates,
    });
  },
  // Set single filter
  setFilter({ commit, state }, { filter, value }) {
    console.log('setFilter', filter, value);
    const { filters } = state;

    commit('setFilters', {
      ...filters,
      [filter]: value,
    });
  },
  // Set all filters at once
  setFilters({ commit }, filters) {
    commit('setFilters', filters);
  },
};

// Mutations
const mutations = {
  setError(state, isError) {
    state.error = isError;
  },
  setFilters(state, filters) {
    state.filters = {
      ...state.filters,
      ...filters,
    };
  },
  setLoading(state, isLoading) {
    state.loading = isLoading;
  },
  setPagination(state, pagination) {
    state.pagination = pagination;
  },
  setThings(state, things) {
    state.byId = things.reduce(
      (acc, thing) => ({ ...acc, [getId(thing)]: thing }),
      {}
    );

    state.allIds = things.map((thing) => getId(thing));
  },
};

const filtersToParams = (filters) => {
  const {
    ages,
    category,
    city,
    end_date,
    location,
    tags,
    type,
    start_date,
    subcategory,
  } = filters;

  const params = {
    ages: ages.join(','),
    category,
    city,
    end_date,
    // If city filter is defined, don't use location params
    lat: !city ? location.lat : null,
    lng: !city ? location.lng : null,
    tags: tags.join(','),
    type,
    start_date,
    subcategory,
  };

  return params;
};

// Returns id for locations, id + date_id for events
const getId = (thing) =>
  thing.type === 'event' ? `${thing.id}_${thing.date_id}` : `${thing.id}`;

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

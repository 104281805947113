<template>
  <nav class="pagination" role="navigation" aria-label="pagination">
    <button
      class="pagination-previous"
      :disabled="current < 2"
      @click="() => onPageChange(current - 1)"
    >
      <i class="fa fa-angle-left"></i>
    </button>
    <ul class="pagination-list">
      <li v-for="p in total" :key="p">
        <button
          :class="['pagination-link', { 'is-current': p === current }]"
          @click="() => onPageChange(p)"
        >
          {{ p }}
        </button>
      </li>
    </ul>
    <button
      class="pagination-next"
      :disabled="current === total"
      @click="() => onPageChange(current + 1)"
    >
      <i class="fa fa-angle-right"></i>
    </button>
  </nav>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    current: Number,
    onPageChange: Function,
    total: Number,
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  align-items: flex-start;
}

.pagination-list {
  justify-content: flex-start;
}

.pagination-next,
.pagination-previous,
.pagination-list li {
  flex-grow: 0;
  flex-shrink: 0;
}
</style>

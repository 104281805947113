<template>
  <div class="details container px-4">
    <!-- Top Row -->
    <div class="flex justify-between">
      <router-link
        v-if="previousRoute"
        :to="previousRoute"
        class="mb-4 text-blue-darker flex items-center"
      >
        <span class="text-xs mr-2"><i class="fa fa-chevron-left"></i></span>
        <strong>Takaisin</strong>
      </router-link>
    </div>
    <!-- Row container -->
    <div class="md:flex">
      <article v-show="data.name" class="w-full max-w-md">
        <!-- Header -->
        <header class="container mb-6">
          <div
            v-if="data.images && data.images.length > 0"
            class="md:hidden mb-4"
          >
            <div
              class="details__header-image"
              :style="{ backgroundImage: `url(${headerImageUrl})` }"
            />
          </div>
          <h1 class="text-4xl md:text-5xl leading-none mb-4">
            {{ data.name }}
          </h1>
          <p class="mb-4">{{ data.short_description }}</p>
          <div class="flex flex-wrap">
            <ButtonFavorite
              v-if="data.id"
              :isFavorite="data.is_favorite"
              :id="data.id"
              :hasLabel="true"
              @addFavorite="data.is_favorite = true"
              @removeFavorite="data.is_favorite = false"
            />
            <ButtonCheckin
              v-if="data.id && data.type === 'location'"
              :id="data.id"
              :isCheckedIn="data.is_checked_in"
              @onChange="
                ({ isCheckedIn }) => (data.is_checked_in = isCheckedIn)
              "
            />
          </div>
        </header>
        <!-- Details -->
        <div class="container">
          <div></div>

          <div class="mb-6" v-if="type === 'event'">
            <h3>Ajankohta</h3>
            <p>{{ startDate }} - {{ endDate }}</p>
          </div>

          <div class="mb-6" v-if="data.description">
            <h3>Lisätietoja</h3>
            <p class="whitespace-pre-line">{{ data.description }}</p>
          </div>

          <div class="flex flex-wrap mb-6">
            <span
              v-for="tag in data.tags"
              :key="tag"
              class="text-xs bg-indigo text-white rounded px-2 py-1 mr-2 mb-2"
            >
              {{ $t('tags.' + tag) | capitalize }}
            </span>
            <span
              v-for="age in data.ages"
              :key="age"
              class="text-xs bg-indigo text-white rounded px-2 py-1 mr-2 mb-2"
            >
              {{ $t('ages.' + age) | capitalize }}
            </span>
          </div>

          <div class="mb-6 truncate" v-if="data.website">
            <h3>Verkkosivu</h3>
            <a :href="data.website" target="_blank">{{ data.website }}</a>
          </div>

          <div class="mb-6" v-if="data.average_rating">
            <h3>Käyttäjien arvosana</h3>
            <Rating :rating="data.average_rating"></Rating>
          </div>

          <div class="mb-6">
            <h3>Sijainti</h3>
            <p>{{ data.address }}</p>

            <div class="details__map">
              <AppleMap
                v-if="data.name.length > 0"
                disableCallout
                :items="[data]"
                :center="data.location"
              />
            </div>
          </div>
        </div>
      </article>

      <!-- Aside content -->

      <aside class="flex flex-col items-center md:items-end w-full">
        <div v-if="isLoggedIn">
          <button class="button" @click="editVisible = true">
            <span class="icon is-small"><i class="fa fa-edit mr-2"></i></span>
            <span>Muokkaa</span>
          </button>
        </div>

        <div v-if="data.name.length > 0">
          <div>
            <ListDropdown
              color="black"
              :isOutlined="true"
              :id="id"
              :type="type"
            ></ListDropdown>
          </div>
        </div>

        <div class="has-text-centered" v-if="type === 'location'">
          <button
            v-show="!createReviewVisible"
            class="button"
            @click="onShowCreateReviewClick"
          >
            <span class="icon is-small"><i class="fa fa-star mr-2"></i></span>
            <span>Arvostele</span>
          </button>
        </div>

        <Modal
          v-show="createReviewVisible"
          :onCloseClick="
            () => {
              createReviewVisible = false;
            }
          "
        >
          <CreateReview
            :locationId="id"
            :name="data.name"
            :onClose="
              () => {
                createReviewVisible = false;
              }
            "
          ></CreateReview>
        </Modal>

        <ImageGrid
          v-if="data.id"
          :id="data.id"
          :images="data.images"
          :onUploadSuccess="handleImageUploadSuccess"
        />

        <Reviews
          v-if="type === 'location'"
          :locationId="id"
          :showCreateReview="onShowCreateReviewClick"
        ></Reviews>
      </aside>
    </div>

    <Modal
      v-if="data.name && editVisible"
      :onCloseClick="() => (editVisible = false)"
    >
      <Edit
        :id="id"
        :type="type"
        :currentName="data.name"
        :currentCategory="data.category"
        :currentSubcategory="data.subcategory"
        :currentShortDescription="data.short_description"
        :currentDescription="data.description"
        :currentTags="data.tags"
        :currentAges="data.ages"
        :currentAddress="data.address"
        :currentLocation="data.location"
        :currentWebsite="data.website"
      ></Edit>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ButtonCheckin from '@/components/ButtonCheckin.vue';
import ButtonFavorite from '@/components/ButtonFavorite.vue';
import CreateReview from '@/components/CreateReview.vue';
import Edit from '@/components/Edit.vue';
import ListDropdown from '@/components/ListDropdown.vue';
import Modal from '@/components/Modal.vue';
import Rating from '@/components/Rating.vue';
import Reviews from '@/components/Reviews.vue';
import ImageGrid from '@/components/ImageGrid.vue';
import AppleMap from '@/components/Map.vue';

import api from '../utils/api.js';
import {
  categoryColors,
  getMarkerIcon,
  getRandomPlaceholder,
  setMeta,
  loadExternalScripts,
} from '../utils/utils.js';
import { capitalize } from '../utils/filters.js';
import { globalMapOptions } from '../utils/map';
import { format, isSameDay } from 'date-fns';

export default {
  components: {
    AppleMap,
    ButtonCheckin,
    ButtonFavorite,
    CreateReview,
    Edit,
    ImageGrid,
    ListDropdown,
    Modal,
    Rating,
    Reviews,
  },
  data() {
    return {
      mapVisible: false,
      uploadVisible: false,
      data: {
        name: '',
        category: '',
        subcategory: '',
        short_description: '',
        dates: [
          {
            end_date: undefined,
            start_date: undefined,
          },
        ],
        description: '',
        address: '',
        location: {
          lat: 0,
          lng: 0,
        },
        favorites: 0,
        rating: undefined,
        start_date: undefined,
        end_date: undefined,
        website: undefined,
      },
      commentCreationVisible: false,
      comments: [],
      commentMessage: '',
      mapOptions: globalMapOptions,
      previousRoute: undefined,
      categoryColors,
      createReviewVisible: false,
      editVisible: false,
    };
  },
  metaInfo() {
    return {
      title: this.data.name,
      meta: [
        ...setMeta({
          title: this.data.name,
          url: `${this.type}/${this.id}/${this.slug}`,
        }),
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    // Handles back button logic
    window.scrollTo(0, 0);
    next((vm) => {
      vm.previousRoute = from.fullPath;
    });
  },
  created() {
    const vm = this;
    const params = vm.$route.params;

    const doFetch =
      params.type === 'location'
        ? api.locations.get(params.id)
        : api.events.get(params.id);

    doFetch
      .then(({ data }) => {
        // Make sure that url contains correct slug
        const params = { ...vm.$route.params, slug: data.slug };
        const query = vm.$route.query;
        vm.$router.replace({ params, query });

        vm.data = {
          ...vm.data,
          ...data,
        };
      })
      .catch((error) => console.error(error));
  },
  mounted() {
    const scripts = [
      "https://wonderus.bbvms.com/a/sandis_korttisisallot_outstream.js",
    ];

    loadExternalScripts(scripts);
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn',
    }),
    headerImageUrl() {
      const { data } = this;

      if (data.images && data.images.length > 0) {
        return data.images[0].sizes.large;
      } else {
        return undefined;
      }
    },
    icon() {
      return getMarkerIcon({ type: this.type, category: this.data.category });
    },
    id() {
      return this.$route.params.id;
    },
    imageUrl() {
      return getRandomPlaceholder(this.data.category || 'event');
    },
    isFavorite() {
      return !!~this.$store.state.favorites.ids.indexOf(this.$route.params.id);
    },
    type() {
      return this.$route.params.type;
    },
    date() {
      const dateId = parseInt(this.$route.query.date_id, 10);
      const date = dateId
        ? this.data.dates.find((date) => date.id === dateId)
        : null;

      return date || this.data.dates[0];
    },
    startDate() {
      const startDate = this.date.start_date;

      return format(startDate, 'D.M.YYYY, HH:mm');
    },
    endDate() {
      const startDate = this.date.start_date;
      const endDate = this.date.end_date;
      const endFormat = isSameDay(startDate, endDate)
        ? 'HH:mm'
        : 'D.M.YYYY, HH:mm';

      return format(endDate, endFormat);
    },
  },
  methods: {
    fetchData() {
      return this.$route.params.type === 'location'
        ? api.locations.get(this.$route.params.id)
        : api.events.get(this.$route.params.id);
    },
    async handleImageUploadSuccess() {
      const { data } = await this.fetchData();

      this.data = {
        ...this.data,
        ...data,
      };
    },
    onShowCreateReviewClick() {
      this.isLoggedIn
        ? (this.createReviewVisible = true)
        : this.$store.dispatch('toggleLogin', { visible: true });
    },
  },
  filters: {
    capitalize,
    trim: (str) => {
      return str.trim(str.replace(/^\s+|\s+$/g, ''));
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/variables';

.details {
  &__map {
    height: 20rem;
    margin-top: 1.5rem;
  }
}

.details__header-image {
  width: 100%;
  height: 15rem;
  background-position: center center;
  background-size: cover;
}
</style>

<template>
  <div class="feature media pt-4">
    <div class="media-left">
      <figure class="image is-96x96"><img :src="image" /></figure>
    </div>
    <div class="media-content">
      <h3>{{ name }}</h3>
      <div class="feature__description text-grey-dark"><slot></slot></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Feature',
  props: {
    name: String,
    image: String,
  },
};
</script>

<style lang="scss">
.feature {
  & + & {
    border-top: none;
  }
}

.feature__title {
  letter-spacing: 1px;
  line-height: 2;
}
</style>

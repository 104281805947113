<template>
  <div class="verify">
    <div class="flex min-h-screen overflow-hidden">
      <div class="bg-grey-lightest background-shapes flex-grow"></div>
      <div class="w-full max-w-lg shadow p-4 flex flex-col items-center">
        <div class="w-full max-w-sm">
          <div>
            <label class="label" for="verification-username">
              Käyttäjätunnus
            </label>
            <input
              class="input is-medium"
              v-model.trim="username"
              id="verification-username"
              type="text"
              name="name"
              value=""
            />
          </div>
          <div>
            <label class="label" for="verification-code">Vahvistuskoodi</label>
            <input
              class="input is-medium"
              v-model.trim="code"
              id="verification-code"
              type="text"
              name="name"
              value=""
            />
          </div>

          <div class="flex justify-end">
            <button
              class="button button--teal"
              @click="onVerifyClick"
              type="button"
              name="button"
              :disabled="loading"
            >
              Vahvista
            </button>
          </div>
          <p v-if="error" class="mt-6 text-red">
            Hups, jotain meni nyt pieleen! Tarkistathan tunnuksesi ja
            salasanasi.
          </p>
        </div>
        <div v-if="success">
          <p class="font-bold">Mahtavaa!</p>
          <p>Käyttäjätunnuksesi on nyt vahvistettu, tervetuloa mukaan!</p>
          <p>Voit kirjautua sisään.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
const { VUE_APP_COGNITO_USER_POOL, VUE_APP_COGNITO_APP_ID } = process.env;

export default {
  name: 'verify',
  data() {
    return {
      username: null,
      code: null,
      loading: false,
      success: false,
      error: false,
    };
  },
  mounted() {
    this.username = this.$route.query.username || '';
    this.code = this.$route.query.code || '';

    if (this.username && this.code) this.onVerifyClick();
  },
  methods: {
    onVerifyClick() {
      this.loading = true;

      const userPool = new CognitoUserPool({
        UserPoolId: VUE_APP_COGNITO_USER_POOL,
        ClientId: VUE_APP_COGNITO_APP_ID,
      });

      const user = new CognitoUser({
        Username: this.username,
        Pool: userPool,
      });

      user.confirmRegistration(this.code, true, (error) => {
        if (error) {
          this.loading = false;
          this.error = true;
          console.log('Verification error:', error);
        } else {
          this.loading = false;
          this.success = true;
        }
      });
    },
  },
};
</script>

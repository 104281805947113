const checkins = (api) => ({
  create(id) {
    return api.post(`/things/${id}/checkins`);
  },
  delete(id) {
    return api.delete(`/things/${id}/checkins`);
  },
});

export default checkins;

<template>
  <div class="front-view">
    <div
      class="section container bg-sandis p-3 mb-4 rounded has-overlay has-overlay--lighter"
    >
      <section class="hero-container my-4">
        <div class="container">
          <div class="w-full max-w-lg">
            <h2 class="mb-6 ds-white">Lapsiystävällinen Sandis</h2>
            <p class="lead mb-6 ds-white">
              Perustimme Sandiksen, jotta vanhemmat voisivat löytää yhdestä
              osoitteesta perheystävälliset paikat ja tapahtumat. Käy
              peremmälle, tutki karttaa, ilmoita omat suosikkipaikkasi ja
              kirjoita niistä kokemuksia. 🙂
            </p>
            <div class="flex flex-wrap">
              <a href="/lists" class="button button--primary--blue mr-2 mb-2">
                Listat
              </a>
              <a href="/browse" class="button button--primary--blue mr-2 mb-2">
                Tutki karttaa
              </a>
              <a
                href="/register"
                class="button button--primary--blue mr-2 mb-2"
              >
                Rekisteröidy
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="container">
      <!-- Row container -->
      <div class="md:flex">
        <div class="w-full md:w-2/3">
          <section class="container">
            <div class="flex flex-wrap w-full max-w-lg">
              <div
                :class="[
                  'w-full flex-grow py-2 md:px-2 ',
                  `md:w-1/${12 / block.size}`,
                ]"
                v-for="block in content"
                :key="`${block.acf_fc_layout}-${block.type}-${block.id}`"
              >
                <component
                  :is="getBlock(block.acf_fc_layout)"
                  :cmsData="block"
                ></component>
              </div>
            </div>
          </section>
        </div>
        <div
          class="flex flex-col items-center md:items-end w-full md:w-1/3 pl-4 pb-4"
        >
          <ActivityFeed />
          <TopUsers class="mt-2" />
        </div>
      </div>
      <section class="section container">
        <AboutGreeting></AboutGreeting>
      </section>
      <section class="section container">
        <AboutFeatures></AboutFeatures>
      </section>
    </div>
  </div>
</template>

<script>
import AboutGreeting from '@/components/AboutGreeting';
import AboutFeatures from '@/components/AboutFeatures';
import BannerLoader from '@/components/BannerLoader';
import BlockCard from '@/components/BlockCard';
import BlockList from '@/components/BlockList';
import BlockPromotion from '@/components/BlockPromotion';
import CampaignHero from '@/components/CampaignHero';
import Card from '@/components/Card';
import CardBanner from '@/components/CardBanner';
import cms from '@/utils/cms.js';
import ActivityFeed from './ActivityFeed';
import TopUsers from './TopUsers';

const blocks = {
  block_card: 'BlockCard',
  block_list: 'BlockList',
  block_promotion: 'BlockPromotion',
};

export default {
  components: {
    AboutGreeting,
    AboutFeatures,
    ActivityFeed,
    BannerLoader,
    BlockCard,
    BlockList,
    BlockPromotion,
    CampaignHero,
    Card,
    CardBanner,
    TopUsers,
  },
  data() {
    return {
      header: {},
      content: [],
    };
  },
  created() {
    const vm = this;

    cms.getPage({ id: 7 }).then(({ data }) => {
      vm.header = data.acf.header;
      vm.content = data.acf.content;
    });
  },
  methods: {
    getBlock(block) {
      return blocks[block];
    },
  },
};
</script>
